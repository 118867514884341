import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from 'semantic-ui-react/dist/es/modules/Transition';
import 'semantic-ui-css/components/transition.min.css';

import './Welcome.css';
import { WelcomeSplash, Button } from 'web-store-modules/packages/web-store-ui-library/'
import { getWelcomeExploreUrlAsync } from '../../../utils/StaticAssetManager.js';
import { getWelcomeMessageStyleAsync, getWelcomeTitleAsync, getStyleColorAsync } from '../../../utils/StoreConfigManager.js';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';

const defaultFontFamily = 'sans-serif';

const isMobile = isMobileDevice();

const welcomeShowTime = 4000;

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.hide = this.hide.bind(this);
        this.onHide = this.onHide.bind(this);

        this.state = {
            text: '',
            imageSrc: '',
            visible: true,
            welcomeMessageStyle: null,
            pendingLoadAssetCount: 3,
            primaryColor: '',
            secondaryColor: '',
        };

        this.timeOutFunction = null;
    }

    componentDidMount() {
        getWelcomeMessageStyleAsync()
            .then(style => {
                if (style) {
                    const welcomeMessageStyle = {
                        fontFamily: style.name + ',' + defaultFontFamily
                    };
                    if (!isMobile) {
                        welcomeMessageStyle.fontSize = style.size;
                    }
                    this.setState(state => {
                        return {
                            welcomeMessageStyle: welcomeMessageStyle,
                            pendingLoadAssetCount: state.pendingLoadAssetCount - 1
                        };
                    });
                }
            })
            .catch(error => console.error(error));

        getStyleColorAsync()
            .then(style => {
                const primaryColor = style[0];
                const secondaryColor = style[1];
                this.setState({ primaryColor, secondaryColor })
            })
            .catch(error => console.error(error));

        getWelcomeTitleAsync()
            .then(welcomeText => {
                this.setState(state => {
                    return {
                        text: welcomeText,
                        pendingLoadAssetCount: state.pendingLoadAssetCount - 1
                    };
                });
            })
            .catch(error => console.error(error));

        getWelcomeExploreUrlAsync()
            .then(url => {
                const welcomeImage = new Image();
                welcomeImage.onload = () => {
                    this.setState(state => {
                        return {
                            pendingLoadAssetCount: state.pendingLoadAssetCount - 1
                        };
                    });
                }
                welcomeImage.src = url;
                this.setState({ imageSrc: url })
            })
            .catch(error => console.error(error));

    }

    componentWillUnmount() {
        if (isMobile) {
            window.removeEventListener('touchend', this.hide);
        } else {
            window.removeEventListener('click', this.hide);
        }
        clearTimeout(this.timeOutFunction);
    }

    hide() {
        this.setState({ visible: false });
    }

    onHide() {
        this.props.onWelcomeHide();
    }

    getUpdatedWelcomeMessageStyle() {
        const { isFlatImageScene } = this.props;
        const { welcomeMessageStyle } = this.state;
        const updatedWelcomeMessageStyle = { ...welcomeMessageStyle, fontSize: 36 };

        return isFlatImageScene ? updatedWelcomeMessageStyle : welcomeMessageStyle;
    }

    render() {
        const { showingUI, isFlatImageScene, flatImageButtonOption, flatImageButtonText } = this.props;
        const { text, visible, pendingLoadAssetCount, primaryColor, secondaryColor } = this.state;

        if (!showingUI || pendingLoadAssetCount > 0) {
            return <React.Fragment />;
        }
        if (!this.timeOutFunction) {
            if (isMobile) {
                window.addEventListener('touchend', this.hide);
            } else {
                window.addEventListener('click', this.hide);
            }
            this.timeOutFunction = setTimeout(() => {
                this.hide();
            }, welcomeShowTime);
        }

        return (
            <Transition
                visible={visible}
                animation='fade'
                duration={300}
                onHide={this.onHide}
            >
                <WelcomeSplash
                    welcomeMessageStyle={this.getUpdatedWelcomeMessageStyle()}
                    welcomeText={text}
                    hideTooltip={isFlatImageScene}
                >
                    {
                        flatImageButtonOption &&
                        <Button
                            style={{ ...this.getUpdatedWelcomeMessageStyle(), padding: '0.2em 1em', marginTop: '0.5em' }}
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                        >
                            <p id='flat-image-button-text'>{flatImageButtonText}</p>
                        </Button>
                    }
                </WelcomeSplash>
            </Transition>
        );
    }
}

Welcome.propTypes = {
    showingUI: PropTypes.bool,
    onWelcomeHide: PropTypes.func,
    isFlatImageScene: PropTypes.bool,
    flatImageButtonOption: PropTypes.bool,
    flatImageButtonText: PropTypes.string,
};

export default Welcome;
