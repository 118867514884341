import React, { useState, useEffect } from "react";

const lockIconUrl = "https://cdn.obsessvr.com/tommy-lock.png";
const bgImg =
  "https://cdn.obsessvr.com/ralph-lauren/rl-bottom-menu-bg-pattern.jpg";

const BottomBarSelectorItem = ({
  index,
  className,
  scene,
  widthPercent,
  selectedBorderWidth,
  currentlySelected,
  isLocked,
  onSelectorOptionClicked,
  BottomBarSelectorLen,
}) => {
  const [keyboardNav, setKeyboardNav] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardNav);
    isActiveHandler();
  }, handleKeyboardNav);

  const handleKeyboardNav = (e) => {
    //If shift + ArrowLeft/ArrowRight are pressed, activate the keyboard
    if (e.shiftKey && e.key === "ArrowLeft" && keyboardNav > 0) {
      setKeyboardNav(keyboardNav - 1);
    } else if (
      e.shiftKey &&
      e.key === "ArrowRight" &&
      keyboardNav < BottomBarSelectorLen - 1
    ) {
      setKeyboardNav(keyboardNav + 1);
    }
  };

  const isActiveHandler = () => {
    const markerKey = index;
    if (keyboardNav === markerKey) {
      setIsActive(true);
      window.addEventListener("keydown", handleSpace);
    } else {
      setIsActive(false);
    }
  };

  const handleSpace = (e) => {
    if (isActive && e.key === " ") {
      onSelectorOptionClicked(e, scene.scene_id, isLocked);
    }
  };

  return (
    <div
      key={scene.scene_id}
      className={className}
      style={{
        width: widthPercent,
        borderWidth: currentlySelected && selectedBorderWidth,
        fontFamily: "LeJeuneDeck-Regular",
        cursor: currentlySelected ? "" : "pointer",
        //backgroundImage: `url(` + `${bgImg}` + `)`
        border: isActive && "3px solid red",
      }}
      onMouseDown={(e) => {
        onSelectorOptionClicked(e, scene.scene_id, isLocked);
      }}
      onTouchEnd={(e) => {
        onSelectorOptionClicked(e, scene.scene_id, isLocked);
      }}
    >
      <span
        className={isLocked ? "locked-selector-text" : "glowing-selector-text"}
      >
        {scene.name === "her" ? "H.E.R." : scene.name}
        {isLocked && (
          <img
            className="bottom-bar-selector-lock-icon"
            src={lockIconUrl}
          ></img>
        )}
      </span>
    </div>
  );
};

export default BottomBarSelectorItem;
