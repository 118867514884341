import React, { Component } from "react";
import PropTypes from "prop-types";

import { ThreeJSWorldContext } from "web-store-modules/packages/web-store-three-world";
import { EFFECTS } from "web-store-modules/packages/three-transition";
import Marker from "./Marker.jsx";
import {
  MovedOnce,
  InteractedOnce,
  ArrowClicked,
} from "../../utils/Analytics.js";
import {
  getAllSceneInfoDictAsync,
  getSceneIds,
  getTrasitionTypeAsync,
} from "../../utils/StoreConfigManager.js";
import {
  isRoomMarkerClicked,
  addClickedRoomMarker,
} from "../../utils/DynamicStoreDataManager.js";
import ArrowSvg from "./ReactSVGArrows/ArrowSvg.jsx";
import StairsUpSvg from "./ReactSVGArrows/StairsUpSvg.jsx";
import StairsDownSvg from "./ReactSVGArrows/StairsDownSvg.jsx";
import { AppContext } from "../../AppContext.jsx";

const CMSTemplateNavSpriteMap = Object.freeze({
  up: ArrowSvg,
  down: ArrowSvg,
  left: ArrowSvg,
  right: ArrowSvg,
  up_left: ArrowSvg,
  down_left: ArrowSvg,
  up_right: ArrowSvg,
  down_right: ArrowSvg,
  stairs_up: StairsUpSvg,
  stairs_down: StairsDownSvg,
});

class NavMarker extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

    const spriteNames = CMSTemplateNavSpriteMap[props.spriteType];

    this.state = {
      svgSprite: spriteNames,
      linkedRoom: {},
      transitionType: EFFECTS.ZOOM_FADE,
    };

    this.sceneIds = [];
  }

  componentDidMount() {
    getSceneIds()
      .then((sceneIds) => (this.sceneIds = sceneIds))
      .catch((error) => console.error(error));
    this.getLinkedRoomData();
    this.getTransitionType();
  }

  async getLinkedRoomData() {
    try {
      const { linkedRoomId } = this.props;
      const sceneInfoDict = await getAllSceneInfoDictAsync();
      if (!sceneInfoDict[linkedRoomId]) {
        throw new Error("Scene with id " + linkedRoomId + " do not exist!");
      }

      this.setState({ linkedRoom: sceneInfoDict[linkedRoomId] });
    } catch (error) {
      console.log(error);
    }
  }

  async getTransitionType() {
    try {
      const transitionType = await getTrasitionTypeAsync();

      if (!transitionType) {
        throw new Error("Transition type do not exist!");
      }

      this.setState({ transitionType });
    } catch (error) {
      console.log(error);
    }
  }

  onClick() {
    const { id, threeJSWorldContext } = this.props;
    const { linkedRoom, transitionType } = this.state;

    MovedOnce();
    InteractedOnce();
    addClickedRoomMarker(id);

    function loadNewScene() {
      const targetPath = "/" + linkedRoom.name;
      ArrowClicked(linkedRoom.name);
      threeJSWorldContext.pushHistoryWithReset(targetPath);
    }

    threeJSWorldContext.onClick(loadNewScene, transitionType);
  }

  render() {
    const { svgSprite } = this.state;
    return (
      <div>
        <Marker
          transform={this.props.transform}
          colliderTransform={this.props.colliderTransform}
          svgSprite={svgSprite}
          primaryColor={this.props.primaryColor}
          secondaryColor={this.props.secondaryColor}
          rotationAngle={this.props.rotationAngle}
          showVisual={this.props.showVisual}
          onClick={this.onClick}
          fade={this.props.canFade && isRoomMarkerClicked(this.props.id)}
          markerKey={this.props.markerKey}
          markerLen={this.props.markerLen}
        />
      </div>
    );
  }
}

NavMarker.propTypes = {
  id: PropTypes.string.isRequired, // '{roomId}-{linkedRoomId}'
  transform: PropTypes.array.isRequired,
  colliderTransform: PropTypes.array.isRequired,
  linkedRoomId: PropTypes.string.isRequired,
  spriteType: PropTypes.string.isRequired,
  canFade: PropTypes.bool.isRequired,
  showVisual: PropTypes.bool,
  setLoadingIconVisibility: PropTypes.func,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  rotationAngle: PropTypes.number,
};

export default (props) => (
  <AppContext.Consumer>
    {(appContextValue) => (
      <ThreeJSWorldContext.Consumer>
        {(threeJSWorldContext) => (
          <NavMarker
            {...props}
            threeJSWorldContext={threeJSWorldContext}
            setLoadingIconVisibility={appContextValue.setLoadingIconVisibility}
          />
        )}
      </ThreeJSWorldContext.Consumer>
    )}
  </AppContext.Consumer>
);
