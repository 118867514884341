import React, {useState, useEffect} from 'react';
import './CarouselIndicator.css';

const CarouselIndicator = ({index, currentIndex, onClick}) => {
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (index === currentIndex) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [currentIndex])

    return (
        <div className={`carousel-indicator ${selected && 'carousel-indicator-selected'}`} onClick={onClick}></div>
    )
}

export default CarouselIndicator