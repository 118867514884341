import React, { Component } from 'react';

import './StoreSelector.css';
import { getBottomSelectorDataAsync, getAllSceneInfoDictAsync, getFontStylesAsync, getIconsColorAsync } from '../../../utils/StoreConfigManager.js';
import { StoreSelectorIcon } from '../../../utils/Analytics.js';
import { constructUrl } from '../../../utils/UrlConstructor';
import { getCurrentScene } from "../../../utils/History";
import BottomBarSelector from './BottomBarSelector';

class BottomStoreSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuTextFontStyle: {},
            menuTitleFontStyle: {},
            linkedStores: []
        };

        getBottomSelectorDataAsync()
            .then((storeSelector) => {
                if (storeSelector && storeSelector.short_scene_selector && storeSelector.short_scene_selector.display) {
                    let linkedStores = [];
                    storeSelector.short_scene_selector.linked_scenes.forEach(store => {
                        let storeInfo = {
                            name: store.name,
                            url: store.url,
                            scene_id: store.scene_id['$oid']
                        };
                        linkedStores.push(storeInfo);
                    });

                    this.setState({ linkedStores });
                }
            })
            .catch(error => console.error(error));

        getAllSceneInfoDictAsync()
            .then(sceneInfoDict => {
                const idNameDict = {};
                for (const [id, info] of Object.entries(sceneInfoDict)) {
                    idNameDict[id] = info['name'];
                }
                this.setState({ sceneIdNameDict: idNameDict });
            })
            .catch(error => console.error(error));

        getFontStylesAsync()
            .then(fontStyles => {
                this.setState({
                    menuTitleFontStyle: {
                        marginTop: '1em',
                        fontFamily: fontStyles.popUpTitle.name,
                        fontSize: fontStyles.popUpTitle.size
                    },
                    menuTextFontStyle: {
                        fontFamily: fontStyles.popUpText.name,
                        fontSize: fontStyles.popUpText.size
                    },
                });
            })
            .catch(error => console.error(error));
    }

    render() {
        const {
            linkedStores,
            menuTextFontStyle,
            menuTitleFontStyle,
            sceneIdNameDict
        } = this.state;

        const { isMobile, isGDPRShowing } = this.props;

        return (linkedStores.length !== 0 && sceneIdNameDict ?
            <BottomBarSelector
                linkedStores={linkedStores}
                menuTextFontStyle={menuTextFontStyle}
                menuTitleFontStyle={menuTitleFontStyle}
                sceneIdNameDict={sceneIdNameDict}
                isGDPRShowing={isGDPRShowing}
                isMobile={isMobile}
            /> : null
        );
    }
}

export default BottomStoreSelector;
