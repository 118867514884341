export const detectIEVersion = () => {
    //userAgent in IE7 WinXP returns: Mozilla/4.0 (compatible; MSIE 7.0; Windows NT 5.1; .NET CLR 2.0.50727)
    //userAgent in IE11 Win7 returns: Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko

    const detectIEregexp = (navigator.userAgent.indexOf('MSIE') != -1) ? /MSIE (\d+\.\d+);/ : /Trident.*rv[ :]*(\d+\.\d+)/;

    if (detectIEregexp.test(navigator.userAgent)) { //if some form of IE
        const ieversion=new Number(RegExp.$1)
        if (ieversion>=12)
            console.log("You're using IE12 or above");
        else if (ieversion>=11)
            console.log("You're using IE11 or above");
        else if (ieversion>=10)
            console.log("You're using IE10 or above");
        else if (ieversion>=9)
            console.log("You're using IE9 or above");
        else if (ieversion>=8)
            console.log("You're using IE8 or above");
        else if (ieversion>=7)
            console.log("You're using IE7.x");
        else if (ieversion>=6)
            console.log("You're using IE6.x");
        else if (ieversion>=5)
            console.log("You're using IE5.x");
        return true;
    } else{
        console.log('N/A')
        return false;
    }
}
