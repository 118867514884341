import React from 'react';

export const ProductModalContext = React.createContext({
    modalOpened: null,
    modalImageType: null,
    productInfo: null,
    spinUrls: null,
    hiResSpinUrls: null,
    thumbnailUrl: null,
    thumbnailUrls: null,
    productSKU: null,
    groupProducts: null,
    colorSelectorReady: null,
    modalStylingFields: null,
    fontStyles: null,
    primaryColor: null,
    secondaryColor: null,
    backgroundColor: null,
    setModalOpened: null,
    onColorSelectorLoaded: null,
    productPricePrefix: null,
    isLoading: null,
    dragToSpinIconUrl: null,
    fetchProductInfo: null,
    reactRoot: null,
    colors: null,
    buttonBorderRadius: null,
    buttonBorderWidth: null,
    buyNowBorderActiveOn: null,
    addToCartBorderActiveOn: null,
    detailsActive: null,
    setDetailsActive: null,
    showingAddToCartPrompt: null,
    setShowingAddToCartPrompt: null,
    expandedProduct: null,
    detailsTitle1: null,
    detailsTitle2: null,
    addToCartEnabled: null,
    focusMode: null,
    setFocusMode: null,
    contentHeight: null,
    setContentHeight: null,
    variantColor: null,
    setVariantColor: null,
    variantSize: null,
    setVariantSize: null,
    selectedVariantSKU: null,
    setSelectedVariantSKU: null,
    productVariantMap: null,
    baseSKU: null,
    setBaseSKU: null,
});
