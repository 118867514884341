import React, { useContext } from 'react';
import './ProductModalDetails.css';
import { ProductModalContext } from '../../ProductModalContext';
import { ContentTypes } from '../util/ProductModalEnums';
import ReactHtmlParser from 'react-html-parser';

const ProductModalDetails = () => {
    const { productInfo, fontStyles, detailsActive, contentHeight } = useContext(ProductModalContext);

    const filterStringEnds = (str) => {
        if (!str) {
            return;
        }
        const len = str.length - 1;
        if (str[len] === ',') {
            return str.substr(0, len) + '' + str.substr(len + 1); 
        }

        return str;
    }

    const details = filterStringEnds(productInfo[ContentTypes.DETAILS_BODY_1]);
    const materials = filterStringEnds(productInfo[ContentTypes.DETAILS_BODY_2]);
    
    return (
        <div className='product-modal-details-container' style={{ minHeight: `${contentHeight}px`, overflowY: 'auto', pointerEvents: detailsActive ? 'all' : 'none', opacity: detailsActive ? '1' : '0' }}>
            {details && (
                <>
                    <div className='product-modal-header' style={fontStyles.detailsTitlesFontStyle}>
                        Description
                    </div>
                    <div className='product-modal-content' style={fontStyles.detailsBodyFontStyle}> 
                        { ReactHtmlParser(details) }
                    </div>
                </>)}
            {materials && (
                <>
                    <div className='product-modal-header' style={fontStyles.detailsTitlesFontStyle}>
                        Materials
                    </div>
                    <div className='product-modal-content' style={fontStyles.detailsBodyFontStyle}>
                        { ReactHtmlParser(materials) }
                    </div>
                </>)}

        </div>
    )
}

export default ProductModalDetails;