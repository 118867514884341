import React, { Component } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";

import HotspotVisual from "../visuals/HotspotVisual.jsx";
import InteractableObject from "../interactions/InteractableObject.jsx";
import { getThreeTransformFromArray } from "../../utils/ThreeTransformConverter.js";
import { setCursorStyle } from "../../utils/MouseUtilities.js";

const hoverCursorStyle = "pointer";
const unhoverCursorStyle = "all-scroll";

const isColliderVisible = false;

const spriteScale = new THREE.Vector3(1, 0.6, 1);

class Marker extends Component {
  constructor(props) {
    super(props);
    this.onHover = this.onHover.bind(this);
    this.onUnhover = this.onUnhover.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleActiveState = this.handleActiveState.bind(this);

    this.transform = getThreeTransformFromArray(props.transform);
    this.colliderTransform = getThreeTransformFromArray(
      props.colliderTransform
    );
    this.mounted = false;
    this.state = {
      isHovered: false,
      keyboardNav: 0,
      isActive: false,
    };

    this.handleKeyboardNav = this.handleKeyboardNav.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
  }

  componentDidMount() {
    const { markerLen } = this.props;

    this.mounted = true;
    if (markerLen) {
      window.addEventListener("keydown", this.handleKeyboardNav);
      window.addEventListener("keydown", this.handleEnter);
      this.setState({ keyboardNav: markerLen[0] });
      this.handleActiveState();
      //Run handleActiveState as soon as the marker renders, so initially one NavMarker is selected.
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onHover() {
    if (this.props.onClick || this.props.onHover) {
      setCursorStyle(hoverCursorStyle);
      if (!this.mounted) {
        return;
      }
      if (this.props.onHover) {
        this.props.onHover();
      }
      this.setState({
        isHovered: true,
      });
    }
  }

  onUnhover() {
    if (this.props.onClick || this.props.onUnhover) {
      setCursorStyle(unhoverCursorStyle);
      if (!this.mounted) {
        return;
      }
      if (this.props.onUnhover) {
        this.props.onUnhover();
      }
      this.setState({
        isHovered: false,
      });
    }
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleKeyboardNav(e) {
    const { keyboardNav } = this.state;
    const { markerLen } = this.props;
    if (e.shiftKey && e.key === "ArrowDown" && keyboardNav > markerLen[0]) {
      this.setState((prevState) => ({
        keyboardNav: prevState.keyboardNav - 1,
      }));
    } else if (
      e.shiftKey &&
      e.key === "ArrowUp" &&
      keyboardNav <= markerLen[0] + markerLen.length - 1
    ) {
      this.setState((prevState) => ({
        keyboardNav: prevState.keyboardNav + 1,
      }));
    } else {
      return;
    }
    this.handleActiveState();
  }

  handleActiveState() {
    const { keyboardNav } = this.state;
    const { markerKey } = this.props;
    if (!this.mounted) {
      return;
    }
    if (keyboardNav === markerKey) {
      this.setState({
        isActive: true,
      });
    } else {
      this.setState({
        isActive: false,
      });
    }
  }

  handleEnter(e) {
    const { isActive } = this.state;
    if (e.key === "Enter" && isActive) {
      this.onClick();
    }
  }

  render() {
    const {
      fade,
      normalSpriteUrl,
      hoveredSpriteUrl,
      svgSprite,
      svgSpriteString,
      rotationAngle,
      primaryColor,
      secondaryColor,
      isLabel,
      alt,
    } = this.props;
    const { isActive } = this.state;

    const hostSpotVisual = this.props.showVisual ? (
      <HotspotVisual
        transform={this.transform}
        isHovered={this.state.isHovered}
        spriteScale={spriteScale}
        normalSpriteUrl={normalSpriteUrl}
        hoveredSpriteUrl={hoveredSpriteUrl}
        svgSprite={svgSprite}
        svgSpriteString={svgSpriteString}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        rotationAngle={rotationAngle}
        fade={fade}
        isLabel={isLabel}
        isActive={isActive}
      />
    ) : (
      <div />
    );

    return (
      <div alt={alt}>
        {hostSpotVisual}
        <InteractableObject
          colliderTransform={this.colliderTransform}
          isColliderVisible={isColliderVisible}
          isLookingCenter={true}
          onHover={this.onHover}
          onUnhover={this.onUnhover}
          onClick={this.onClick}
          // onKeyPress={this.handleEnter}
        />
      </div>
    );
  }
}

Marker.propTypes = {
  transform: PropTypes.array,
  colliderTransform: PropTypes.array,
  normalSpriteUrl: PropTypes.string,
  hoveredSpriteUrl: PropTypes.string,
  svgSprite: PropTypes.func,
  svgSpriteString: PropTypes.string,
  showVisual: PropTypes.bool,
  onHover: PropTypes.func,
  onUnhover: PropTypes.func,
  onClick: PropTypes.func,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  rotationAngle: PropTypes.number,
  fade: PropTypes.bool,
  isLabel: PropTypes.bool,
};

export default Marker;
