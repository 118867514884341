/* Controls variables for store entry overlay in an embed */

let thisScript;

if (document.currentScript) {
    thisScript = document.currentScript;
} else if (document._currentScript) {
    thisScript = document._currentScript();
} else {
    thisScript = document.scripts[document.scripts.length - 1]; 
}

const client = thisScript.getAttribute('client');
const store = thisScript.getAttribute('store');
const hideLogo = thisScript.getAttribute('hide-logo');
const containerId = thisScript.getAttribute('container-id');
const autoRotate = thisScript.getAttribute('auto-rotate');
const link = thisScript.getAttribute('link');
const title = thisScript.getAttribute('title');
const buttonText = thisScript.getAttribute('button-text');
const titleColor = thisScript.getAttribute('title-color');
const buttonTextColor = thisScript.getAttribute('button-text-color');
const buttonBackgroundColor = thisScript.getAttribute('button-background-color');
const titleFont = thisScript.getAttribute('title-font');
const buttonTextFont = thisScript.getAttribute('button-text-font');
const titleFontSize = thisScript.getAttribute('title-font-size');
const titleFontSizeMobile = thisScript.getAttribute('title-font-size-mobile');
const buttonTextFontSize = thisScript.getAttribute('button-text-font-size');
const topRightBarToBottomLeftBar = thisScript.getAttribute('top-right-bar-to-bottom-left-bar');

export function getClient() {
    return client;
}

export function getStore() {
    return store;
}

export function getHideLogo() {
    return hideLogo === '' || hideLogo === 'true';
}

export function getContainerId() {
    return containerId;
}

export function getAutoRotate() {
    return autoRotate === 'true';
}

export function getLink() {
    return link;
}

export function getTitle() {
    return title;
}

export function getButtonText() {
    return buttonText;
}

export function getTitleColor() {
    return titleColor;
}

export function getButtonTextColor() {
    return buttonTextColor;
}

export function getButtonBackgroundColor() {
    return buttonBackgroundColor;
}

export function getTitleFont() {
    return titleFont;
}

export function getButtonTextFont() {
    return buttonTextFont;
}

export function getTitleFontSize() {
    return titleFontSize;
}

export function getTitleFontSizeMobile() {
    return titleFontSizeMobile;
}

export function getButtonTextFontSize() {
    return buttonTextFontSize;
}

export function getTopRightBarToBottomLeftBar() {
    return topRightBarToBottomLeftBar === 'true';
}
