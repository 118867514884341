import React from 'react';
import PropTypes from 'prop-types';
import { ToggleArrow } from 'web-store-modules/packages/web-store-ui-library';
import './ColorDropdownSelector.css';

const ColorDropdownSelectorItem = ({ text, onClick, showArrow, arrowDown, relative, oneItem }) => {
    const containerStyle = relative ? { position: relative } : {};

    return (
        <div style={containerStyle} className={oneItem ? "color-selector-item" : "color-selector-item outline pointer"} onClick={onClick}>
            <p>{text}</p>
            <span>{showArrow && <ToggleArrow down={arrowDown} />}</span>
        </div>
    );
}

ColorDropdownSelectorItem.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    relative: PropTypes.bool,
    showArrow: PropTypes.bool,
    arrowDown: PropTypes.bool
};

ColorDropdownSelectorItem.defaultProps = {
    relative: false,
    showArrow: false
};

export default ColorDropdownSelectorItem;
