import React, { Component, useContext, useEffect } from 'react';
import { Router } from "react-router-dom";
import { createHashHistory } from 'history';
import PropTypes from 'prop-types';

import TopUILayer from './components/ui/TopUILayer.jsx';
import { ThreeJSWorld, ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import RoomRoutes from './components/room/RoomRoutes.jsx';
import { AppContext } from "./AppContext.jsx";
import AppLoadingIcon from './AppLoadingIcon.jsx';
import { WebStoreUILayer } from 'web-store-modules/packages/web-store-ui-layer';
import AddToCartContextComponent from './components/ui/add-to-cart/AddToCartContextComponent.jsx';
import { MediaContextProvider } from './components/ui/MediaContext.jsx';
import FlatImageSceneContextComponent from './components/flat-image-scene/FlatImageSceneContextComponent';
import { THREE_CACHE_MANAGER_OPTION, initThreeCacheManager } from 'web-store-modules/packages/three-cache-manager';
import { getSceneIds, getAllSceneInfoDictAsync } from './utils/StoreConfigManager.js';
import { loadUIImageAsync } from './utils/AssetLoader.js';
import { constructUrl } from './utils/UrlConstructor';
import EntranceAnimationContextComponent from './components/entrance-animation/EntranceAnimationContextComponent.jsx';

initThreeCacheManager(THREE_CACHE_MANAGER_OPTION.LAST_ROOM);

function DisableDamping() {
    const ctx = useContext(ThreeJSWorldContext)
    useEffect(() => {
        ctx.controller.controller.enableDamping = false
        ctx.controller.dampingWindow = 0
        ctx.controller.dampingFactor = 0
    }, [ctx.controller])
    return <div />
}

const history = createHashHistory();

const appStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%'
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingUI: false,
            hideRoomObjects: true,
            showingLoadingIcon: false,
            currentSceneName: '',
            firstSceneId: '',
            flatImageUrl: '',
            flatSceneDims: null
        };

        this.onFirstFullResImageLoaded = this.onFirstFullResImageLoaded.bind(this);
        this.setLoadingIconVisibility = this.setLoadingIconVisibility.bind(this);
        this.setRoomObjectVisibility = this.setRoomObjectVisibility.bind(this);
    }

    componentDidMount() {
        getSceneIds()
            .then(sceneIds => this.setState({ firstSceneId: sceneIds[0] }))
            .catch(error => console.error(error));

        getAllSceneInfoDictAsync()
            .then(sceneInfoDict => {
                this.calculateFlatImageDims(sceneInfoDict);
            })
            .catch(error => console.error(error));
    }

    onFirstFullResImageLoaded() {
        if (this.state.showingUI) {
            return;
        }
        this.setState({ showingUI: true });
    }

    setLoadingIconVisibility = (isShowing, source) => {
        this.setState({ showingLoadingIcon: isShowing });
    }

    setRoomObjectVisibility = (visibility) => {
        this.setState({ hideRoomObjects: !visibility });
    }

    setCurrentSceneName = (scene) => {
        this.setState({ currentSceneName: scene });
    }

    setFlatImageDims = (width, height) => {
        this.setState({ flatSceneDims: {width, height} });
    }

    calculateFlatImageDims = async (sceneIdDataDict) => {
        const firstSceneData = sceneIdDataDict[this.state.firstSceneId];
        if(firstSceneData.mobile_flat_scene_url) {
            const flatImageUrl = constructUrl(firstSceneData.mobile_flat_scene_url);
            const image = await loadUIImageAsync(flatImageUrl)
            const height = image.naturalHeight;
            const width = image.naturalWidth;
            const imageRatio = height / width;

            let multiplier = 1.05 
            
            let targetWidth = window.innerWidth * multiplier;
            let targetHeight = targetWidth * imageRatio;
            
            if (targetHeight < window.innerHeight) {
                targetHeight = window.innerHeight * 1.05;
                targetWidth = targetHeight / imageRatio;
            }

            this.setFlatImageDims(targetWidth, targetHeight);
        } else {
            this.setFlatImageDims('', '');
        }
    }

    render() {
        const { hideLogo } = this.props;
        const { showingUI, hideRoomObjects, showingLoadingIcon, currentSceneName, flatSceneDims } = this.state;

        const appContextValue = {
            showingLoadingIcon: showingLoadingIcon,
            onFirstFullResImageLoaded: this.onFirstFullResImageLoaded,
            setLoadingIconVisibility: this.setLoadingIconVisibility,
            setCurrentSceneName: this.setCurrentSceneName,
            currentSceneName,
        };
        return (
            <div id='app' style={appStyle}>
                <AppContext.Provider value={appContextValue}>
                    <AppLoadingIcon />
                    <AddToCartContextComponent>
                        {flatSceneDims && <ThreeJSWorld
                            history={history}
                            rootNodeId='app'
                            connectControllerImmediately={false}
                            enableDrag={false}
                            mobileFlatImageDimension={flatSceneDims} 
                            >
                            <DisableDamping />
                            <MediaContextProvider>
                                <FlatImageSceneContextComponent>
                                    <EntranceAnimationContextComponent>
                                        <TopUILayer
                                            hideLogo={hideLogo}
                                            showingUI={showingUI}
                                            setLoadingIconVisibility={this.setLoadingIconVisibility}
                                            setRoomObjectVisibility={this.setRoomObjectVisibility}
                                        />
                                        <WebStoreUILayer>
                                            <Router history={history}>
                                                <RoomRoutes hideRoomObjects={hideRoomObjects} />
                                            </Router>
                                        </WebStoreUILayer>
                                    </EntranceAnimationContextComponent>
                                </FlatImageSceneContextComponent>
                            </MediaContextProvider>
                        </ThreeJSWorld>}
                    </AddToCartContextComponent>
                </AppContext.Provider>
            </div>
        );
    }
}

App.propTypes = {
    hideLogo: PropTypes.bool
}

export default App;
