import React, { useEffect, useContext } from 'react';
import { ColorSelector } from 'web-store-modules/packages/product-modal';
import ColorDropdownSelector from './color-dropdown-selector/ColorDropdownSelector';

import ProductModalFooter from './ProductModalFooter.jsx';
import ProductModalCarousel from './carousel/ProductModalCarousel.jsx';
import ProductModalButtons from './ProductModalButtons.jsx';
import ProductModalDetails from './ProductModalDetails.jsx';

import { ProductModalContext } from '../../ProductModalContext';
import { formatPriceString } from '../util/ProductModalHelpers';
import { ContentTypes } from '../util/ProductModalEnums.js';
import { SpinActivated, ColorSelectorAnalytics } from '../../../../../../utils/Analytics';
import { AppContext } from '../../../../../../AppContext.jsx';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';

const isMobile = isMobileDevice();

const loadBodyContent = (WrappedComponent) => {
    return (props) => {
        const {
            isLoading,
            productInfo,
            modalStylingFields,
            fontStyles,
            productPricePrefix,
            thumbnailUrls,
            productSKU,
            groupProducts,
            fetchProductInfo,
            onColorSelectorLoaded,
            primaryColor,
            secondaryColor,
            showingAddToCartPrompt,
            setShowingAddToCartPrompt,
            addToCartEnabled,
            focusMode,
            setFocusMode,
            variantColor,
            setVariantColor,
            productVariantMap
        } = useContext(ProductModalContext);

        const { setLoadingIconVisibility } = useContext(AppContext);

        useEffect(() => {
            setLoadingIconVisibility(isLoading);
            console.log("=>", productVariantMap);
        }, [isLoading])

        const {
            showBrandName,
            showColor,
            showDescription,
            showPrice
        } = modalStylingFields;

        const { brandNameFontStyle, popUpTitleFontStyle, colorFontStyle, shortDescriptionFontStyle, priceFontStyle } = fontStyles;
        const [priceNum, salePriceNum] = [productInfo[ContentTypes.PRICE], productInfo[ContentTypes.SALE_PRICE]].map(price => {
            if (price || price === 0) {
                if (price % 1 !== 0) {
                    return price.toFixed(2);
                }
            }
            return price;
        });


        const onSpinActivated = () => {
            const { sku, title } = props;
            SpinActivated(sku, title);
        }

        const getProductThumbnailUrl = () => {
            if (productInfo[ContentTypes.DOWNSIZED_CAROUSEL_IMAGES])
                return productInfo[ContentTypes.DOWNSIZED_CAROUSEL_IMAGES]['carousel'][0]
            else
                return productInfo[ContentTypes.DOWNSIZED_THUMBNAIL];
        }
        const responsiveTitleStyle = {
            ...popUpTitleFontStyle,
            fontSize: isMobile ? popUpTitleFontStyle.fontSize - titleLengthFontSizingOffset() : popUpTitleFontStyle.fontSize,
        }
        const shortDescriptionStyle = { ...shortDescriptionFontStyle }
        if (priceNum == undefined) {
            responsiveTitleStyle.marginBottom = '5px';
            shortDescriptionStyle.marginBottom = '10px';
        }

        const productGroup = productInfo[ContentTypes.GROUP];
        const brand = showBrandName && <span style={brandNameFontStyle}>{productInfo[ContentTypes.BRAND]}</span>;
        const title = showBrandName && <span style={responsiveTitleStyle}>{productInfo[ContentTypes.TITLE]}</span>;
        const color = showColor && !productGroup && <span style={colorFontStyle}>{productInfo[ContentTypes.COLOR]}</span>;
        const description = showDescription && <span style={shortDescriptionStyle}>{productInfo[ContentTypes.DESCRIPTION]}</span>;
        const price = showPrice && priceNum !== undefined && priceNum > 0 && <span style={priceFontStyle}>{formatPriceString(priceNum, productInfo[ContentTypes.PRICE_CURRENCY], productInfo[ContentTypes.PRICE_SUFFIX], productInfo[ContentTypes.PRICE_PREFIX])}</span>;
        const salePrice = showPrice && salePriceNum && salePriceNum > 0 && <span style={priceFontStyle}>{formatPriceString(salePriceNum, productPricePrefix)}</span>;
        // size dropdown enabled if there is size info populated in the CSV
        const sizeDropdownEnabled = !!productVariantMap.features_map;
        const groupColors = productVariantMap.features_map && Object.keys(productVariantMap.features_map); // array of color variants for a SKU
        // const colorSwatchesEnabled = productVariantMap.siblings && !!productVariantMap.siblings[productSKU].color_image_file;
        let colorSelector = null;

        // if there is a productGroup - then there are variants (color, size, or both)
        if (false) {
            // using color swatches - CSV data has color names and color files
            colorSelector = (<div id='colorSelector'>
                <ColorSelector
                    productSKU={productSKU}
                    color={productInfo[ContentTypes.COLOR]}
                    colorFontStyle={fontStyles.colorFontStyle}
                    activeColorUrl={productInfo[ContentTypes.COLOR_IMAGE_FILE]}
                    fetchProductInfo={fetchProductInfo}
                    groupProducts={groupProducts}
                    onColorSelectorLoaded={onColorSelectorLoaded}
                    analytics={ColorSelectorAnalytics}
                    colorCircles={true}
                    defaultColor={groupColors && groupColors[0]}
                    setVariantColor={setVariantColor}
                    groupColors={groupColors}
                />
            </div>)
        } else if (groupColors) {
            // using color dropdown - CSV data has color names and no color files
            colorSelector = <ColorDropdownSelector
                fontStyle={fontStyles.colorFontStyle}
                defaultColor={productVariantMap.color}
                groupColors={groupColors}
            />
        } else {
            // no color swatches or dropdowns
            colorSelector = <div style={{ padding: focusMode ? '0' : '0.5em 0' }} />
        };


        const productImage = <ProductModalCarousel priceNum={priceNum} sku={productSKU} thumbnailArr={thumbnailUrls} onSpinActivated={onSpinActivated} focusMode={focusMode} setFocusMode={setFocusMode} productInfo={productInfo} />;

        const productButtons = !focusMode && (
            <ProductModalButtons
                buttonFontStyle={fontStyles.buttonTextFontStyle}
                productButtonCopy={modalStylingFields.productButtonCopy}
                addToCartCopy={modalStylingFields.addToCartCopy}
                sku={productSKU}
                title={productInfo[ContentTypes.TITLE]}
                price={productInfo[ContentTypes.PRICE]}
                downsizedThumbnail={getProductThumbnailUrl()}
                url={productInfo[ContentTypes.URL]}
                // cartUrl={productInfo[ContentTypes.CUSTOM_FIELD]}
                colorName={productInfo[ContentTypes.COLOR]}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                buttonBorderRadius='1em'
                showingAddToCartPrompt={showingAddToCartPrompt}
                setShowingAddToCartPrompt={setShowingAddToCartPrompt}
                addToCartEnabled={addToCartEnabled}
                addToCartFontStyle={fontStyles.addToCartTextFontStyle}
                sizeDropdownEnabled={sizeDropdownEnabled}
                groupProducts={groupProducts}
                fetchProductInfo={fetchProductInfo}
            />
        )
        // }

        const productDetails = (productInfo[ContentTypes.DETAILS_BODY_1] || productInfo[ContentTypes.DETAILS_BODY_2]) && <ProductModalDetails />

        // A map of all the components to be referenced when a load order is passed into render product content.
        const componentMap = {
            brand,
            title,
            color,
            description,
            price,
            salePrice,
            colorSelector,
            productImage,
            productButtons,
            productDetails,
        }

        const footer = (productInfo[ContentTypes.DETAILS_BODY_1] || productInfo[ContentTypes.DETAILS_BODY_2]) && (
            <ProductModalFooter />
        )

        // TODO: Once CMS functionality is defined we can retrieve load order directly from this component, or through context
        const renderProductContent = (loadOrder) => {
            return loadOrder.map((field, index) => {
                return (
                    <React.Fragment key={index}>
                        {componentMap[field]}
                    </React.Fragment>
                );
            });
        }

        return <WrappedComponent renderProductContent={renderProductContent} footer={footer} {...props} />
    }
}

export default loadBodyContent;
