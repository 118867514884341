import React, { useState, useEffect } from 'react';
import { AddToCartContext } from './AddToCartContext';
import { getStoreId, getProductPricePrefixAsync, getProductButtonColorsAsync, getProductButtonFields } from '../../../utils/StoreConfigManager';

const storeId = getStoreId();
const ADD_TO_CART_COOKIE_ENUM = 'cartList';

// TODO: Add backend cart saving support, possibly by storing UUIDs + cookies
// Add To Cart Context provides modal context with access to add/remove cart functionality
// Context also used in top level UI to create a dropdown add to cart menu
const AddToCartContextComponent = (props) => {
    const [cartList, setCartList] = useState([]);
    const [itemCount, setItemCount] = useState(0);
    const [cartButtonHidden, setCartButtonHidden] = useState(true);
    const [pricePrefix, setPricePrefix] = useState('$');
    const [colors, setColors] = useState();
    const [buttonFields, setButtonFields] = useState();

    // TODO: Currently not tied to any functionality. To be used in dropdown cart
    // Load saved cookie if ID stored in cookie matches ID of current store
    useEffect(() => {
        (async () => {
            setPricePrefix(await getProductPricePrefixAsync());
            setColors(await getProductButtonColorsAsync());
            setButtonFields(await getProductButtonFields());
        })()
        const cartListCookie = JSON.parse(getCookie(ADD_TO_CART_COOKIE_ENUM));
        if (cartListCookie && cartListCookie['id'] === storeId) {
            const savedList = cartListCookie['cartList'];
            setCartList(savedList);
        }
    }, [])
    // Saves add to cart list in a cookie
    useEffect(() => {
        if (cartList.length > 0) {
            setCartCookie();
        }
        setItemCount(cartList.length);
    }, [cartList])

    const setCartCookie = () => {
        const today = new Date();
        const tomorrow = new Date(today);

        const cookieObject = {
            id: storeId,
            cartList: cartList
        }
        const cookieString = `cartList=${JSON.stringify(cookieObject)}; expires=${tomorrow.getDate() + 1}`;
        // Sets cookie to expire a day later from latest update
        document.cookie = cookieString;
    }

    const getCookie = (name) => {
        const regEx = new RegExp(name + "=([^;]+)");
        const value = regEx.exec(document.cookie);
        return value ? unescape(value[1]) : null;
    }

    const addToCart = (item) => {
        setCartList([...cartList, item]);
    }

    const removeFromCart = (itemName) => {
        const removedList = cartList.filter(item => item.TITLE !== itemName);
        setCartList(removedList);
    }

    const contextValue = {
        cartList,
        itemCount,
        addToCart,
        removeFromCart,
        cartButtonHidden,
        setCartButtonHidden,
        pricePrefix,
        colors,
        buttonFields
    }

    return (
        <AddToCartContext.Provider value={contextValue}>
            {props.children}
        </AddToCartContext.Provider>
    )
}

export default AddToCartContextComponent;