import { BackgroundLODs } from 'web-store-modules/packages/three-background';

/**
 * CubemapUrls object, contains all supported lod urls for the background cube.
 */
export default class CubemapUrls {
    /**
     * @param {string} path - foler path to the s3 cubemap tiles folder (cdn).
     * @param bust_key - random key to bust cache
     */
    constructor(path, bust_key = "") {

        this.generateFaceUrls = this.generateFaceUrls.bind(this);
        this.generateLODUrls = this.generateLODUrls.bind(this);

        this.leftImageUrls = this.generateFaceUrls(path, 'left', bust_key);
        this.rightImageUrls = this.generateFaceUrls(path, 'right',  bust_key);
        this.topImageUrls = this.generateFaceUrls(path, 'top', bust_key);
        this.bottomImageUrls = this.generateFaceUrls(path, 'bottom', bust_key);
        this.backImageUrls = this.generateFaceUrls(path, 'back', bust_key);
        this.frontImageUrls = this.generateFaceUrls(path, 'front', bust_key);
    }

    /**
     * Generate an image urls jagged array containing all the lods for the requested background cube face.
     * @param {string} path - Folder path to the s3 cubemap tiles folder (cdn).
     * @param {string} face - The face of the background cube. Options: left, right, top, bottom, back, front.
     * @returns {Array} - Image urls jagged array for lod0, lod1, and lod2.
     * @param bust_key - random key to bust cache
     */
    generateFaceUrls(path, face, bust_key = "") {
        return [
            this.generateLODUrls(path, face, BackgroundLODs.LOD0, bust_key),
            this.generateLODUrls(path, face, BackgroundLODs.LOD1, bust_key),
            this.generateLODUrls(path, face, BackgroundLODs.LOD2, bust_key),
            this.generateLODUrls(path, face, BackgroundLODs.LOD3, bust_key)
        ];
    }

    /**
     * Generates and array for the cubemap tile urls for face at lod.
     * @param {string} path - Folder path to the s3 cubemap tiles folder (cdn).
     * @param {string} face - The face of the background cube. Options: left, right, top, bottom, back, front.
     * @param {BackgroundLODs} lod - The lod of the cubemap tiles.
     * @returns {Array} - Array of all image urls for face at lod.
     * @param bust_key - random key to bust cache

     */
    generateLODUrls(path, face, lod, bust_key = "") {
        const urls = [];
        switch (lod) {
            case BackgroundLODs.LOD0:
                urls.push(`${path}512_${face}_0_0.jpg${bust_key}`);
                break;
            case BackgroundLODs.LOD1:
                urls.push(`${path}1k_${face}_0_0.jpg${bust_key}`);
                urls.push(`${path}1k_${face}_0_1.jpg${bust_key}`);
                urls.push(`${path}1k_${face}_1_0.jpg${bust_key}`);
                urls.push(`${path}1k_${face}_1_1.jpg${bust_key}`);
                break;
            case BackgroundLODs.LOD2:
                for (let y = 0; y < 4; ++y) {
                    for (let x = 0; x < 4; ++x) {
                        urls.push(`${path}2k_${face}_${y}_${x}.jpg${bust_key}`)
                    }
                }
                break;
            case BackgroundLODs.LOD3:
                for (let y = 0; y < 8; ++y) {
                    for (let x = 0; x < 8; ++x) {
                        urls.push(`${path}4k_${face}_${y}_${x}.jpg${bust_key}`)
                    }
                }
                break;
            default:
                break;
        }
        return urls;
    }
}