import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BaseUILayerPanel from './BaseUILayerPanel.jsx';
import './DisclaimerPanel.css';
import { getClientNameAsync } from '../../../utils/StoreConfigManager.js';

class DisclaimerPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientName: ''
        };

        getClientNameAsync()
        .then(name => this.setState({ clientName: name }))
        .catch(error => console.error(error));
    }

    render() {
        const { visible, onCloseButtonClicked, onPanelClosed } = this.props;
        const clientName = this.state.clientName;
        return (
            <BaseUILayerPanel
                visible={visible}
                onCloseButtonClicked={onCloseButtonClicked}
                onPanelClosed={onPanelClosed}
            >
                <div id='disclaimerContainer'>
                    <p className='disclaimer'>
                        This fictional store is for demo purposes only.
                    </p>
                    <p className='disclaimer'>
                        {'This web site is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by ' + clientName + '. All product and company names are the registered trademarks of their original owners. The use of any logos, images, trade name or trademark is for identification and reference purposes only and does not imply any association with the trademark holder of their product brand.'}
                    </p>
                </div>
            </BaseUILayerPanel>
        );
    }
}

DisclaimerPanel.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCloseButtonClicked: PropTypes.func.isRequired,
    onPanelClosed: PropTypes.func,
};

export default DisclaimerPanel;
