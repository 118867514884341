import React, { Component } from 'react';
import PropTypes from 'prop-types';
import canvg from 'canvg';
import ReactDOMServer from 'react-dom/server';

let canvas = null;

class Hotspot extends Component {
    constructor(props) {
        super(props);
        this.getSVG = this.getSVG.bind(this);
        this.saveSVG = this.saveSVG.bind(this);
    }

    saveSVG(color, finished) {
        const svg = this.getPlainSVG(color);
        const svgString = ReactDOMServer.renderToStaticMarkup(svg);
        if (!canvas) {
            canvas = document.createElement("canvas");
        }
        const v = canvg.fromString(canvas.getContext('2d'),
            svgString,
            { enableRedraw: false, ignoreMouse: true, ignoreAnimation: true, ignoreClear: true });
        v.render();
        finished(canvas);
    }

    getSVG() {
        let color = this.props.primaryColor ? this.props.primaryColor : 'black';
        return (this.getPlainSVG(color));
    }

    render() {
        return (this.getSVG());
    }
}

Hotspot.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    property: PropTypes.object,
    setProperty: PropTypes.func
};
export default Hotspot;

