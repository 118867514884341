import React, { useState, useEffect, useRef } from 'react';
import FlyUpModal from '../FlyUpModal';
import { getFontStylesAsync } from '../../../../utils/StoreConfigManager';
import './ImageCarouselModal.css';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import ModalOverlay from '../ModalOverlay';
import { StaticImage } from 'web-store-modules/packages/modal-media';
import { AppContext } from '../../../../AppContext';
import { CarouselArrowClicked } from '../../../../utils/Analytics';
import { constructUrl } from '../../../../utils/UrlConstructor';

const isMobile = isMobileDevice();
const rightArrow = 'https://cdn.obsess-vr.com/dyson/right-arrow.png'
const leftArrow = 'https://cdn.obsess-vr.com/dyson/left-arrow.png'
const closeIcon = 'https://cdn.obsess-vr.com/dyson/close-icon-v2.png';

const ImageCarouselModal = ({ modalContent, onModalHidden, currentSceneName }) => {
    const { pages } = modalContent;
    console.log("pages============>", modalContent)
    const [fontStyles, setFontStyles] = useState();
    const modalRef = useRef();
    const [carouselWidth, setCarouselWidth] = useState();
    const [distOffset, setDistOffset] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [xTranslation, setXTranslation] = useState(0);
    const carouselContainer = useRef();
    const totalItems = pages.length;

    useEffect(() => {
        fetchFontStyles();
        addListeners();
        return (() => {
            removeListeners();
        });
    }, []);

    useEffect(() => {
        if (carouselContainer.current && carouselContainer.current.clientWidth)
            setCarouselWidth(carouselContainer.current.clientWidth);
    }, [carouselContainer.current]);

    useEffect(() => {
        setDistOffset(carouselWidth * currentIndex);
    }, [currentIndex, carouselWidth])

    useEffect(() => {
        setXTranslation(distOffset ? -distOffset : 0);
    }, [distOffset])

    const addListeners = () => {
        if (isMobile) {
            window.addEventListener('touchend', handleModalClose);
        } else {
            window.addEventListener('click', handleModalClose);
        }
    }

    const removeListeners = () => {
        if (isMobile) {
            window.removeEventListener('touchend', handleModalClose);
        } else {
            window.removeEventListener('click', handleModalClose);
        }
    }

    const fetchFontStyles = () => {
        getFontStylesAsync()
            .then((fontStyles) => setFontStyles(fontStyles))
            .catch((err) => console.error(err));

    }

    const handleModalClose = (e) => {
        if (modalRef && modalRef.current.contains(e.target)) {
            return;
        }
    }
    const textItem = () => {
        const productImageStyle = { display: 'inline', minWidth: '100%', minHeight: '100%', objectFit: 'contain', transition: 'all 500ms', padding: '0', transform: `translate(${xTranslation}px, 0px)` };

        return pages.map((x, i) => {
            return (
                <div className='text-modal-html-container' key={i} style={productImageStyle}>
                    <span style={{ fontSize: isMobile ? '60px' : '24px', fontFamily: 'futura-light-bt', marginBottom: '5px' }}> {x.title} </span>
                    <p className="subtitle">{x.subtitle}</p>
                    <div className="modal-description">
                        <img src={constructUrl(x.image)} style={{height: '300px', width:'250px', paddingTop: '10px'}}/>
                        
                        <p className="description">{x.description}</p>
                    </div>
                </div>
            )
        })
    }

    const onclickHandler = (arrowType) => {
        if(arrowType == 'left-arrow'){
            setCurrentIndex(currentIndex - 1);            
        } else {
            setCurrentIndex(currentIndex + 1);            
        }
        CarouselArrowClicked(currentSceneName);        
    }

    const textContainer = () => {
        return (
            <div className='text-modal-carousel' ref={carouselContainer} style={{overflowY: currentIndex == 0 ? 'hidden': 'auto'}}>
                {textItem()}
            </div>
        )
    }

    const arrowIndicatore = () => {
        return (
            <div className='text-modal-arrow'>
                {currentIndex > 0 && <div className="left-arrow" onClick={() => onclickHandler('left-arrow') }><img src={leftArrow} alt='left-arrow' /></div>}
                {totalItems > 1 && (currentIndex + 1) < totalItems && <div className="right-arrow" onClick={() =>  onclickHandler('right-arrow') }><img src={rightArrow} alt='right-arrow' /></div>}
            </div>
        )
    }

    const renderModalContent = () => {
        return (
            <div className='text-modal-content' style={{ textAlign: 'center' }} ref={modalRef}>
                {arrowIndicatore()}
                {textContainer()}
            </div>
        )
    }

    return (
        //Added the isImage Loaded bool here to display the close button on the Modal due to a condition in Modal.jsx
        <FlyUpModal extraClassName="text-modal-container" onModalHidden={onModalHidden} isOpaque
            textPopup hideCloseIcon isImageLoaded closeIconImage={closeIcon}>
            <ModalOverlay />
            {!!fontStyles && renderModalContent()}
        </FlyUpModal>
    );
}

export default props => (
    <AppContext.Consumer>
      {appContextValue => (
        <ImageCarouselModal {...props} currentSceneName = {appContextValue.currentSceneName}/>
      )}
    </AppContext.Consumer>
    );