import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getTwitterAsync } from '../../../utils/StaticAssetManager.js';
import { TwitterButtonAnalytics } from '../../../utils/Analytics.js';
import SocialButton from './SocialButton.jsx';

const url = window.location.href;
// const twitterShareUrl = "https://twitter.com/home?status=Shop%20the%20Levi%20virtual%20store%20in%20360%20levi.obsessvr.com%2Fsoho-demo%2F";
// 'https://twitter.com/share?text=virtualstore.carmensol.com/main/';

class TwitterButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null
        };

        this.url = props.action === 'profile'
            ? this.props.profileUrl
            : 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.props.shareCopy) +'%20' + url
            // 'https://twitter.com/home?status=Shop%20the%20' + this.props.storeName.replace(' ', '%20') + '%20virtual%20store%20in%20360%20' + url

        // getTwitterAsync()
        // .then(url => this.setState({ imageSrc: url }))
        // .catch(error => console.error(error));
    }

    render() {
        const color = this.props.color;
        // const imageSrc = this.state.imageSrc;
        return (
            <SocialButton
                imageSrc='https://cdn.obsess-vr.com/twitter.svg'
                style={this.props.style}
                redirectUrl={this.url}
                color={color}
                onClickAnalytics={TwitterButtonAnalytics}
            />
        );
    }
}

TwitterButton.propTypes = {
    style: PropTypes.object
};

export default TwitterButton;
