import React from 'react';
import SVGImage from './image-components/SVGImage';


const accessibility_icon = "https://cdn.obsess-vr.com/ada/accessibility_icon.svg";

const AccessibilityButton = ({ setShowAccessibilityModal }) => {

    const onBtnClick = () => {
        setShowAccessibilityModal();
    }

    return (
        <div className="accessibility_icon-container hoverable" onClick={onBtnClick}>
            <SVGImage
                src={accessibility_icon}
                width={50}
                height={50}
                viewbox='0 0 52 52'
                className="accessibility_icon"
            />
        </div>
        
    );
}

export default AccessibilityButton;