import React, { Component } from 'react';

import SocialEnum from './SocialType';
import FacebookButton from "./social-buttons/FacebookButton";
import TwitterButton from "./social-buttons/TwitterButton";
import InstagramButton from "./social-buttons/InstagramButton";
import SnapchatButton from "./social-buttons/SnapchatButton";
import PinterestButton from "./social-buttons/PinterestButton";
import WhatsAppButton from "./social-buttons/WhatsappButton";

import {getIconsColorAsync, getSocialDataAsync, getStoreNameAsync} from '../../utils/StoreConfigManager';

const socialRegular = [[SocialEnum.FACEBOOK, FacebookButton], [SocialEnum.TWITTER, TwitterButton], [SocialEnum.INSTAGRAM, InstagramButton], [SocialEnum.SNAPCHAT, SnapchatButton], [SocialEnum.PINTEREST, PinterestButton],[SocialEnum.WHATSAPP, WhatsAppButton]];
const socialReversed = [[SocialEnum.PINTEREST, PinterestButton], [SocialEnum.SNAPCHAT, SnapchatButton], [SocialEnum.INSTAGRAM, InstagramButton], [SocialEnum.TWITTER, TwitterButton], [SocialEnum.FACEBOOK, FacebookButton],[SocialEnum.WHATSAPP, WhatsAppButton]];

export default class Social extends Component {
    constructor(props) {
        super(props);

        this.state = {
            socialData: [],
            iconsColor: '#ffffff',
            storeName: 'Obsess'
        };
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        const socialPromise = getSocialDataAsync();
        const colorPromise = getIconsColorAsync();
        const namePromise = getStoreNameAsync();

        Promise.all([socialPromise, colorPromise, namePromise]).then((values) => {
            if (this.mounted) {
                console.log("values values ",values);
                this.setState({ socialData: values[0], iconsColor: values[1], storeName: values[2] });
            }
        })
        .catch(error => console.error(error));
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        if (!this.state.socialData) {
            return (<React.Fragment/>);
        }
        const order = this.props.socialReversed ? socialReversed : socialRegular;
        const buttonStyle = this.props.socialReversed ? {marginLeft: '0.7em', top: '0.5em'} : {marginRight: '0.7em', bottom: '0.15em'}
        const containerStyle = this.props.socialReversed ? {marginRight: '0.5em'} : null;

        const buttons = order.map(([platform, Button], index) => {
            const databasePlatform = this.state.socialData.find(dataPlatform => dataPlatform.platform === platform);
            const action = databasePlatform ? databasePlatform.action : null;
            const profileUrl = databasePlatform && databasePlatform.profile_url ? databasePlatform.profile_url.path : null;
            const shareCopy = databasePlatform && databasePlatform.share_copy ? databasePlatform.share_copy : 'Shop the ' + this.state.storeName + ' virtual store in 360 ';

            return action
                ? <Button
                    key={index}
                    style={buttonStyle}
                    color={this.state.iconsColor}
                    storeName={this.state.storeName}
                    action={action}
                    profileUrl={profileUrl}
                    shareCopy={shareCopy}
                />
                : <React.Fragment key={index}/>
        });

        return <span style={containerStyle}>{buttons}</span>
    }
}
