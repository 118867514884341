import React, { Component } from "react";
import Proptypes from "prop-types";

import { WhatsAppButtonAnalytics } from "../../../utils/Analytics.js";
import { isMobileDevice } from "web-store-modules/packages/obsess-device-detector";
import SocialButton from "./SocialButton.jsx";

const url = window.location.href;
const isMobile = isMobileDevice();

class WhatsAppButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: null,
    };
    this.url =
      props.action === "profile"
        ? this.props.profileUrl
        : isMobile
        ? "whatsapp://send?text=" +
          encodeURIComponent(this.props.shareCopy) +
          "%20" +
          url
        : "https://web.whatsapp.com/send?text=" +
          encodeURIComponent(this.props.shareCopy) +
          "%20" +
          url;
  }

  render() {
    const color = this.props.color;
    console.log("this.url-------->", this.url);
    return (
      <SocialButton
        imageSrc="https://s3.amazonaws.com/obsessvr-webstore-assets-public/whatsapp.svg"
        style={this.props.style}
        redirectUrl={this.url}
        color={color}
        onClickAnalytics={WhatsAppButtonAnalytics}
      />
    );
  }
}

WhatsAppButton.propTypes = {
  style: Proptypes.object,
};

export default WhatsAppButton;
