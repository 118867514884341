import React, { useState, useEffect, useContext } from 'react';
import { detectBrowser } from 'web-store-modules/packages/obsess-device-detector';
import { loadUIImageAsync } from 'web-store-modules/packages/asset-loader';

import { ProductModalContext } from './ProductModalContext.jsx';

import { ContentTypes, ImageTypes } from './extended-product-modal/util/ProductModalEnums.js';
import {
    getProductInfoAsyncObsessAPI,
    getGroupProductInfosObsessAPI
} from './extended-product-modal/util/ProductModalData.js';
import {
    getStoreId,
    productModalFieldsAsync,
    getStyleColorAsync,
    getPopupBackgroundColor,
    getProductPricePrefixAsync,
    getFontStylesAsync,
    getProductButtonColorsAsync,
    getProductButtonFields,
    getExtendedProductData,
    getAddToCartEnabled
} from '../../../../utils/StoreConfigManager';
import { constructUrl } from '../../../../utils/UrlConstructor.js';
import { getDragToSpinIconAsync } from '../../../../utils/StaticAssetManager';
import {
    getEnumeratedProductInfo,
    constructSpinImageUrls
} from './extended-product-modal/util/ProductModalHelpers.js';

const variables = require('../../../../../../variables/Variables.js');

const { apiUrl, reactRoot, s3Bucket } = variables;

const isIE = detectBrowser() === 'ie';
const DEFAULT_DRAG_ICON = "https://cdn.obsess-vr.com/drag-to-spin-icon.png";

const ProductModalContextComponent = ({ ProductSKU, onProductTitleLoaded, children }) => {
    // TODO: Might be necessary to nest some of this data ...
    const [modalOpened, setModalOpened] = useState(false);
    const [productInfo, setProductInfo] = useState();
    const [thumbnailUrls, setThumbnailUrls] = useState();
    const [groupProducts, setGroupProducts] = useState();
    const [colorSelectorReady, setColorSelectorReady] = useState(false) // Used to correctly resize zoomed images after color selector is loaded
    const [modalStylingFields, setModalStylingFields] = useState();
    const [fontStyles, setFontStyles] = useState();
    const [primaryColor, setPrimaryColor] = useState();
    const [secondaryColor, setSecondaryColor] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [productPricePrefix, setProductPricePrefix] = useState();
    const [dragToSpinIconUrl, setDragToSpinIconUrl] = useState(DEFAULT_DRAG_ICON);
    const [productSKU, setProductSKU] = useState(ProductSKU);
    const [colors, setColors] = useState();
    const [buttonBorderRadius, setButtonBorderRadius] = useState('0');
    const [buttonBorderWidth, setButtonBorderWidth] = useState('0');
    const [buyNowBorderActiveOn, setBuyNowBorderActiveOn] = useState({
        primary: false,
        secondary: false
    });
    const [addToCartEnabled, setAddToCartEnabled] = useState(false);
    const [addToCartBorderActiveOn, setAddToCartBorderActiveOn] = useState({
        primary: false,
        secondary: false
    });
    const [detailsActive, setDetailsActive] = useState(false);
    const [showingAddToCartPrompt, setShowingAddToCartPrompt] = useState(false);
    const [hiResLoaded, setHiResLoaded] = useState(false);
    const [expandedProduct, setExpandedProduct] = useState(false);
    const [detailsTitle1, setDetailsTitle1] = useState();
    const [detailsTitle2, setDetailsTitle2] = useState();
    const [focusMode, setFocusMode] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [variantColor, setVariantColor] = useState('');
    const [variantSize, setVariantSize] = useState('');
    const [selectedVariantSKU, setSelectedVariantSKU] = useState(null);
    const [productVariantMap, setProductVariantMap] = useState(null);
    const [baseSKU, setBaseSKU] = useState('');

    useEffect(() => {
        fetchPopupInfo()
        fetchProductInfo(ProductSKU);
        fetchStylingInfo();
        fetchButtonStyling();
        setBaseSKU(ProductSKU);
    }, []);

    useEffect(() => {
        if (selectedVariantSKU) {
            fetchProductInfo(selectedVariantSKU);
        }
    }, [selectedVariantSKU, productVariantMap])

    // Begin loading hi-res images once modal is active
    useEffect(() => {
        // Hi res image is loaded only after low res image is set because if the images are being set under a race condition, low res image would show up for a second and change, resulting in a jittery user experience
        (async () => {
            if (!hiResLoaded && productInfo && modalOpened && thumbnailUrls) {
                // Async loading for extended pop up
                if (expandedProduct) {
                    thumbnailUrls.forEach(async (thumbnail) => {
                        if (thumbnail.hiResThumbnailUrl) {
                            const hiResImage = await loadUIImageAsync(thumbnail.hiResThumbnailUrl)
                            thumbnail.thumbnailUrl = hiResImage.src;
                        }
                    });
                } else { // Async loading for original pop up
                    const hiResImage = await loadUIImageAsync(thumbnailUrls[0].hiResThumbnailUrl);
                    const thumbnail = thumbnailUrls[0];
                    thumbnail.thumbnailUrl = hiResImage.src;
                    setThumbnailUrls([thumbnail]);
                }

                setHiResLoaded(true);
            }
        })()
    }, [modalOpened, thumbnailUrls])

    useEffect(() => {
        if (modalStylingFields && productInfo && fontStyles) {
            setIsLoading(false);
        }
    }, [modalStylingFields, productInfo, fontStyles]);

    const fetchPopupInfo = async () => {
        const [extendedPopup, title1, title2] = await getExtendedProductData();
        setExpandedProduct(extendedPopup);
        setDetailsTitle1(title1);
        setDetailsTitle2(title2);
        setAddToCartEnabled(await getAddToCartEnabled());

    }

    const fetchProductInfo = async (sku) => {
        if (sku !== productSKU) {
            setProductSKU(sku);
        }

        processProductInfo(await getProductInfoAsyncObsessAPI(sku, apiUrl, getStoreId()));
    }

    const fetchDragToSpinIconUrl = async () => {
        const dragUrl = await getDragToSpinIconAsync();
        setDragToSpinIconUrl(dragUrl);
    }

    const fetchStylingInfo = async () => {
        setModalStylingFields(await productModalFieldsAsync());

        const fontStyles = await getFontStylesAsync();
        setFontStyles(() => {
            const fontStyleState = {};
            Object.entries(fontStyles).forEach(([key, value]) => {
                fontStyleState[`${key}FontStyle`] = {
                    // hardcoded until product finds the font file to upload in CMS
                    fontFamily: value.name,
                    fontSize: value.size,
                    fontWeight: value.weight,
                    color: value.color
                }
            });

            return fontStyleState;
        });

        const colors = await getStyleColorAsync();
        setPrimaryColor(colors[0]);
        setSecondaryColor(colors[1]);

        const bgColor = await getPopupBackgroundColor();
        setBackgroundColor(bgColor);
        const pricePrefix = await getProductPricePrefixAsync();
        setProductPricePrefix(pricePrefix);
    }

    const fetchButtonStyling = async () => {
        try {
            setColors(await getProductButtonColorsAsync());
            const buttonFields = await getProductButtonFields();

            setButtonBorderRadius(buttonFields.buttonBorderRadius);
            setButtonBorderWidth(buttonFields.buttonBorderWidth);
            setBuyNowBorderActiveOn(buttonFields.buyNowBorderActiveOn)
            setAddToCartBorderActiveOn(buttonFields.addToCartBorderActiveOn);
        }
        catch (error) {
            console.error(error);
        }
    }

    /**
     * Product image processing function
     * Take in product information and be able to output a carousel of 1 -> n images
     * Array of images output should be agnostic to image type and be able to handle any combination of images
     * @input : ImageTypes: {STATIC, ZOOM, SPIN, MODEL}
     * @output : Array of product image objects
     * ImageObject: {
     *      @param url: "image source",
     *      @param spinUrls: "Spin urls object",
     *      @param hiResSpinUrls: "Spin urls object",
     *      @param type: "image type",
     * }
     */

    const processProductImages = async (data) => {
        const colorSelectorReadyBool = !data[ContentTypes.GROUP] || data[ContentTypes.GROUP] === ',';

        const gltfFile = data[ContentTypes.GLTF_FILE];
        const usdzFile = data[ContentTypes.USDZ_FILE];

        const downsizedThumbnail = data[ContentTypes.DOWNSIZED_THUMBNAIL];
        const thumbnail = data[ContentTypes.THUMBNAIL];

        const downsizedSpinImageObjects = data[ContentTypes.DOWNSIZED_SPIN_IMAGES];
        const spinImageObjects = data[ContentTypes.SPIN_IMAGES];

        const downsizedCarouselImages = data[ContentTypes.DOWNSIZED_CAROUSEL_IMAGES];
        const carouselImages = data[ContentTypes.CAROUSEL_IMAGES];

        const video = data[ContentTypes.VIDEO];

        const getProductThumbnailUrl = () => {
            if (downsizedCarouselImages) {
                return downsizedCarouselImages['carousel'][0]
            } else if (downsizedThumbnail) {
                return downsizedThumbnail;
            } else {
                return null;
            }
        }

        const previewThumbnail = getProductThumbnailUrl();

        const constructProps = (type, scale) => {
            return ({
                type,
                dragToSpinIconUrl,
                arEnabled: type === ImageTypes.THREE ? 'true' : 'false', // TODO: Retrieve AR-enabled param from CMS
                zoomLevel: 3,
                sku: productSKU,
                scalingDimReady: colorSelectorReadyBool
            })
        }


        let spinUrls;
        let hiResSpinUrls;

        const loadModelViewer = () => {
            if (isIE) {
                return;
            }

            if ((gltfFile || usdzFile) && previewThumbnail) {
                const gltfModel = gltfFile && constructUrl(gltfFile);
                const usdzModel = usdzFile && constructUrl(usdzFile);
                return {
                    gltfModel,
                    usdzModel,
                    thumbnailUrl: constructUrl(previewThumbnail), ...constructProps(ImageTypes.THREE)
                };
            }
        }

        const loadSpinImages = () => {
            if (spinImageObjects && Object.values(spinImageObjects).some((pov) => pov)) {
                // If no downsized spin images, make spinUrls the regular spin images. If downsized spin images exist, make
                // spinUrls the downsized spin images and hiResSpinUrls the regular spin images
                if (downsizedSpinImageObjects) {
                    spinUrls = constructSpinImageUrls(downsizedSpinImageObjects, s3Bucket);
                    hiResSpinUrls = constructSpinImageUrls(spinImageObjects, s3Bucket, false);
                } else {
                    spinUrls = constructSpinImageUrls(spinImageObjects, s3Bucket);
                }
                return { spinUrls, hiResSpinUrls, thumbnailUrl: constructUrl(previewThumbnail), ...constructProps(ImageTypes.SPIN) };
            }
        }

        const loadCarouselImages = async () => {
            if (carouselImages) {
                if (typeof downsizedCarouselImages != 'undefined' && downsizedCarouselImages.length > 0) {
                    // preload downsized image, then push source into thumbnail urls
                    const downsizedArr = downsizedCarouselImages.carousel.map((thumbnail, index) => {
                        const thumbnailUrl = constructUrl(thumbnail)

                        if (index === 0) {
                            loadUIImageAsync(thumbnailUrl);
                            return {
                                thumbnailUrl,
                                hiResThumbnailUrl: constructUrl(carouselImages.carousel[index]), ...constructProps(
                                    ImageTypes.ZOOM)
                            };
                        }

                        return {
                            thumbnailUrl,
                            hiResThumbnailUrl: constructUrl(carouselImages.carousel[index]), ...constructProps(
                                ImageTypes.ZOOM)
                        };

                    })

                    return downsizedArr;
                } else {
                    const carouselArr = carouselImages.carousel.map((thumbnail, index) => {
                        const thumbnailUrl = constructUrl(thumbnail)
                        if (index === 0) {
                            return {
                                thumbnailUrl,
                                hiResThumbnailUrl: thumbnailUrl, ...constructProps(
                                    ImageTypes.ZOOM)
                            };
                        }

                        return {
                            thumbnailUrl,
                            hiResThumbnailUrl: thumbnailUrl, ...constructProps(
                                ImageTypes.ZOOM)
                        };

                    })

                    return carouselArr;
                }
            } else {
                // In the case we upload only one image of a product we set thumbnail url in an array to maintain data structure
                const thumbnailObj = downsizedThumbnail ? downsizedThumbnail : thumbnail;
                const downsizedThumbnailUrl = constructUrl(thumbnailObj);
                loadUIImageAsync(downsizedThumbnailUrl);
                return {
                    thumbnailUrl: downsizedThumbnailUrl,
                    hiResThumbnailUrl: constructUrl(thumbnail), ...constructProps(ImageTypes.ZOOM)
                };
            }
        }

        const loadVideo = () => {
            if (video) {
                const videoUrl = constructUrl(video);
                return { videoUrl, thumbnailUrl: constructUrl(previewThumbnail), ...constructProps(ImageTypes.VIDEO) };
            }
        }

        const thumbnailLoadOrder = (mediaOrder) => {
            const mediaArr = [];
            mediaOrder.forEach(async (loadMedia) => {
                const media = await loadMedia();
                if (!media) {
                    return;
                }
                if (media.length > 0) {
                    media.forEach((element) => mediaArr.push(element))
                } else {
                    mediaArr.push(media);
                }
            });
            return mediaArr;
        }

        setThumbnailUrls(await thumbnailLoadOrder([
            loadCarouselImages,
            loadSpinImages,
            loadModelViewer,
            loadVideo,
        ]));

        setColorSelectorReady(colorSelectorReadyBool);
    }

    const processProductInfo = (fetchedProductInfo) => {
        // variantMap to lookup SKU for particular variantColor and variantSize
        setProductVariantMap(fetchedProductInfo);
        const enumeratedProductInfo = getEnumeratedProductInfo(fetchedProductInfo, s3Bucket);

        onProductTitleLoaded(enumeratedProductInfo[ContentTypes.TITLE]);
        // colorSelectorReady value is true by default if the product modal shouldn't have a color selector
        fetchDragToSpinIconUrl();
        processProductImages(enumeratedProductInfo);

        if (enumeratedProductInfo[ContentTypes.GROUP] && enumeratedProductInfo[ContentTypes.GROUP] !== ',') {
            fetchGroupProducts(enumeratedProductInfo)
        }

        setProductInfo(enumeratedProductInfo);
    }

    const fetchGroupProducts = (productInfo) => {
        return getGroupProductInfosObsessAPI(productInfo[ContentTypes.GROUP], apiUrl, getStoreId())
            .then(groupProducts => {
                setGroupProducts(groupProducts.map(product => getEnumeratedProductInfo(product,
                    s3Bucket)));
            })
    }

    const onColorSelectorLoaded = () => setColorSelectorReady(true);

    const contextValue = {
        modalOpened,
        productInfo,
        thumbnailUrls,
        productSKU,
        groupProducts,
        colorSelectorReady,
        modalStylingFields,
        fontStyles,
        primaryColor,
        secondaryColor,
        backgroundColor,
        setModalOpened,
        onColorSelectorLoaded,
        productPricePrefix,
        isLoading,
        dragToSpinIconUrl,
        fetchProductInfo,
        reactRoot,
        colors,
        buttonBorderRadius,
        buttonBorderWidth,
        buyNowBorderActiveOn,
        addToCartBorderActiveOn,
        detailsActive,
        setDetailsActive,
        showingAddToCartPrompt,
        setShowingAddToCartPrompt,
        expandedProduct,
        detailsTitle1,
        detailsTitle2,
        addToCartEnabled,
        focusMode,
        setFocusMode,
        contentHeight,
        setContentHeight,
        variantColor,
        setVariantColor,
        variantSize,
        setVariantSize,
        selectedVariantSKU,
        setSelectedVariantSKU,
        productVariantMap,
        baseSKU,
        setBaseSKU,
    };
    return (
        <ProductModalContext.Provider value={contextValue}>
            {productInfo && children}
        </ProductModalContext.Provider>
    )
}

export default ProductModalContextComponent;
