import React, { Component } from 'react';
import '../StoreSelector.css';
import './OldStoreSelector.css';
import { setCursorStyle } from '../../../../utils/MouseUtilities.js';
import Segment from 'semantic-ui-react/dist/es/elements/Segment';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/image.min.css';

const hoverCursorStyle = 'pointer';
const unhoverCursorStyle = 'all-scroll';

import { StoreSelectorOption } from '../../../../utils/Analytics';

class OldStoreSelectorMenuItem extends Component {
    constructor(props) {
        super(props);

        this.onHover = this.onHover.bind(this);
        this.onUnhover = this.onUnhover.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onHover() {
        setCursorStyle(hoverCursorStyle);
    }

    onUnhover() {
        setCursorStyle(unhoverCursorStyle);
    }

    onClick() {
        StoreSelectorOption(this.props.store.name);
        window.location.href = this.props.store.url;
    }

    render() {
        let style = Object.assign({}, this.props.menuTextFontStyle);
        if (!style.hasOwnProperty('color')) {
            style["color"] = "#000000"
        }
        return (
            <Segment
                className="overflow-auto text-align-left"
                onMouseOver={this.onHover}
                onMouseOut={this.onUnhover}
                onClick={this.onClick}
            >
                <div>
                    <span id="selector-store-name" style={style}> {this.props.store.name} </span>
                    <br />
                    <span id="selector-store-location" style={style}> {this.props.store.location} </span>
                </div>
            </Segment>
        );
    }
}

export default OldStoreSelectorMenuItem;
