import { isMobileDevice, getMobileOS } from 'web-store-modules/packages/obsess-device-detector';

const isMobile = isMobileDevice();
const INSTAGRAM_ICON = 'https://cdn.obsess-vr.com/instagram-transparent-icon.png';
const EMAIL_ICON = 'https://cdn.obsess-vr.com/email-icon-mobile.png';
const FACEBOOK_ICON = 'https://cdn.obsess-vr.com/facebook-icon-mobile.png';
const TWITTER_ICON = 'https://cdn.obsess-vr.com/twitter-icon-mobile.png';
const PINTEREST_ICON = 'https://cdn.obsess-vr.com/pinterest-icon-mobile.png';
const COPY_LINK_ICON = 'https://cdn.obsess-vr.com/link-icon-mobile.png';

const shareOptions = {
    desktop: [
        {
            analyticsName: 'Mail',
            icon: EMAIL_ICON,
            prefilled: true,
            key: 'email'
        },        
        {
            analyticsName: 'Facebook',
            icon: FACEBOOK_ICON,
            url: 'https://www.facebook.com/sharer/sharer.php?u=',
            prefilled: false,
            enable: false,
            key: 'facebook'
        },
        {
            analyticsName: 'Twitter',
            icon: TWITTER_ICON,
            url: 'https://twitter.com/intent/tweet?text=',
            prefilled: true,
            targetSelf: true,
            enable: false,
            key: 'twitter'
        },
        {
            analyticsName: 'Pinterest',
            icon: PINTEREST_ICON,
            url: 'http://pinterest.com/pin/create/link/?url=',
            prefilled: false,
            enable: false,
            key: 'pinterest'
        },
        {
            analyticsName: 'CopyLink',
            icon: COPY_LINK_ICON,
            prefilled: true,
            textAbove: 'LINK COPIED',
            enable: false,
            key: 'copy_link'
        }
    ],
    mobile: [
        {
            analyticsName: 'Instagram',
            icon: INSTAGRAM_ICON,
            url: 'getInstagramLink',
            prefilled: true,
            enable: false,
            key: 'instagram'
        },        
        {
            analyticsName: 'Facebook',
            icon: FACEBOOK_ICON,
            url: 'https://www.facebook.com/sharer/sharer.php?u=',
            prefilled: false,
            enable: false,
            key: 'facebook'
        },
        {
            analyticsName: 'Twitter',
            icon: TWITTER_ICON,
            url: 'https://twitter.com/intent/tweet?text=',
            prefilled: true,
            targetSelf: true,
            enable: false,
            key: 'twitter'
        },
        {
            analyticsName: 'Pinterest',
            icon: PINTEREST_ICON,
            url: 'http://pinterest.com/pin/create/link/?url=',
            prefilled: false,
            enable: false,
            key: 'pinterest'
        },
        {
            analyticsName: 'CopyLink',
            icon: COPY_LINK_ICON,
            prefilled: true,
            textAbove: 'LINK COPIED',
            enable: false,
            key: 'copy_link'
        }
    ]
}

export const getShareOptions = (shareUrl, storeName, isMobile) => {
    const options = isMobile ? shareOptions.mobile : shareOptions.desktop;

    return options.map(option => {
        if (option.analyticsName === 'Mail') {
            option.url = `mailto:?subject=Check out this new virtual store - ${storeName}&body=`;
            option.body = shareUrl;
        } else if (option.analyticsName === 'CopyLink') {
            option.url = shareUrl;
        } else if (option.analyticsName === 'Twitter') {
            option.body = shareUrl;
        } else if (option.analyticsName === 'Facebook') {
            option.body = shareUrl;
        } else {
            option.body = shareUrl;
        }
        return option;
    })
}

export function getInstagramLink(igHandle) {
    if (isMobile) {
        if (getMobileOS() === 'iOS') {
            return `instagram://user?username=${igHandle}`;
        } else {
            return `intent://instagram.com/_u/${igHandle}/#Intent;package=com.instagram.android;scheme=https;end`;
        }
    } else {
        return `https://www.instagram.com/${igHandle}/`;
    }
}

export const getAllDescendantIDs = (node, IDs) => {
    IDs = IDs || [];
    for (let i = 0; i < node.childNodes.length; i++) {
        IDs.push(node.childNodes[i].id);
        getAllDescendantIDs(node.childNodes[i], IDs)
    }
    return IDs
}