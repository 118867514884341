/*
    The first React Component that deals with an individual scene instead of the application as a whole. Room takes the
    scene id passed down from RoomRoutes and fetches the scene image, associated blurred image, and RoomObjects, and
    renders them all to the screen
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../AppContext.jsx';
import { constructUrl } from '../../utils/UrlConstructor';
import { FlatImageSceneContext } from '../flat-image-scene/FlatImageSceneContextComponent';
import { getBlurredImageUrl, getRoomObjectRenderAsync, getSceneIds } from '../../utils/RoomConstructor.js';
import { getInitialAnimationedPlayed } from "../../InitialAnimationEnums";
import { getShowInitialAnimation, getAllSceneInfoDictAsync } from "../../utils/StoreConfigManager";
import { isMobileDevice } from "../../utils/DeviceDetector";
import { loadTextureAsync } from 'web-store-modules/packages/asset-loader'
import { RoomContext } from './RoomContext.jsx';
import { SceneBackground, TiledSceneBackground, FlatSceneBackground } from 'web-store-modules/packages/scene-background';
import { SceneLoaded, VirtualPageVisited } from '../../utils/Analytics.js';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import CubemapUrls from './CubemapUrls.js';
import EntranceAnimation from '../entrance-animation/EntranceAnimation.jsx';
import { EntranceAnimationContext } from '../entrance-animation/EntranceAnimationContextComponent.jsx';

const isMobile = isMobileDevice();
class Room extends Component {
    constructor(props) {
        super(props);

        this.initAutoRotate = this.initAutoRotate.bind(this);
        this.onBackgroundTextureLoaded = this.onBackgroundTextureLoaded.bind(this);
        this.onLod1Loaded = this.onLod1Loaded.bind(this);
        this.initAutoRotate = this.initAutoRotate.bind(this);

        this.state = {
            isLoading: true,
            roomObjectRender: null,
            imageUrl: '',
            blurredImageUrl: '',
            cubemapUrls: null,
            flatSceneUrl: '',
            lod2Ready: !!getInitialAnimationedPlayed(),
            showInitialAnimation: false,
            animationFetched: false,
            neighbourIds: []
        };        
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isAnimationVideoEnd !== this.props.isAnimationVideoEnd && this.props.isEntranceAnimationScene) {
            this.props.threeJSWorldContext.connectController()
            setTimeout(() => {
                this.initAutoRotate()
            }, 10000);
        }
    }


    shouldPlayAnimation() {
        return !getInitialAnimationedPlayed() && this.state.showInitialAnimation;
    }

    async componentDidMount() {
        const { id, sceneData, setLoadingIconVisibility, threeJSWorldContext, setCurrentSceneName } = this.props;
        const { setIsFlatImageSceneWithSceneIds, setFlatImageButtonOption, setFlatImageButtonText } = this.props.flatImageSceneContextValue;
        const name = sceneData['name'];
        const { setupInitialAnimation, connectController, disconnectController } = threeJSWorldContext;

        setCurrentSceneName(name);

        let storeData  = await getAllSceneInfoDictAsync();
        const currentSceneData = storeData[sceneData.id] 
        if (id === currentSceneData.id && currentSceneData.first_scene_video_url && !this.props.isAnimationVideoEnd) {
            this.props.setIsEntranceAnimationScene(true)
        } else {
            this.props.setIsEntranceAnimationScene(false)
        }

        const flatSceneId = sceneData.isFlatImageScene && id;
        setIsFlatImageSceneWithSceneIds(id, flatSceneId);
        setFlatImageButtonOption(sceneData.flat_scene_button);
        setFlatImageButtonText(sceneData.flat_scene_button_text);

        //Show Loading Icon
        if (setLoadingIconVisibility) {
            setLoadingIconVisibility(true, "b");
        }

        getShowInitialAnimation()
            .then(showInitialAnimation => {
                const stateToSet = { showInitialAnimation };               
                if (showInitialAnimation && !getInitialAnimationedPlayed()) {
                    setupInitialAnimation();
                }                
                if (sceneData.isFlatImageScene) {
                    disconnectController();
                } else {
                    if (this.props.isEntranceAnimationScene) {
                        disconnectController();
                    } else {
                        connectController();
                    }                    
                }
                // Don't wait for animation to start loading cubemap LoD if we aren't using animation for this store
                if (!showInitialAnimation && !this.state.lod2Ready) {
                    stateToSet.lod2Ready = true
                }
                this.setState(stateToSet);
            });

        this.prepareBackgroundScene();
        this.setRoomObjbectRenders();
        VirtualPageVisited(name);
    }

    setRoomObjbectRenders() {
        getRoomObjectRenderAsync(this.props.id)
            .then((roomObjectRender) => { this.setState({ roomObjectRender }); return roomObjectRender; })
            .then(objects => {
                const neighbourIds = objects.props.children.flatMap(child => 'linkedRoomId' in child.props ? child.props.linkedRoomId : [] )
                const neighborCubeScenes = neighbourIds.filter(id => 'cube_map_dir' in this.props.scenesInfo[id]).map(id => ({ data: this.props.scenesInfo[id], id }))
                neighborCubeScenes.forEach(({ data, id }) => this.preloadCubemap(data, id))
            })
            .catch((error) => console.error('Load view products failed with error:', error));
    }

    getBustKey() {
        /*
        * Returns a cache busting key from sceneData
        */
        const { sceneData } = this.props;
        return sceneData["image_integrity"] ? "?key=" + sceneData["image_integrity"].replace(/\D/g,
            '') : "";
    }

    loadFromFlatScene() {
        const { sceneData } = this.props;
        const { background, backgroundSphere, flatBackground, flatBackgroundScene } = this.props.threeJSWorldContext;

        if (!flatBackground.scene) {
            flatBackground.addToScene(flatBackgroundScene);
        }
        if (background.scene) {
            background.removeFromScene()
        }
        if (backgroundSphere.scene) {
            backgroundSphere.removeFromScene();
        }

        const flatSceneUrl = (isMobile && sceneData.mobile_flat_scene_url) ? constructUrl(sceneData.mobile_flat_scene_url) : constructUrl(sceneData.flat_scene_url) + this.getBustKey();
        if (flatSceneUrl) {
            this.setState({ flatSceneUrl });
        } else {
            console.log("has no flat image!");
        }
    }

    preloadCubemap = async (sceneData, roomId) => {
        console.log('preloading neighbor cube map')
        const cubemapPath = constructUrl(sceneData.cube_map_dir);
        const face_imgs = new CubemapUrls(cubemapPath, '')
        const imgs_to_load = [
            // ...face_imgs.leftImageUrls[0],
            // ...face_imgs.rightImageUrls[0],
            // ...face_imgs.topImageUrls[0],
            // ...face_imgs.bottomImageUrls[0],
            // ...face_imgs.backImageUrls[0],
            // ...face_imgs.frontImageUrls[0],
            ...face_imgs.leftImageUrls[1],
            ...face_imgs.rightImageUrls[1],
            ...face_imgs.topImageUrls[1],
            ...face_imgs.bottomImageUrls[1],
            ...face_imgs.backImageUrls[1],
            ...face_imgs.frontImageUrls[1],
        ]

        await Promise.all(imgs_to_load.map(url => loadTextureAsync(url, null, roomId)))
        console.log(`prefetched ${imgs_to_load.length} images for neighbor cube room`)
    }

    loadFromCubemap() {
        const cubeMapDirObject = this.props.sceneData['cube_map_dir'];
        const { threeJSWorldContext } = this.props;

        if (!threeJSWorldContext.background.scene) {
            threeJSWorldContext.background.addToScene(threeJSWorldContext.scene);
        }
        if (threeJSWorldContext.backgroundSphere.scene) {
            threeJSWorldContext.backgroundSphere.removeFromScene();
        }
        const cubemapPath = constructUrl(cubeMapDirObject);
        this.setState({ cubemapUrls: new CubemapUrls(cubemapPath, this.getBustKey()) });
    }

    loadFromEqRectangular() {
        const { threeJSWorldContext } = this.props;
        const { sceneData } = this.props;

        if (!threeJSWorldContext.backgroundSphere.scene) {
            threeJSWorldContext.backgroundSphere.addToScene(threeJSWorldContext.scene);
        }
        if (threeJSWorldContext.background.scene) {
            threeJSWorldContext.background.removeFromScene();
        }

        const image = sceneData['image'];
        const imageUrl = constructUrl(image) + this.getBustKey();
        if (imageUrl) {
            const blurredImageUrl = getBlurredImageUrl(imageUrl);
            this.setState({ imageUrl, blurredImageUrl });
        } else {
            console.error(name, 'has no background image!');
        }
    }

    prepareBackgroundScene() {
        const { sceneData } = this.props;
        if (sceneData.isFlatImageScene) {
            this.loadFromFlatScene();
        } else if (sceneData.hasOwnProperty("cube_map_dir")) {
            this.loadFromCubemap();
        } else {
            this.loadFromEqRectangular();
        }
    }

    initAutoRotate(isUserFirstInteraction) {
        const { threeJSWorldContext } = this.props;
        const dragTooltipEncountered = sessionStorage.getItem('dragTooltipEncountered');

        if (dragTooltipEncountered || (this.props.isEntranceAnimationScene && !this.props.isAnimationVideoEnd)) {
            return;
        }

        sessionStorage.setItem('dragTooltipEncountered', true);
        threeJSWorldContext.toggleIdleAnimation(isUserFirstInteraction);
    }

    setSceneLoading() {
        const { transitionAnimation } = this.props.threeJSWorldContext;
       
        if (transitionAnimation) {
            transitionAnimation.subscribeSceneLoad(() => {
                this.setState({ isLoading: false });
            });
        } else {
            this.setState({ isLoading: false });
        }
    }

    onBackgroundTextureLoaded() {
        const { sceneData, onFirstFullResImageLoaded, setLoadingIconVisibility, threeJSWorldContext } = this.props;

        // threeJSWorldContext.connectController();

        this.initAutoRotate(!sessionStorage.getItem('dragTooltipEncountered'));

        if (onFirstFullResImageLoaded) {
            onFirstFullResImageLoaded();
        }

        if (setLoadingIconVisibility) {
            setLoadingIconVisibility(false);
        }

        const name = sceneData['name'];
        SceneLoaded(name);

        this.props.requestImmediate3DSceneRender();
        this.setSceneLoading();
    }

    onBlurredLoaded() {
        this.props.requestImmediate3DSceneRender()
    }

    onFullImageLoaded(initRotation = false) {
        this.onBackgroundTextureLoaded()
        this.props.requestImmediate3DSceneRender()
        if (this.props.setLoadingIconVisibility) {
            this.props.setLoadingIconVisibility(false);
        }
        if (initRotation) {
            this.initAutoRotate();
        }
    }

    onLod1Loaded() {
        this.setState({ lod2Ready: true });
        this.onFullImageLoaded()
    }

    // empty function for now, add whatever additional logic that needs to be fired when LOD2 is loaded
    onLod2Loaded() {
    }

    getTiledSceneBackground() {
        const { cubemapUrls } = this.state;
        return (
            <TiledSceneBackground
                roomId={this.props.id}
                threeJSWorldContext={this.props.threeJSWorldContext}
                leftImageUrls={cubemapUrls.leftImageUrls}
                rightImageUrls={cubemapUrls.rightImageUrls}
                topImageUrls={cubemapUrls.topImageUrls}
                bottomImageUrls={cubemapUrls.bottomImageUrls}
                backImageUrls={cubemapUrls.backImageUrls}
                frontImageUrls={cubemapUrls.frontImageUrls}
                onLOD1Loaded={() => this.onLod1Loaded()}
                onLOD2Loaded={() => this.onLod2Loaded()}
                onLod3Loaded={this.initAutoRotate}
                initStoreScene={this.cubemapInitStoreScene ? this.onBackgroundTextureLoaded : () => { }}
                toggleAutoRotate={this.initAutoRotate}
                isUserFirstInteraction={this.getIsUserFirstInteraction()}
                lodDelayPerLevelMS={500}
                readyForLOD2={this.state.lod2Ready}
            />
        );
    }

    getSceneBackground() {
        return (
            <SceneBackground
                roomId={this.props.id}
                imageUrl={this.state.imageUrl}
                blurredImageUrl={this.state.blurredImageUrl}
                threeJSWorldContext={this.props.threeJSWorldContext}
                onBlurredImageSetToBackground={() => this.onBlurredLoaded()}
                onFullSizeImageSetToBackground={() => this.onFullImageLoaded(true)}
                isUserFirstInteraction={this.getIsUserFirstInteraction()}
            />
        );
    }

    getFlatSceneBackground() {
        return (
            <FlatSceneBackground
                backgroundURL={this.state.flatSceneUrl}
                onLoaded={this.onBackgroundTextureLoaded}
                threeJSWorldContext={this.props.threeJSWorldContext}
            />
        );
    }

    getIsUserFirstInteraction() {
        return !sessionStorage.getItem('dragTooltipEncountered');
    }

    getBackground() {
        if (this.state.cubemapUrls) {
            return this.getTiledSceneBackground();
        } else if (this.state.imageUrl) {
            return this.getSceneBackground();
        } else if (this.state.flatSceneUrl) {
            return this.getFlatSceneBackground();
        }
    }

    getRoomObjects() {
        if (this.state.isLoading || this.props.hideRoomObjects) {
            return <React.Fragment />
        }
        return this.state.roomObjectRender;
    }

    showEntranceAnimation = () => {
        if (this.props.isEntranceAnimationScene) {
            return <EntranceAnimation roomId={this.props.sceneData.id}/>
        } else {
            return <React.Fragment />
        }
    }
    render() {
        const contextValue = {
            cubemapUrls: this.state.cubemapUrls,
            onBackgroundTextureLoaded: this.onBackgroundTextureLoaded,
            roomId: this.props.id
        };
        return (
            <RoomContext.Provider value={contextValue}>
                {this.showEntranceAnimation()}
                {this.getBackground()}
                {this.getRoomObjects()}
            </RoomContext.Provider>
        );
    }
}

Room.propTypes = {
    id: PropTypes.string.isRequired,
    sceneData: PropTypes.object.isRequired,
    hideRoomObjects: PropTypes.bool,
    loadBlurredImage: PropTypes.bool,
    threeJSWorldContext: PropTypes.object,
    onFirstFullResImageLoaded: PropTypes.func,
    setLoadingIconVisibility: PropTypes.func,
    requestImmediate3DSceneRender: PropTypes.func,
    scenesInfo: PropTypes.object.isRequired,
};

export default props => (
    <AppContext.Consumer>
        {appContextValue => (
            <ThreeJSWorldContext.Consumer>
                {threeJSWorldContextValue => (
                    <FlatImageSceneContext.Consumer>
                        {flatImageSceneContextValue => (
                            <EntranceAnimationContext.Consumer>
                                {entAniContextValue => (
                                 <Room
                                    {...props}
                                    threeJSWorldContext={threeJSWorldContextValue}
                                    onFirstFullResImageLoaded={appContextValue.onFirstFullResImageLoaded}
                                    setLoadingIconVisibility={appContextValue.setLoadingIconVisibility}
                                    setCurrentSceneName={appContextValue.setCurrentSceneName}
                                    requestImmediate3DSceneRender={threeJSWorldContextValue.requestImmediate3DSceneRender}
                                    flatImageSceneContextValue={flatImageSceneContextValue}
                                    {...entAniContextValue}
                                />
                                )}
                           </EntranceAnimationContext.Consumer>
                        )}
                    </FlatImageSceneContext.Consumer>
                )}
            </ThreeJSWorldContext.Consumer>
        )}
    </AppContext.Consumer>
);
