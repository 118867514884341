import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobileDevice } from '../../../utils/DeviceDetector';

const propTypes = {
    fontSize: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonHoverColor: PropTypes.string,
    text: PropTypes.string,
    textColor: PropTypes.string,
    textHoverColor: PropTypes.string,
    fontFamily: PropTypes.string,
    onClick: PropTypes.func
}

const defaultButtonColor = 'black';
const defaultButtonOverColor = 'black';
const defaultTextColor = 'white';
const defaultTextHoverColor = 'white';

const isMobile = isMobileDevice();

class PromoButton extends Component {
    constructor(props) {
        super(props);

        this.buttonClicked = this.buttonClicked.bind(this);
        this.onTouch = this.onTouch.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.style = {
            backgroundColor: defaultButtonColor,
            border: 'none',
            color: defaultTextColor,
            padding: '13px 32px',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-block',
            fontSize: '16px',
            width: '12em',
            fontFamily: 'OpenSans-Bold, Helvetica, sans-serif',
            overflow: 'hidden'
        };

        if (props.fontSize) {
            this.style.fontSize = props.fontSize;
        }
        if (props.fontFamily) {
            this.style.fontFamily = props.fontFamily;
        }
        this.buttonColor = props.buttonColor ? props.buttonColor : defaultButtonColor;
        this.buttonHoverColor = props.buttonHoverColor ? props.buttonHoverColor : defaultButtonOverColor;
        this.style.backgroundColor = this.buttonColor;

        this.textColor = props.textColor ? props.textColor : defaultTextColor;
        this.textHoverColor = props.textHoverColor ? props.textHoverColor : defaultTextHoverColor;
        this.style.color = this.textColor;
    }

    buttonClicked() {
        if (isMobile) {
            return;
        }
        this.props.onClick();
    }

    onTouch() {
        if (!isMobile) {
            return;
        }
        this.props.onClick();
    }

    onMouseOver() {
        this.refs.buyButton.style.backgroundColor = this.buttonHoverColor;
        this.refs.buyButton.style.color = this.textHoverColor;
    }

    onMouseLeave() {
        this.refs.buyButton.style.backgroundColor = this.buttonColor;
        this.refs.buyButton.style.color = this.textColor;
    }

    render() {
        return (
            <div>
                <button
                    ref={'buyButton'}
                    className={'hoverable obsessvr-regularFont'}
                    style={this.style}
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.buttonClicked}
                    onTouchEnd={this.onTouch}
                >
                    {this.props.text}
                </button>
            </div>
        );
    }
}

PromoButton.propTypes = propTypes;

export default PromoButton;
