import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

import './ModalOverlay.css';
import { ModalContext } from './ModalContext.jsx';

export default class ModalOverlay extends Component {
    constructor(props) {
        super(props);

        this.setTransitionStyles = this.setTransitionStyles.bind(this);

        this.setTransitionStyles();
    }

    setTransitionStyles() {
        this.commonStyle = { transition: 'all 0.5s' };

        const invisibleStyle = { backgroundColor: 'rgba(0,0,0,0)' };
        const visibleStyle = { backgroundColor: 'rgba(0,0,0,0.6)' };
        this.transitionStyles = { entering: invisibleStyle, entered: visibleStyle, exiting: visibleStyle, exited: invisibleStyle };
    };

    render() {
        const overlay = (
            <ModalContext.Consumer>
                {value => (
                    <Transition in={value.modalActive} appear={true} timeout={1} >
                        {state => (
                            <div style={{ ...this.commonStyle, ...this.transitionStyles[state] }} className="modalOverlay" />
                        )}
                    </Transition>
                )}
            </ModalContext.Consumer>
        );

        return ReactDOM.createPortal(overlay, document.getElementById('obsessvr-webstore-react-embed-root'));
    }
}