// Global site tag(gtag.js) - Google Analytics
// <script async src="https://www.googletagmanager.com/gtag/js?id=UA-91780857-16"></script>
// <script>
//     window.dataLayer = window.dataLayer || [];
//     function gtag() {dataLayer.push(arguments); }
//     gtag('js', new Date());

//     gtag('config', 'UA-91780857-16');
// </script>

import { getGAIdAsync } from './StoreConfigManager.js';
import { sendMissedAnalyticsOnGALoaded } from './Analytics.js';

let storeName = '';

export function setGAHelperStoreName(name) {
    storeName = name;
}

export function loadGAAsync() {
    getGAIdAsync()
    .then(id => {
        const gaSrc = 'https://www.googletagmanager.com/gtag/js?id=' + id;
        const gaScript = document.createElement('script');
        gaScript.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            window.gtag = gtag;
            // This line tracks the page view and activates ga so we need to call it here
            // Because we call it here, we don't need to do retries in Analytics.js for virtual page visits.
            // gtag('config', id, { 'page_path': storeName + '/' });
            console.log('GA loaded!');

            sendMissedAnalyticsOnGALoaded(id);
        }
        gaScript.src = gaSrc;
        document.head.appendChild(gaScript);
    })
    .catch(error => console.error(error));
}
