import React from 'react';
import './UserPanel.css';

const UserPanel = ({ visible, userCount }) => {
    const pluralString = `There are ${userCount} people in this store right now!`;
    const singularString = "There is 1 person in this store right now!";
    const noUsers = "There are no people in this store right now!";

    return (
        <div id='user-panel-container' style={{ visibility: visible ? 'visible' : 'hidden' }}>
            <div id='user-panel-center'>
                <span id='user-panel-text'>
                    {userCount !== 1 ? pluralString : (userCount === 0 ? noUsers : singularString)}
                </span>
            </div>
        </div>
    )
}

export default UserPanel;