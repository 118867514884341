import React from 'react';
import FlyUpModal from '../FlyUpModal';
import ModalOverlay from '../ModalOverlay';
import BaseImage from '../../image-components/BaseImage';
import './AccessibilityModal.css'

const rotate3DScene = "https://cdn.obsess-vr.com/ada/nav1.svg";
const navigationArrow =  "https://cdn.obsess-vr.com/ada/nav5.svg";
const sectionSelector =  "https://cdn.obsess-vr.com/ada/nav4.svg";

const AccessibilityModal = ({ onAccessibilityModalHidden }) => {

    const renderModalContent = () => {

        return (
            <>
                <div className='main-modal-container' >
                    <div className="content-container">
                        <div className='scene-rotation-instruction'>
                            <span>To rotate 3D scene</span>
                            <span><BaseImage src={rotate3DScene} /></span>
                        </div>
                        <div className='navigation-arrow-instruction'>
                            <span>To select navigation arrows</span>
                            <span><BaseImage src={navigationArrow} /></span>
                        </div>
                        <div className='section-selector-instruction'>
                            <span>To select section selector scenes</span>
                          <span><BaseImage src={sectionSelector} /></span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <FlyUpModal extraClassName="accessibility-modal-container" onModalHidden={onAccessibilityModalHidden} isOpaque
            textPopup hideCloseIcon isImageLoaded>
            <ModalOverlay />
            {renderModalContent()}
        </FlyUpModal>
    );
}

export default AccessibilityModal;


