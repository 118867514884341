import React from 'react';
import PropTypes from 'prop-types';
import Hotspot from './Hotspot';


class HotspotPlus extends Hotspot {
    constructor(props) {
        super(props);
    }

    getPlainSVG(color) {
        return (<svg id="arrow" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     width="512px" height="512px" viewBox="0 0 512 512"
                     enableBackground="new 0 0 256 256">
            <circle fill={color} strokeMiterlimit="10" cx="256" cy="256" r="256"/>
            <rect x="218" y="83" fill="#FFFFFF" stroke="#000000" strokeMiterlimit="10" width="75"
                  height="350"/>
            <rect x="83" y="218" fill="#FFFFFF" stroke="#FFFFFF" strokeMiterlimit="10" width="350"
                  height="75"/>
        </svg>);
    }

}

HotspotPlus.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    property: PropTypes.object,
    setProperty: PropTypes.func
};
export default HotspotPlus;

