import React, { useState, useEffect, useContext } from 'react';
import { TextWithBackground, ModalContext } from 'web-store-modules/packages/web-store-ui-library';
import { ProductModalContext } from '../../ProductModalContext';
import { ContentTypes } from '../util/ProductModalEnums';

import './ProductModalCartOverlay.css'

const ProductModalCartOverlay = ({ isMobile }) => {
    const { productInfo, setModalOpened } = useContext(ProductModalContext);
    const { closeModal } = useContext(ModalContext);

    useEffect(() => {
        const closeTimer = setTimeout(() => {
            closeModal();
        }, 2000);
        return (() => {
            clearTimeout(closeTimer);
        })
    }, []);

    return (
        <div id='product-modal-cart-overlay' className='full-width full-height flex-center'>
            {/* <TextWithBackground textTransformStyle={{ display: 'flex' }} margin={'0.5em'} size={'18px'} className='add-to-cart-prompt-text' text={productInfo[ContentTypes.TITLE]} /> */}
            <TextWithBackground textTransformStyle={{ display: 'flex' }} padding={'0.55em 0.5em'} size={isMobile ? '15px' : '25px'} className='add-to-cart-prompt-text' text='This item has been added to your wishlist' />
            {/* <TextWithBackground className='add-to-cart-prompt-text' text='This item has been added to your wishlist' /> */}
        </div>
    );
}

export default ProductModalCartOverlay;