import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MusicController from './MusicController.jsx';
import Social from './Social';
import { getBackgroundMusicUrlAndStartStatus,getSocialShareDataAsync  } from '../../utils/StoreConfigManager.js';
import SharePanel from './share-panel/SharePanel.jsx';
import Screenshot from './share-panel/Screenshot.jsx';
import SocialButton from './buttons/SocialButton.jsx';
import './BottomBarLeft.css';
import UserPanel from './user-panel/UserPanel.jsx';
import BottomStoreSelector from './store-selector/BottomStoreSelector';

const shareIconUrl = 'https://cdn.obsess-vr.com/instagram-transparent-icon.png';
const userIconUrl = 'https://cdn.obsess-vr.com/user-counter-icon.png';

const BottomBarLeft = ({ showSocial, userPanel, toggleUserPanel, userCount, hideUserPanel, isMobile }) => {
    const [showMusicController, setShowMusicController] = useState(false);
    const [showScreenshot, setShowScreenshot] = useState(false);
    const [showSharePanel, setShowSharePanel] = useState(false);
    const [sharePanelData, setSharePanelData] = useState({});

    const fetchMusicData = async () => {
        const urlAndStatus = await getBackgroundMusicUrlAndStartStatus();
        const url = urlAndStatus.url;        
        if (url) {
            setShowMusicController(true);
        }
    }

    const fetchSharePanelData = async () => {
        const sharePanelData = await getSocialShareDataAsync(); 
        setSharePanelData(sharePanelData)      
    }

    useEffect(() => {
        fetchMusicData();
        fetchSharePanelData();
    }, []);

    const parseNumber = (number) => {
        if (number > 999) {
            const thousands = ((number / Math.pow(10, 3)) + 'k');
            return thousands;
        }
        return number + '';
    }

    const count = parseNumber(userCount);

    const toggleSharePanel = () => {
        setShowSharePanel(!showSharePanel);
    }

    const musicControllerRender = showMusicController && <MusicController newRender={false} />;
    const socialIconRender = showSocial && <Social socialReversed={false} />

    return (
        <div id='bottomBarLeft'>
            <BottomStoreSelector/>
            {/* {musicControllerRender} */}
            <div id="social-button-wrapper" style={{bottom: '5em', left: '0.75em', position: 'fixed'}}>
                {socialIconRender}
            </div>
            
            {/* DISABLE NEW ICONS UNTIL NEW DESIGN HAS BEEN APPROVED */}
            {showScreenshot && <Screenshot />}
            {showSharePanel && <SharePanel
                showSharePanel={showSharePanel}
                setShowSharePanel={setShowSharePanel}
                hideUserPanel={hideUserPanel}
                showScreenshot={showScreenshot}
                setShowScreenshot={setShowScreenshot}
                isMobile={isMobile}
                sharePanelData={sharePanelData}
            />}
            <UserPanel visible={userPanel && userCount} userCount={count} />
            <div id="social-button-wrapper">
            {sharePanelData && sharePanelData.enable &&
                <SocialButton
                    onClick={toggleSharePanel}
                    isMobile={isMobile}
                    active={showSharePanel}
                    iconUrl={shareIconUrl}
                    iconText='SHARE'
                />}
                {/* <SocialButton
                    onClick={toggleUserPanel}
                    isMobile={isMobile}
                    iconUrl={userIconUrl}
                    iconText={userCount ? count : null}
                /> */}
                {showMusicController && <MusicController newRender={true}/>}
            </div>
        </div>
    )
}

// BottomBarLeft.propType = {
//     showSocial: PropTypes.bool
// };

export default BottomBarLeft;