import React, { useState, useEffect, useContext, useRef } from 'react';
import { ProductModalImage } from 'web-store-modules/packages/modal-media';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector'

import CarouselIndicator from './CarouselIndicator.jsx';
import ThumbnailCarouselIndicator from './ThumbnailCarouselIndicator';
import { ImageTypes, ContentTypes } from '../../util/ProductModalEnums';
import { ThumbnailClicked, VideoIconClicked } from '../../../../../../../utils/Analytics';
import { ProductModalContext } from '../../../ProductModalContext';
import useLongPress from './useLongPress';
import { scrollToElem } from './SmoothScrollPolyfill.js';
import { getAltText } from '../../../../../../../utils/StaticAssetManager.js';

import './ProductModalCarousel.css';

const swipeThreshold = 200;

const nextArrowUrl = "https://cdn.obsessvr.com/next-thumbnail-arrow.png";
const threeIcon = 'https://cdn.obsessvr.com/3d-circle-icon.png';
const spinIcon = 'https://cdn.obsessvr.com/360-circle-icon.png';
const videoIcon = 'https://cdn.obsessvr.com/video-circle-icon.png';

const isMobile = isMobileDevice();

const ProductModalCarousel = ({ thumbnailArr, sku, setFocusMode, focusMode, priceNum }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startPos, setStartPos] = useState(0);
    const [endPos, setEndPos] = useState(0);
    const [carouselHeight, setCarouselHeight] = useState();
    const [carouselWidth, setCarouselWidth] = useState();
    const [distOffset, setDistOffset] = useState(0);
    const [xTranslation, setXTranslation] = useState(0);
    const [isUserMoving, setIsUserMoving] = useState(false);
    const [isUserInteracting, setIsUserInteracting] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [maxScrollDist, setMaxScrollDist] = useState(0);
    const [thumbnailHeight, setThumbnailHeight] = useState(0);
    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
    const [exitingFocusMode, setExitingFocusMode] = useState(false);

    const { productInfo, modalOpened } = useContext(ProductModalContext);

    const carouselContainer = useRef();
    const indicatorContainer = useRef();

    const [onArrowUpPress, onArrowUpRelease] = useLongPress(() => {
        if (scrollY > 0) {
            setScrollY(indicatorContainer.current.scrollTop - 1);
        }
    }, 20);

    const [onArrowDownPress, onArrowDownRelease] = useLongPress(() => {
        if (scrollY <= maxScrollDist) {
            setScrollY(indicatorContainer.current.scrollTop + 1);
        }
    }, 20);

    const onPlayVideo = () => {
        VideoIconClicked(productInfo[ContentTypes.SKU], productInfo[ContentTypes.TITLE]);
    }

    const renderThumbnails = () => {
        return thumbnailArr.map((thumbnail, index) => {
            const thumbnailFileName = thumbnail?.thumbnailUrl ? getAltText(thumbnail.thumbnailUrl) : '';
            return <ProductModalImage key={index} index={index} modalOpened={modalOpened} currentIndex={currentIndex} focusMode={focusMode} exitingFocusMode={exitingFocusMode} setFocusMode={setFocusMode} xTranslation={xTranslation} isUserMoving={isUserMoving} setIsUserInteracting={setIsUserInteracting} onPlayVideo={onPlayVideo} {...thumbnail} alt={thumbnailFileName} />
        })
    }

    const renderIndicator = () => {
        // Assigns icons for thumbnail carousel indicator
        const iconAssigner = (type) => {
            if (type === ImageTypes.VIDEO) {
                return videoIcon;
            } else if (type === ImageTypes.THREE) {
                return threeIcon;
            } else if (type === ImageTypes.SPIN) {
                return spinIcon;
            }
            return;
        }
        return thumbnailArr.map((thumbnail, index) => {
            const thumbnailImageFileName = thumbnail?.thumbnailUrl ? getAltText(thumbnail.thumbnailUrl) : '';

            const indicatorClickHandler = () => {
                ThumbnailClicked(productInfo[ContentTypes.SKU], productInfo[ContentTypes.TITLE]);
                setCurrentIndex(index)
            }
            if (!isMobile) {
                return <ThumbnailCarouselIndicator icon={iconAssigner(thumbnail.type)} thumbnail={thumbnail} key={index} index={index} currentIndex={currentIndex} onClick={indicatorClickHandler} last={index === thumbnailArr.length - 1} alt={thumbnailImageFileName} />
            }
            return <CarouselIndicator key={index} index={index} currentIndex={currentIndex} onClick={indicatorClickHandler} />
        })
    }

    const getSign = (num) => Math.sign(num);

    const withinBounds = (index, min, max) => (index < min || index + 1 > max) ? false : true;

    const onMouseDown = (e) => {
        e.preventDefault();
        setStartPos(e.clientX);
        setStartTime(Date.now())
        setIsUserMoving(true);
    }

    const onMouseMove = (e) => {
        e.preventDefault();
        if (isUserMoving && !isUserInteracting) {
            setEndPos(e.clientX);
            setEndTime(Date.now());
        }
    }

    const onMouseUp = (e) => {
        e.preventDefault();
        const dist = startPos - endPos;
        const isUserSwiping = Math.abs(startTime - endTime) < swipeThreshold ? true : false;

        if ((Math.abs(dist) > (carouselWidth * 0.4) || isUserSwiping) && endPos) {
            if (withinBounds(currentIndex + (getSign(dist) * 1), 0, thumbnailArr.length)) {
                setCurrentIndex(currentIndex + (getSign(dist) * 1));
            }
        }

        setStartPos(0);
        setEndPos(0);
        setIsUserMoving(false);
    }

    const onTouchStart = (e) => {
        setStartPos(e.touches[0].pageX);
        setStartTime(Date.now())
        if (!carouselWidth) {
            setCarouselWidth((carouselContainer.current.clientWidth));
        }
    }

    const onTouchMove = (e) => {
        if (!isUserInteracting) {
            setEndPos(e.touches[0].pageX);
            setEndTime(Date.now());

            setIsUserMoving(true);
        }
    }

    const onTouchEnd = () => {
        const dist = startPos - endPos;
        const isUserSwiping = Math.abs(startTime - endTime) < swipeThreshold ? true : false;

        if ((Math.abs(dist) > (carouselWidth * 0.4) || isUserSwiping) && endPos) {
            if (withinBounds(currentIndex + (getSign(dist) * 1), 0, thumbnailArr.length)) {
                setCurrentIndex(currentIndex + (getSign(dist) * 1));
            }
        }

        setStartPos(0);
        setEndPos(0);
        setIsUserMoving(false);
    }

    const onLeftArrowClick = () => {
        if (currentIndex !== 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const onRightArrowClick = () => {
        if (currentIndex !== thumbnailArr.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    }

    useEffect(() => {
        const indicatorItemHeight = indicatorContainer.current ? Math.round(indicatorContainer.current.scrollHeight / thumbnailArr.length) : 0;
        const scrollMax = indicatorContainer.current ? indicatorContainer.current.scrollHeight - indicatorContainer.current.offsetHeight : 0;
        setThumbnailHeight(indicatorItemHeight);
        setMaxScrollDist(scrollMax);
        setCarouselWidth(carouselContainer.current.clientWidth);
        setCarouselHeight(carouselContainer.current.clientHeight);
        indicatorContainer.current && indicatorContainer.current.addEventListener('scroll', (e) => {
            setCurrentScrollPosition(e.target.scrollTop)
        }, { passive: true });
    }, [])

    // Used to resize carousel width when entering focus mode
    useEffect(() => {
        setCarouselWidth(carouselContainer.current.clientWidth);
        if (!focusMode) {
            // used to keep transition between focus mode/normal mode and prevent carousel from scrolling back to last position after resizing width
            setExitingFocusMode(!focusMode);
            setTimeout(() => {
                setExitingFocusMode(false);
            }, 250)
        }
    }, [focusMode])

    // If product variant changes, set index back to 0
    useEffect(() => {
        setCurrentIndex(0);
    }, [sku])

    useEffect(() => {
        const thumbnailScrollDist = thumbnailHeight * currentIndex;
        if (thumbnailScrollDist <= maxScrollDist) {
            // Offset scrolls allows scrolling to be more user friendly by allowing users to see the option above it at all times
            const offsetScroll = (currentIndex - 1) * thumbnailHeight;
            setScrollY(offsetScroll);
        } else if (currentIndex > thumbnailArr.length - 4) {
            setScrollY(thumbnailScrollDist);
        }
        setDistOffset(carouselWidth * currentIndex);
    }, [currentIndex, carouselWidth])

    useEffect(() => {
        if (thumbnailArr.length > 1) {
            setXTranslation(distOffset ? -distOffset : 0);
        }
    }, [distOffset])

    useEffect(() => {
        if ((thumbnailArr.length > 1) || isUserInteracting) {
            setXTranslation(-(distOffset + (startPos - endPos)));
        }
    }, [endPos, isUserInteracting])

    useEffect(() => {
        if (indicatorContainer && indicatorContainer.current !== undefined) {
            indicatorContainer.current && scrollToElem(indicatorContainer.current, scrollY);
        }
    }, [scrollY])

    const imageWrapperStyle = { height: carouselHeight ? carouselHeight : '100%' }

    if (priceNum == undefined) {
        imageWrapperStyle.paddingTop = '0.5em';
        imageWrapperStyle.paddingBottom = '0.5em';
    }
    return (
        <div id={`${isMobile ? 'product-image-carousel' : 'product-image-carousel-desktop'}`} className="flex-1" style={{ paddingLeft: (!isMobile && thumbnailArr.length === 1) || focusMode ? '0%' : '5%' }}>
            {isMobile && !focusMode && <div id="carousel-arrow-container">
                <img id="carousel-left-arrow" style={{ opacity: currentIndex !== 0 ? '1' : '0' }} src={nextArrowUrl} onClick={onLeftArrowClick} />
                <img id="carousel-right-arrow" style={{ opacity: currentIndex !== thumbnailArr.length - 1 ? '1' : '0' }} src={nextArrowUrl} onClick={onRightArrowClick} />
            </div>}
            <div
                className='product-image-wrapper'
                ref={carouselContainer}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                // style={imageWrapperStyle} 
                //^Not sure if we need this. This breaks product popup layout when zoomed in.
            >
                {(thumbnailArr && carouselHeight) ? renderThumbnails() : null}
            </div>
            {!focusMode && thumbnailArr.length > 1 ? <div className={isMobile ? 'carousel-indicator-container-mobile' : 'carousel-indicator-container'} ref={indicatorContainer} >
                {!isMobile && (
                    <div className="thumbnail-carousel-arrow-container" style={{ opacity: currentScrollPosition > 0 ? '1' : '0' }} onMouseDown={onArrowUpPress} onMouseUp={onArrowUpRelease}>
                        <div className="thumbnail-carousel-arrow-up" />
                    </div>
                )}
                {thumbnailArr.length > 1 && renderIndicator()}
                {!isMobile && (
                    <div className="thumbnail-carousel-arrow-container" style={{ opacity: (maxScrollDist - currentScrollPosition !== 0) ? '1' : '0' }} onMouseDown={onArrowDownPress} onMouseUp={onArrowDownRelease}>
                        <div className="thumbnail-carousel-arrow-down" />
                    </div>
                )}
            </div> : <></>}

        </div>
    );
}

export default ProductModalCarousel;
