import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getProductMarkerNormalAsync, getProductMarkerHoverAsync, getVideoMarkerNormalAsync } from '../../utils/StaticAssetManager.js';
import Marker from './Marker.jsx';
import ComposableModal from '../ui/modals/composable/ComposableModal.jsx';
import HotspotCircle from './ReactSVGHotspots/HotspotCircle';
import HotspotPlus from './ReactSVGHotspots/HotspotPlus';
import { getHotspotIconStyle } from '../../utils/StoreConfigManager.js';
import { getIconSVGAsync } from '../ui/ui-layer-panel/SVGIcons.jsx';
import TextModal from '../ui/modals/text/TextModal';
import ImageCarouselModal from '../ui/modals/image-carousel/ImageCarouselModal';
import { ModalTypes } from '../ui/modals/utils/ModalConstants';
import IFrameModal from '../ui/modals/iFrame/IFrameModal.jsx';

export const ModalMarkerTypes = Object.freeze({
    Image: "image",
    Video: "video"
});

export const MarkerImageAssets = Object.freeze({
    VideoMarker: "https://cdn.obsess-vr.com/play-icon.svg",
    ImageMarker: "https://cdn.obsess-vr.com/template-image-hotspot-icon.svg"
});

class ModalMarker extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onModalHidden = this.onModalHidden.bind(this);

        this.state = {
            isShowingModal: false,
            normalSpriteUrl: '',
            hoverSpriteUrl: '',
            svgSprite: null,
            svgSpriteString: ''
        };
        this.mounted = false;

        switch (props.type) {
            case ModalMarkerTypes.Video:
                this.setSVGIcon(MarkerImageAssets.VideoMarker);
                break;
            case ModalMarkerTypes.Image:
                // TODO: import web-store-modules for svgs.
                this.setSVGIcon(MarkerImageAssets.ImageMarker);
                break;
            default:
                getProductMarkerNormalAsync()
                .then(url => this.setState({ normalSpriteUrl: url }))
                .catch(error => console.error(error));
                getProductMarkerHoverAsync()
                .then(url => this.setState({ hoverSpriteUrl: url }))
                .catch(error => console.error(error));
                getHotspotIconStyle()
                .then(icon => {
                    const Sprite = icon == 'blank' ? HotspotCircle : HotspotPlus;
                    this.setState({ svgSprite: Sprite });
                })
                .catch(error => console.error(error));
                break;
        }
    }

    setSVGIcon(markerIconUrl) {
        getIconSVGAsync(null, markerIconUrl)
        .then(svgString => this.setState({ svgSpriteString: svgString }))
        .catch(error => console.error(error));
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onClick() {
        if (!this.mounted) {
            return;
        }
        this.setState({ isShowingModal: true });
    }

    onModalHidden() {
        if (!this.mounted) {
            return;
        }
        this.setState({ isShowingModal: false });
    }

    getTextModal() {
        return (
            <TextModal
                modalContent={this.props.modalContent}
                onModalHidden={this.onModalHidden}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        );
    }
    
    getImageCarouselModal() {
        return (
            <ImageCarouselModal
                modalContent={this.props.modalContent}
                onModalHidden={this.onModalHidden}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        );
    }

    getComposableModal() {
        return (
            <ComposableModal
                type={this.props.type}
                modalContent={this.props.modalContent}
                onModalHidden={this.onModalHidden}
            />
        );
    }

    getIFrameModal() {
        return (
            <IFrameModal
                modalContent={this.props.modalContent}
                onModalHidden={this.onModalHidden}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        );
    }

    renderModal() {
        if (this.state.isShowingModal && this.props.type) {
            switch (this.props.type) {
                case ModalTypes.TextModal:
                    return this.getTextModal();
                case ModalTypes.ImageCarousel:
                    return this.getImageCarouselModal();
                case ModalTypes.IFrameModal:
                    return this.getIFrameModal();
                default:
                    return this.getComposableModal();
            }
        }
        return null;
    }

    render() {
        const { normalSpriteUrl, hoverSpriteUrl, svgSprite, svgSpriteString } = this.state;
        const { fileName } = this.props;
        return (
            <div>
                {this.renderModal()}
                <Marker
                    transform={this.props.transform}
                    colliderTransform={this.props.colliderTransform}
                    normalSpriteUrl={normalSpriteUrl}
                    hoverSpriteUrl={hoverSpriteUrl}
                    svgSprite={svgSprite}
                    svgSpriteString={svgSpriteString}
                    showVisual={this.props.showVisual}
                    onClick={this.onClick}
                    primaryColor={this.props.primaryColor}
                    secondaryColor={this.props.secondaryColor}
                    alt={ fileName ? fileName : ''}
                />
            </div>
        );
    }
}

ModalMarker.propTypes = {
    transform: PropTypes.array.isRequired,
    colliderTransform: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    modalContent: PropTypes.object.isRequired,
    showVisual: PropTypes.bool,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string
};

export default ModalMarker;
