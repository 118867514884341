import React, { Component } from 'react';
import Proptypes from 'prop-types';

import { FacebookButtonAnalytics } from '../../../utils/Analytics.js';
import SocialButton from './SocialButton.jsx';

const url = window.location.href;
const facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + url;

class FacebookButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null
        };
        this.url = props.action === 'profile' ? this.props.profileUrl : facebookShareUrl;

        // getFacebookAsync()
        // .then(url => this.setState({ imageUrl: url }))
        // .catch(error => console.error(error));
    }

    render() {
        const color = this.props.color;
        // const imageSrc = this.state.imageSrc;
        return (
            <SocialButton
                // imageSrc='https://cdn.obsess-vr.com/facebook.svg'
                imageSrc='https://s3.amazonaws.com/obsessvr-webstore-assets-public/facebook.svg'
                style={this.props.style}
                redirectUrl={this.url}
                color={color}
                onClickAnalytics={FacebookButtonAnalytics}
            />
        );
    }
}

FacebookButton.propTypes = {
    style: Proptypes.object
};

export default FacebookButton;
