import * as THREE from 'three';
import ChromaKeyVert from './ChromaKey.vert';
import ChromaKeyFrag from './ChromaKey.frag';

export default function getChromaKeyMaterial(texture, color) {
    const material = new THREE.ShaderMaterial({
        uniforms: {
            texture: { type: 't', value: texture },
            color: { type: "c", value: color }
        },
        transparent: true,
        vertexShader: ChromaKeyVert,
        fragmentShader: ChromaKeyFrag
    });
    return material;
}
