import React, { Component } from 'react';

import './PromoPage.css';
import { getTitleColor, getButtonTextColor, getButtonBackgroundColor, getTitleFont, getButtonTextFont, getTitleFontSize, getTitleFontSizeMobile, getButtonTextFontSize, getTitle, getButtonText } from '../../../utils/EmbedVariable.js';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import PromoButton from './PromoButton.jsx';

const isMobile = isMobileDevice();

const titleColor = getTitleColor();
const buttonTextColor = getButtonTextColor();
const buttonBackgroundColor = getButtonBackgroundColor();
const titleFont = getTitleFont();
const buttonTextFont = getButtonTextFont();
const titleFontSize = getTitleFontSize();
const titleFontSizeMobile = getTitleFontSizeMobile();
const buttonTextFontSize = getButtonTextFontSize();

const titleStyle = {
    color: titleColor,
    fontFamily: titleFont,
    fontSize: isMobile ? titleFontSizeMobile : titleFontSize
}

class PromoPage extends Component {
    constructor(props) {
        super(props);
        this.goToVirtualStore = this.goToVirtualStore.bind(this);
        this.onButtonClicked = this.onButtonClicked.bind(this);

        this.state = {
            title: getTitle(),
            buttonText: getButtonText()
        };
    }

    componentDidMount() {
        const rootNode = document.getElementById('obsessvr-webstore-react-embed-root');
        if (isMobile) {
            rootNode.addEventListener('touchend', this.goToVirtualStore);
        }
    }

    componentWillUnmount() {
        const rootNode = document.getElementById('obsessvr-webstore-react-embed-root');
        if (isMobile) {
            rootNode.removeEventListener('touchend', this.goToVirtualStore);
        }
    }

    goToVirtualStore() {
        this.props.onWelcomeHide();
    }

    onButtonClicked() {
        this.goToVirtualStore();
    }

    render() {
        const { title, buttonText } = this.state;
        let titleRender = (<React.Fragment />);
        if (title) {
            titleRender = (
                <p
                    className='ObsessPromoPageTitleText'
                    style={titleStyle}
                >
                    {title}
                </p>
            );
        }
        let buttonRender = (<React.Fragment />);
        if (buttonText) {
            buttonRender = (
                <div style={{ marginTop: '0.3em' }}>
                    <PromoButton
                        text={buttonText}
                        textColor={buttonTextColor}
                        textHoverColor={buttonTextColor}
                        buttonColor={buttonBackgroundColor}
                        buttonHoverColor={buttonBackgroundColor}
                        fontSize={buttonTextFontSize}
                        fontFamily={buttonTextFont}
                        onClick={this.onButtonClicked}
                    />
                </div>
            );
        }
        return (
            <div id='ObsessPromoPageContainerOuter' className={'hoverable'}>
                <div id='ObsessPromoPageContainerInner'>
                    {titleRender}
                    {buttonRender}
                </div>
            </div>
        );
    }
}

export default PromoPage;
