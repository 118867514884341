import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ContentTypes } from '../../util/ProductModalEnums';
import ColorDropdownSelectorItem from './ColorDropdownSelectorItem';
import './ColorDropdownSelector.css';
import { ProductModalContext } from '../../../ProductModalContext';
import {  ColorSelectorOptionClicked } from '../../../../../../../utils/Analytics';

const ColorDropdownSelector = ({ defaultColor, fontStyle, groupColors }) => {
    const { variantColor, setVariantColor, setSelectedVariantSKU, selectedVariantSKU, productVariantMap, baseSKU, productSKU } = useContext(ProductModalContext)
    const [selectorOpen, setSelectorOpen] = useState(false);

    useEffect(() => {
        setVariantColor(defaultColor);
        setSelectedVariantSKU(productVariantMap.features_map[defaultColor]);
        return () => { setSelectedVariantSKU(baseSKU) };
    }, [])

    const toggleSelector = () => setSelectorOpen(!selectorOpen);

    const handleOnClick = (color) => {
        setVariantColor(color);
        ColorSelectorOptionClicked(productSKU, color )
        setSelectedVariantSKU(productVariantMap.features_map[color]);
        toggleSelector();
    };

    return (
        <div id="color-selector-column" className="flex-center" style={fontStyle}>
            <ColorDropdownSelectorItem text={defaultColor} sku={selectedVariantSKU} onClick={groupColors.length > 1 ? toggleSelector : () => { return; }} showArrow={groupColors.length > 1 && true} arrowDown={!selectorOpen} oneItem={groupColors.length <= 1} />

            <div id="color-selector-items" className="flex-center">
                {groupColors.length > 1 && <ColorDropdownSelectorItem text={variantColor} sku={selectedVariantSKU} relative={true} onClick={toggleSelector} showArrow={groupColors.length > 1 && true} arrowDown={!selectorOpen} />}
                {selectorOpen && groupColors && groupColors.length > 1 && groupColors.map((color, index) => (
                    <ColorDropdownSelectorItem
                        key={index}
                        relative={true}
                        onClick={() => handleOnClick(color)}
                        text={color}
                    />
                ))}
            </div>
        </div>
    );
}

ColorDropdownSelector.propTypes = {
    groupProducts: PropTypes.array.isRequired,
    fontStyle: PropTypes.object,
    size: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    fetchProductInfo: PropTypes.func.isRequired
};

export default ColorDropdownSelector;
