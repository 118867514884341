import React, { Component, useRef } from 'react';
import '../StoreSelector.css';
import './NewStoreSelector.css';
import { setCursorStyle } from '../../../../utils/MouseUtilities.js';
import Segment from 'semantic-ui-react/dist/es/elements/Segment';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/image.min.css';

const hoverCursorStyle = 'pointer';
const unhoverCursorStyle = 'all-scroll';

import { StoreSelectorOption } from '../../../../utils/Analytics.js';
import SVGImage from '../../image-components/SVGImage';
import { getAllSceneInfoDictAsync } from '../../../../utils/StoreConfigManager';
import { AppContext } from '../../../../AppContext.jsx';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import MenuItem from './MenuItem';

const linkedRandomScenes = () => {
    return [
        {
            name: 'Entrance',
            scene_id: '5f7f9387018459df66ca47ea'
        },
        {
            name: 'Terrace',
            scene_id: '5f74fa8bf9ea02eb4c350a65'
        },
        {
            name: 'Living Room',
            scene_id: '5f7b94228b1d14ab9ebf01f1'
        },
        {
            name: 'Bedroom',
            scene_id: '5f74fee562108b77cfe300a4'
        },
        {
            name: 'Dressing Room',
            scene_id: '5f8a11b62744fbe58e679f52'
        },
        {
            name: 'Entrance',
            scene_id: '5f7f9387018459df66ca47ea'
        },
        {
            name: 'Terrace',
            scene_id: '5f74fa8bf9ea02eb4c350a65'
        },
        {
            name: 'Living Room',
            scene_id: '5f7b94228b1d14ab9ebf01f1'
        }
    ]
}
class CurrentStoreMenuItem extends Component {
    constructor(props) {
        super(props);

        this.onHover = this.onHover.bind(this);
        this.onUnhover = this.onUnhover.bind(this);
        this.onClick = this.onClick.bind(this);

        this.state = {
            jumpToFont: 'https://s3.amazonaws.com/obsessvr-webstore-assets-public/Caslon-BookItalic.otf',
            placeholderIconSrc: 'https://s3.amazonaws.com/obsessvr-webstore-assets-public/placeholder.svg',
            currentStoreMenuItemColor: '#7d7e7e',
        }

    }

    onHover() {
        setCursorStyle(hoverCursorStyle);
    }

    onUnhover() {
        setCursorStyle(unhoverCursorStyle);
    }

    onClick(sceneId) {
        const { threeJSWorldContext, setLoadingIconVisibility, closeMenu } = this.props;
        setLoadingIconVisibility(true);
        closeMenu();
        getAllSceneInfoDictAsync()
            .then(sceneInfoDict => {
                if (!sceneInfoDict[sceneId]) {
                    throw new Error('Scene with id ' + sceneId + ' do not exist!');
                }

                const linkedSceneName = sceneInfoDict[sceneId]['name'];
                let targetPath = '/' + linkedSceneName;
                StoreSelectorOption(linkedSceneName);

                if (threeJSWorldContext.history.location.pathname !== targetPath) {
                    threeJSWorldContext.pushHistoryWithReset(targetPath)
                } else {
                    this.props.setLoadingIconVisibility(false);
                }
            })
    }

    render() {
        const { currentStoreMenuItemColor, placeholderIconSrc } = this.state;
        const { menuTextFontStyle, store, storeSelectorLen } = this.props;

        let style = Object.assign({}, menuTextFontStyle);
        let sceneStyle = Object.assign({}, menuTextFontStyle);

        style.color = currentStoreMenuItemColor;
        const routeRenders = store.linkedScenes.map((scene, index) => (
            <React.Fragment key={scene.scene_id}>
                {/* <div
                    style={{ marginBottom: '0.3em' }}
                    onMouseOver={this.onHover}
                    onMouseOut={this.onUnhover}
                    onClick={() => this.onClick(scene.scene_id)}
                >
                    <span className="linked-store-scene" style={sceneStyle}> {scene.name} </span>
                </div> */}
                <MenuItem
                    handleMouseOver={this.onHover}
                    handleMouseOut={this.onUnhover}
                    handleClick={() => this.onClick(scene.scene_id)}
                    menuStyle={sceneStyle}
                    scene={scene}
                    index={index}
                    storeSelectorLen={storeSelectorLen}
                />
                { index !== store.linkedScenes.length - 1 && (
                    <hr className="linked-store-scene-border" />
                )}
            </React.Fragment>
        ));

        /* const routeRendersRandom = linkedRandomScenes().map((scene, index) => (
            <React.Fragment key={scene.scene_id}>
                <hr className="linked-store-scene-border" />
                <div
                    style={{ marginBottom: '0.3em' }}
                    onMouseOver={this.onHover}
                    onMouseOut={this.onUnhover}
                    onClick={() => this.onClick(scene.scene_id)}
                >
                    <span className="linked-store-scene" style={sceneStyle}> {scene.name} </span>
                </div>
            </React.Fragment>
        )); */

        return (
            <Segment className="overflow-auto">
                <div className="text-align-left float-left">
                    {routeRenders}
                </div>
                <div
                    style={{ float: 'right', marginTop: '-0.5em' }}
                >
                </div>
            </Segment>
        );
    }
}

CurrentStoreMenuItem.propTypes = {
    closeMenu: PropTypes.func,
    menuTextFontStyle: PropTypes.object,
    store: PropTypes.object,
    setLoadingIconVisibility: PropTypes.func
};

export default props => (
    <AppContext.Consumer>
        {appContextValue => (
            <ThreeJSWorldContext.Consumer>
                {threeJSWorldContext => (
                    <CurrentStoreMenuItem
                        {...props}
                        threeJSWorldContext={threeJSWorldContext}
                        setLoadingIconVisibility={appContextValue.setLoadingIconVisibility}
                    />
                )}
            </ThreeJSWorldContext.Consumer>
        )}
    </AppContext.Consumer>
);
