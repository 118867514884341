import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Transition from 'semantic-ui-react/dist/es/modules/Transition';
import BaseImage from '../image-components/BaseImage';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/transition.min.css';

import './BaseUILayerPanel.css';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';

const closeIconNormalSrc = 'https://cdn.obsess-vr.com/close-icon.png';
const isMobile = isMobileDevice();
const closeIconStyle = {
    width: '1em',
    float: 'right',
    marginTop: '0.4em'
};

class BaseUILayerPanel extends Component {
    constructor(props) {
        super(props);
        this.closePanel = this.closePanel.bind(this);

        this.closeButtonOnClick = null;
        this.closeButtonOnTouchEnd = null;
        if (isMobile) {
            this.closeButtonOnTouchEnd = this.closePanel;
        } else {
            this.closeButtonOnClick = this.closePanel;
        }
    }

    closePanel() {
        this.props.onCloseButtonClicked();
    }

    render() {
        const visible = this.props.visible;
        return (
            <div className='baseUILayerPanelContainer'>
                <Transition
                    visible={visible}
                    animation='fade up'
                    duration={300}
                    onHide={this.props.onPanelClosed}
                >
                    <div className='baseUILayerPanel'>
                        <div
                            className='hoverable baseUILayerCloseIcon'
                            onClick={this.closeButtonOnClick}
                            onTouchEnd={this.closeButtonOnTouchEnd}
                        >
                            {/* <Icon color='grey' fitted name='close' size='large' /> */}
                            <BaseImage src={closeIconNormalSrc} style={closeIconStyle} />
                        </div>
                        {this.props.children}
                    </div>
                </Transition>
            </div>
        );
    }
}

BaseUILayerPanel.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCloseButtonClicked: PropTypes.func.isRequired,
    onPanelClosed: PropTypes.func,
};

export default BaseUILayerPanel;
