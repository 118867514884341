import React, { useState, useEffect } from 'react';
import { getMobileOS } from 'web-store-modules/packages/obsess-device-detector';
import './SharePanelSocialIcon.css'
import { isMobileDevice } from '../../../utils/DeviceDetector';
const isMobile = isMobileDevice();

const SharePanelSocialIcon = ({ redirectUrl, name, textAbove, targetSelf, iconSrc, primaryColor, showScreenshot, setShowScreenshot }) => {
    const [showTextAbove, setShowTextAbove] = useState(false);

    useEffect(() => {
        if (showTextAbove) {
            setTimeout(() => { setShowTextAbove(false) }, 2600);
        }
    }, [showTextAbove])

    const shareClicked = () => {
        if (textAbove) {
            setShowTextAbove(true)
        }

        if (['Mail', 'Messages'].includes(name)) {
            window.location.href = redirectUrl;
        } else if (name === 'CopyLink') {
            // Copy the link in textField
            const textField = document.createElement('textarea');
            textField.innerText = redirectUrl;
            document.body.appendChild(textField);
            textField.readOnly = true;  // Prevent iOS chrome from opening up keyboard

            const range = document.createRange();
            range.selectNode(textField);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            document.execCommand('copy');
            selection.removeAllRanges();
            textField.remove();
        } else if (name === 'Instagram') {
            setShowScreenshot(!showScreenshot)
        } else {
            window.open(redirectUrl, targetSelf ? '_self' : '_blank');
        }
    }

    return (
        <div onClick={shareClicked}>
            {
                textAbove && <span id='_event' className='share-text-above' style={{ opacity: showTextAbove ? 1 : 0 }}>{textAbove}</span>
            }
            {
                name === 'Instagram' ?
                    <div id='_event' className='instagram-wrapper icon-bg'>
                        <img id='_event' style={{ width: '2em', filter: 'invert(1)' }} src={iconSrc} />
                    </div>
                    :
                    name === 'SMS' && !isMobile ? 
                    ''
                    :
                    <div id='_event' className='icon-bg'>
                        <img id='_event' style={{ width: '3em', margin: '0 0.3em', filter: 'invert(1)' }} src={iconSrc} />
                    </div>
            }
        </div>
    )
}

export default SharePanelSocialIcon