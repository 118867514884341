import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BaseUILayerPanel from './BaseUILayerPanel.jsx';
import './TipPanel.css';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import { getDesktopTips, getMobileTips } from '../../../utils/StoreConfigManager.js';
import SVGImage from '../image-components/SVGImage.jsx';
import PoweredByObsess from './PoweredByObsess';
import Disclaimer from './Disclaimer';

const tipIconAtlasSrc = 'https://cdn.obsess-vr.com/tip-icon-atlas.svg';
const iconIds = [
    'welcome-darg-icon',
    'welcome-nav-icon',
    'hand-icon'
];
const iconColors = [
    'white',
    'black',
    'white'
];
const isMobile = isMobileDevice();
const defaultDragTip = 'Drag the image around to see 360˚ view.';
const defaultNavTip = isMobile ? 'Tap on arrows to move through the space.' : 'Click on arrows to move through the space.';
const defaultHandTip = isMobile ? 'Tap on hotspots to shop products.' : 'Click on hotspots to shop products.';

class TipPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragIconSrc: '',
            navIconSrc: '',
            handIconSrc: '',
            dragTip: defaultDragTip,
            navTip: defaultNavTip,
            handTip: defaultHandTip
        };
    }

    componentDidMount() {
        const fetchTipFunc = isMobile ? getMobileTips : getDesktopTips;
        fetchTipFunc()
            .then(tips => {
                const { imageTip, arrowTip, hotspotTip } = tips;
                this.setState({
                    dragTip: imageTip ? imageTip : defaultDragTip,
                    navTip: arrowTip ? arrowTip : defaultNavTip,
                    handTip: hotspotTip ? hotspotTip : defaultHandTip
                });
            })
            .catch(error => console.error(error));
    }

    render() {
        const { visible, onCloseButtonClicked, onPanelClosed, showPoweredByObsess, hasDisclaimer, isDisclaimerShowing, disclaimerOnClick, disclaimerOnTouchEnd } = this.props;
        const { dragTip, navTip, handTip } = this.state;

        let poweredByObsess = <div />;
        if (showPoweredByObsess) {
            poweredByObsess = (
                <PoweredByObsess showSeparator={hasDisclaimer} />
            );
        }

        let disclaimerRender = <div />;
        if (hasDisclaimer) {
            disclaimerRender = <Disclaimer
                isDisclaimerShowing={isDisclaimerShowing}
                disclaimerOnClick={disclaimerOnClick}
                disclaimerOnTouchEnd={disclaimerOnTouchEnd}
                showSeparator={false}
            />;
        }

        const tipTexts = [dragTip, navTip, handTip];
        let tipList = [];
        for (let i = 0; i < 3; i++) {
            const tipText = tipTexts[i];
            const color = iconColors[i];
            const iconId = iconIds[i];
            const tip = (
                <div key={i} className='tip'>
                    <div className='tipIcon'>
                        <SVGImage
                            src={tipIconAtlasSrc}
                            svgId={iconId}
                            color={color}
                            width={40}
                            height={40}
                            viewbox='0 0 52 52'
                        />
                    </div>
                    <div className='tipText'>
                        <p>{tipText}</p>
                    </div>
                </div>
            );
            tipList.push(tip);
        }
        const tipRender = tipList.map(tip => {
            return tip;
        });
        return (
            null
            // <BaseUILayerPanel
            //     visible={visible}
            //     onCloseButtonClicked={onCloseButtonClicked}
            //     onPanelClosed={onPanelClosed}
            // >
            //     <div id='tipContainer'>
            //         {tipRender}
            //     </div>
            //     <div id='poweredAndDisclaimer'>
            //         <div id='poweredByObsess'>
            //             {poweredByObsess}
            //         </div>
            //         <div id='poweredByObsess'>
            //             {disclaimerRender}
            //         </div>
            //     </div>
            // </BaseUILayerPanel>
        );
    }
}

TipPanel.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCloseButtonClicked: PropTypes.func.isRequired,
    onPanelClosed: PropTypes.func,
    showPoweredByObsess: PropTypes.bool,
};

export default TipPanel;
