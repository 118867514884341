import React, { useState, useEffect, useContext, useRef } from 'react';

import loadBodyContent from './ProductModalContentWrapper.jsx';
import { Modal } from 'web-store-modules/packages/web-store-ui-library';
import { ProductModalContext } from '../../ProductModalContext.jsx';

import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import { ComponentTypes } from '../util/ProductModalEnums.js';

const { reactRoot } = require('../../../../../../../../variables/Variables.js');

import './ProductModal.css';
import ProductModalCartOverlay from './ProductModalCartOverlay.jsx';

const isMobile = isMobileDevice();

// Arrays that determine the load order of modal content
const headerLoadOrder = [ComponentTypes.TITLE, ComponentTypes.DESCRIPTION];
const bodyLoadOrder = [ComponentTypes.PRICE, ComponentTypes.SALE_PRICE, ComponentTypes.COLOR_SELECTOR, ComponentTypes.PRODUCT_IMAGE, ComponentTypes.PRODUCT_BUTTONS];

const preventDefault = (e) => e.preventDefault();

const ProductModal = ({ onClose, isMall, renderProductContent, footer }) => {
    const { setModalOpened, isLoading, primaryColor, detailsActive, showingAddToCartPrompt, focusMode, contentHeight, setContentHeight } = useContext(ProductModalContext);
    const [hidePinchTip, setHidePinchTip] = useState(!!sessionStorage.getItem('hidePinchTip'));

    const contentContainer = useRef();
    const animationType = isMall ? 'blur' : 'flyup'

    // TODO: Populate styles from product modal context, possibly taken from CMS settings
    const closeIconStyle = { width: '2.8em', float: 'right', backgroundColor: primaryColor, borderRadius: '50%', visibility: focusMode ? 'hidden' : 'visible' };
    const productModalContainerStyle = {
        height: focusMode ? '100vh' : isMobile ? `${document.getElementById(reactRoot).offsetHeight * .85}px` : `${document.getElementById(reactRoot).offsetHeight * .80}px`,
        width: focusMode ? '100vw' : isMobile ? '85vw' : '35em',
        padding: focusMode ? '0' : !footer ? '1em 1em 0' : '1em',
        fontWeight: '400'
        // backgroundColor
    };

    useEffect(() => {
        setContentHeight(contentContainer.current.clientHeight);
        // We use native DOM manipulation here because the react onTouchMove event listener isn't preventing defaults
        if (!hidePinchTip) {
            setTimeout(() => {
                setHidePinchTip(true);
                // If a user doesn't pinch to zoom within two seconds. hide pinch tooltip for the rest of the session, 
                // this is to prevent spamming the user with this tooltip
                if (!sessionStorage.getItem('hidePinchTip')) {
                    sessionStorage.setItem('hidePinchTip', true);
                }
            }, 2000);
            document.addEventListener('touchmove', hideOnPinch, { passive: false });

            // Event Listener is targeting a specific option in addEventListener to disable
            // Mousewheel affects zoom in Safari (Desktop)
            // All event listeners use a shared preventDefault function so that the removeEventListener calls have a specific function to remove
            document.addEventListener('mousewheel', preventDefault, { passive: false });
            // False sets useCapture to false, handler will only be called during bubbling phase
            // Prevents zoom in iOS Safari when Modal is open
            document.addEventListener('touchmove', preventDefault, false);
            // Disables pinch to zoom on Safari w/ macOS trackpad
            document.addEventListener('gesturestart', preventDefault);
            document.addEventListener('gesturechange', preventDefault);
            document.addEventListener('gestureend', preventDefault);

            return function cleanUp() {
                document.removeEventListener('touchmove', hideOnPinch, { passive: false });
                document.removeEventListener('mousewheel', preventDefault, { passive: false });
                document.removeEventListener('touchmove', preventDefault, false);
                document.removeEventListener('gesturestart', preventDefault);
                document.removeEventListener('gesturechange', preventDefault);
                document.removeEventListener('gestureend', preventDefault)
            };
        }
    }, []);

    const hideOnPinch = (e) => {
        if (hidePinchTip && e.touches.length === 2) {
            sessionStorage.setItem('hidePinchTip', true);
            setHidePinchTip(true);
            e.preventDefault();
        } else if (e.touches.length > 1) {
            e.preventDefault();
        }
    }

    return (
        <Modal animationType={animationType} setModalOpened={setModalOpened} onClose={onClose} reactRootId={reactRoot} closeIconStyle={closeIconStyle} isLoading={isLoading} wrapperStyle={{ backgroundColor: focusMode ? 'rgba(0, 0, 0, 1)' : 'rgb(255, 255, 255)' }}>
            {/* {showingAddToCartPrompt && <ProductModalCartOverlay isMobile={isMobile} />} */}
            <div id="product-modal-container" className='flex' style={productModalContainerStyle}>
                <div id="product-modal-header" className="flex" style={{display: focusMode ? 'none' : 'flex'}}>
                    {renderProductContent(headerLoadOrder)}
                </div>
                <div className="flex flex-1" style={{position:"relative"}}>
                    {/* <div ref={contentContainer} className="flex flex-1"/> */}
                    <div id="product-modal-content" ref={contentContainer} className="flex flex-1" style={{ transform: detailsActive ? 'translateY(-100%)' : 'translateY(0)', position: "absolute", width: "100%", maxWidth: "100%", display: "flex", flexFlow: "column" }}>
                        <div id='product-modal-transition-wrapper' className='flex flex-1' style={{ minHeight: `${contentHeight}px`, opacity: detailsActive ? '0' : '1' }}>
                            {renderProductContent(bodyLoadOrder)}
                        </div>
                        {renderProductContent([ComponentTypes.PRODUCT_DETAILS])}
                    </div>
                </div>
                <div id="product-modal-footer" className='align-bottom flex-center flex-no-shrink' style={{ display: focusMode ? 'none' : 'flex' }}>
                    {footer}
                </div>
            </div>
        </Modal >
    );
}

export default loadBodyContent(ProductModal);