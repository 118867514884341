import * as THREE from 'three';
import getChromaKeyMaterial from './chromakey/ChromaKeyMaterial';
import { loadImageAsync } from '../utils/AssetLoader';

export default class VideoPlane {
    constructor(width, height, url, onVideoEnd, imageUrl=null, chromaColor=null, roomId=null, addToRenderLoop = null, videoReadyState) {
        if (typeof (width) !== 'number' || typeof (height) !== 'number') {
            throw new Error('width and height must be numbers!');
        }
        this.addToScene = this.addToScene.bind(this);
        this.removeFromScene = this.removeFromScene.bind(this);
        this.setTransform = this.setTransform.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.setRotation = this.setRotation.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.end = this.end.bind(this);

        this.videoLoadedState = this.videoLoadedState.bind(this)
        this.isPlaying = this.isPlaying.bind(this);
        this.isVisible = this.isVisible.bind(this);

        this.imageUrl = imageUrl;
        this.image = null;
        if (imageUrl) {
            const imageGeometry = new THREE.PlaneGeometry(1, 1);
            const imageMaterial = new THREE.MeshBasicMaterial();
            this.image = new THREE.Mesh(imageGeometry, imageMaterial);
            this.image.visible = false;
            loadImageAsync(
                imageUrl,
                (image) => {
                    this.image.material.map = image;
                    this.image.material.needsUpdate = true;
                    this.image.material.transparent = true;
                    this.image.visible = true;
                },
                null,
                (error) => {
                    console.log("error: " + error + ", loading image with url: " + url);
                },
                roomId
            );
        }

        this.videoReadyState = videoReadyState;
        this.roomId = roomId
        this.onVideoEnd = onVideoEnd;
        this.video = document.createElement('video');
        this.video.src = url;
        this.video.crossOrigin = 'anonymous';
        this.video.setAttribute('webkit-playsinline', '');
        this.video.setAttribute('playsinline', '');
        this.video.currentTime = 1;
        this.video.playsInline = true;
        this.video.muted = true;0
        this.video.load();
        if (this.videoReadyState) {
            this.video.currentTime = 0;
        } else {
            this.video.currentTime = 1;
        }
        this.video.addEventListener('ended', this.end, false);
        if (this.videoReadyState) {
            this.video.addEventListener('loadeddata', this.videoLoadedState, false)
        }

        const texture = new THREE.VideoTexture(this.video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.format = THREE.RGBFormat;

        const geometry = new THREE.PlaneGeometry(width, height);
        const material = chromaColor === null ? new THREE.MeshBasicMaterial({ side: THREE.FrontSide }) : getChromaKeyMaterial(texture, chromaColor);
        material.map = texture;
        material.needsUpdate = true;
        this.plane = new THREE.Mesh(geometry, material);
        this.plane.visible = false;
        this.plane.userData.isVideoPlane = true
        this.addToRenderLoop = addToRenderLoop;
    }

    videoLoadedState() {
        if (this.video && this.video.readyState === 4 && this.videoReadyState) {
            this.plane.visible = true;
            this.plane.userData.shouldBeVisible = true
            this.videoReadyState(true)
        }
    } 

    addToScene(scene) {
        scene.add(this.plane);
        if (this.imageUrl) {
            scene.add(this.image);
        }
    }

    removeFromScene(scene) {
        scene.remove(this.plane);
        if (this.imageUrl) {
            scene.remove(this.image);
            this.image = null;
        }
        this.plane = null;
        this.pause();
        this.video = null;
    }

    setTransform(transform, imageTransform = null) {
        this.plane.matrix = transform;
        this.plane.matrix.decompose(this.plane.position, this.plane.quaternion, this.plane.scale);

        if (this.imageUrl) {
            this.image.matrix = imageTransform;
            this.image.matrix.decompose(this.image.position, this.image.quaternion, this.image.scale);

            // const oldPos = this.image.position;
            // this.image.position.set(oldPos.x, oldPos.y - 0.1, oldPos.z + 0)
            // this.image.rotation.x = 0;
            // this.image.rotation.y = 1.57;
            // this.image.rotation.z = -0.005;
            // this.image.scale.x = 1.7;
            // this.image.scale.y = 2.35;
            // this.image.scale.z = 1;
            // console.log(this.image.matrix);
        }

        if (this.videoReadyState) {
            this.setRotation(0, THREE.MathUtils.degToRad(90), 0);
            if (this.video.src.includes('Dyson_VirtualStore_Entrance_HiRes.mp4')) {
                this.setPosition(-2.73, -0.01, 0);
                this.setScale(0.0043, 0.0042, 1);
            } else if (this.video.src.includes('VirtualStore_intro_Desktop_1920x1080.mp4')) {
                this.setPosition(-2.7, -0.01, 0);
                this.setScale(0.0046, 0.0042, 1);
            } else if (this.video.src.includes('VirtualStore_intro_Mobile_1080x1920.mp4')) {
                this.setPosition(-2.8, -0.01, 0);
                this.setScale(0.005, 0.00425, 0);
            }
            console.log("EntranceAnimation video plane");
        }


        // for tuning video transform
        // const oldPos = this.plane.position;
        // this.plane.visible = true;
        // this.setPosition(oldPos.x + 14.1, oldPos.y - 0.75, oldPos.z + 9.99);
        // this.plane.rotateOnAxis(new THREE.Vector3(0, 1, 0), THREE.Math.degToRad(-90));
        // console.log(this.plane.matrix);
    }

    playActions() {
        this.video.play()
        this.plane.visible = true
        this.plane.userData.shouldBeVisible = true
        if (this.imageUrl) {
            this.image.visible = false
        }
    }

    setPosition(x, y, z) {
        this.plane.position.set(x, y, z);
    }

    setRotation(x, y, z) {
        this.plane.rotation.x = x;
        this.plane.rotation.y = y;
        this.plane.rotation.z = z;
    }

    setScale(x, y, z) {
        this.plane.scale.x = x;
        this.plane.scale.y = y;
        this.plane.scale.z = z;
    }

    play() {
        /*this.video.play();
        this.plane.visible = true;
        if (this.imageUrl) {
            this.image.visible = false;
        }*/
        if (this?.video?.readyState === 4) {
            this.playActions();
        } else {
            this.video.addEventListener("loadeddata", () => {
                this.playActions();
            })
        }

    }

    pause() {
        this.video.pause();
    }

    end() {
        this.plane.visible = false;
        if (this.imageUrl) {
            this.image.visible = true;
        }
        if (this.onVideoEnd) {
            this.onVideoEnd();
        }
    }

    isPlaying() {
        return !this.video.paused;
    }

    isVisible() {
        return this.plane.visible;
    }
}
