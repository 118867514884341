import React, { useState, createContext } from 'react';

const initialState = {
    isEntranceAnimationScene: false,
    isAnimationVideoEnd: false,
    animationVideoPlay: false
};

export const EntranceAnimationContext = createContext(initialState);

const EntranceAnimationContextComponent = ({ children }) => {
    const [isEntranceAnimationScene, setIsEntranceAnimationScene] = useState(false);
    const [isAnimationVideoEnd, setIsAnimationVideoEnd] = useState(false);
    const [isAnimationVideoReady, setIsAnimationVideoReady] = useState(false);
    const [animationVideoPlay, setAnimationVideoPlay] = useState(false);

    const setIsEntranceAnimationSceneWithSceneId = (currentSceneId, EntranceAnimationSceneId) => {
        setIsEntranceAnimationScene(currentSceneId === EntranceAnimationSceneId);
    }

    return (
        <EntranceAnimationContext.Provider value={{
            isEntranceAnimationScene,
            setIsEntranceAnimationScene,
            isAnimationVideoEnd,
            setIsAnimationVideoEnd,
            setIsEntranceAnimationSceneWithSceneId,
            isAnimationVideoReady,
            setIsAnimationVideoReady,
            animationVideoPlay,
            setAnimationVideoPlay
        }}>
            {children}
        </EntranceAnimationContext.Provider>
    )
}

export default EntranceAnimationContextComponent;