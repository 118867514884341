import * as THREE from 'three';

export function getMousePositionInWebGLRenderer(renderer, clientX, clientY) {
    let rect = renderer.domElement.getBoundingClientRect();
    var mousePosition = new THREE.Vector2(0, 0);
    mousePosition.x = ((clientX - rect.left) / rect.width) * 2 - 1;
    mousePosition.y = - ((clientY - rect.top) / rect.height) * 2 + 1;
    
    return mousePosition;
}

export function getMobileTouchPositionInWebGLRenderer(renderer, pageX, pageY) {
    let rect = renderer.domElement.getBoundingClientRect();
    var mousePosition = new THREE.Vector2(0, 0);
    mousePosition.x = ((pageX - rect.left) / rect.width) * 2 - 1;
    mousePosition.y = - ((pageY - rect.top) / rect.height) * 2 + 1;
    // mousePosition.x = (pageX / renderer.domElement.clientWidth) * 2 - 1;
    // mousePosition.y = -(pageY/ renderer.domElement.clientHeight) * 2 + 1;
    return mousePosition;
}

export function setCursorStyle(cursorStyle) {
    document.body.style.cursor = cursorStyle;
}
