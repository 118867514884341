import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Marker from './Marker.jsx';
import { RoomContext } from '../room/RoomContext.jsx';
import { getThreeTransformFromArray } from '../../utils/ThreeTransformConverter';
import { TextClicked } from '../../utils/Analytics.js';

const TextBox = ({ hoveredSpriteUrl, showTextBox, primaryColor, secondaryColor, title, transform }) => {
    const roomContext = useContext(RoomContext);
    const textBoxSize = roomContext.cubemapUrls ? [10, 5.7] : [20, 11.4]
    const transformArray = transform;
    const threeTransform = getThreeTransformFromArray(transformArray);
    // Setting custom box size in transformation matrix to determine size of text box component
    threeTransform.elements[0] = textBoxSize[0];
    threeTransform.elements[5] = textBoxSize[1];
    
    const transform3d = threeTransform.elements;

    if (showTextBox) {
        TextClicked(title);
        return (
            <Marker
                transform={transform3d}
                colliderTransform={transform}
                normalSpriteUrl={hoveredSpriteUrl}
                hoveredSpriteUrl={hoveredSpriteUrl}
                showVisual={showTextBox}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
            />
        );
    }
    else {
        return(
            <React.Fragment />
        );
    }
}

TextBox.propTypes = {
    transform: PropTypes.array,
    colliderTransform: PropTypes.array,
    showVisual: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    hoveredSpriteUrl: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string
};

export default TextBox;
