import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Marker from './Marker.jsx';
import { constructUrl } from '../../utils/UrlConstructor.js';
import { getIconSVGAsync } from '../ui/ui-layer-panel/SVGIcons.jsx';
import { AppContext } from '../../AppContext.jsx';
import { SoundClicked } from '../../utils/Analytics.js';

const iconUrl = "https://cdn.obsess-vr.com/sams-club/sound-hotspot-icon.svg";

class SoundMarker extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);

        this.state = {
            audioUrl: '',
            isPlaying: false,
            svgSprite: '',
        };

        this.player = null;
        this.mounted = false;
    }

    componentDidMount() {
        const { modalContent } = this.props;
        const { url } = modalContent;

        const audioUrl = constructUrl(url);

        this.setState({ audioUrl });
        getIconSVGAsync(null, iconUrl)
            .then(svgString => {
                this.setState({ svgSprite: svgString });
            }).catch(err => {
                console.error(err)
            })

        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onClick() {
        const { modalContent, currentScene } = this.props;
        const { url } = modalContent;
        if (!this.mounted) {
            return;
        }
        const stateToSet = !this.state.isPlaying;

        if (stateToSet) {
            this.player.play();
        } else {
            this.player.pause();
        }

        if (url) {
            const fileName = url.path.substring(url.path.lastIndexOf('/') + 1);
            SoundClicked(currentScene, fileName)
        }
        this.setState({ isPlaying: !this.state.isPlaying });
    }

    render() {
        const { audioUrl, svgSprite } = this.state;

        return (
            <>
                <audio src={audioUrl} ref={(audioRef) => { this.player = audioRef }}></audio>
                <Marker
                    transform={this.props.transform}
                    colliderTransform={this.props.colliderTransform}
                    // svgSprite={svgSprite}
                    svgSpriteString={svgSprite}
                    primaryColor={this.props.primaryColor}
                    secondaryColor={this.props.secondaryColor}
                    showVisual={this.props.showVisual}
                    onClick={this.onClick}
                />
            </>
        );
    }
}

SoundMarker.propTypes = {
    color: PropTypes.string,
    productSKU: PropTypes.string.isRequired,
    transform: PropTypes.array.isRequired,
    colliderTransform: PropTypes.array.isRequired,
    specialUrl: PropTypes.string,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    currentScene: PropTypes.string
};

// HACK: We only need appContext for the current scene
export default props => (
    <AppContext.Consumer>
        {appContext => (
            <SoundMarker currentScene={appContext.currentScene} {...props} />
        )}
    </AppContext.Consumer>
);