/*
    This is where all scenes for this store are fetched, and placed into the Component tree in the form of Router
    Routes, that each lead to Room objects
 */

import React, { Component } from 'react';
import { Route } from "react-router-dom";
import PropTypes from 'prop-types';

import Room from './Room.jsx';
import { getSceneIds, getAllSceneInfoDictAsync } from '../../utils/StoreConfigManager.js';
const path = require('path');

class RoomRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstSceneId: '',
            sceneIdDataDict: null,
        };

        getSceneIds()
            .then(sceneIds => this.setState({ firstSceneId: sceneIds[0] }))
            .catch(error => console.error(error));

        getAllSceneInfoDictAsync()
            .then(sceneInfoDict => {
                this.setSceneIdDataDict(sceneInfoDict);
            })
            .catch(error => console.error(error));
    }

    isFlatImageSceneCheck(pathName) {
        return !!path.extname(pathName);
    }

    setSceneIdDataDict(sceneInfoDict) {
        const sceneData = {}
        for (const sceneInfo in sceneInfoDict) {
            sceneData[sceneInfo] = sceneInfoDict[sceneInfo];

            const flatScenePath = sceneData[sceneInfo].flat_scene_url ? sceneData[sceneInfo].flat_scene_url.path : '';
            const isFlatImageScene = this.isFlatImageSceneCheck(flatScenePath)
            sceneData[sceneInfo].isFlatImageScene = isFlatImageScene;
        }

        this.setState({ sceneIdDataDict: sceneData });
    }

    render() {
        const { firstSceneId, sceneIdDataDict } = this.state;

        if (!firstSceneId || !sceneIdDataDict) {
            return (<div />);
        }

        const routeRenders = [];
        const firstSceneData = sceneIdDataDict[firstSceneId];

        routeRenders.push(<Route
            key={firstSceneId}
            path={'/'}
            exact
            render={props => <Room id={firstSceneId} sceneData={firstSceneData} hideRoomObjects={this.props.hideRoomObjects} loadBlurredImage={true} scenesInfo={sceneIdDataDict} />}
        />);

        for (const [id, data] of Object.entries(sceneIdDataDict)) {
            const name = data['name'];
            let render = <Route
                key={id + '-' + name}
                path={'/' + name}
                exact
                render={props => <Room id={id} sceneData={data} hideRoomObjects={this.props.hideRoomObjects} loadBlurredImage={true} scenesInfo={sceneIdDataDict} />}
            />
            routeRenders.push(render);
        }
        return (
            <React.Fragment>
                {routeRenders}
            </React.Fragment>
        );
    }
}

RoomRoutes.propTypes = {
    hideRoomObjects: PropTypes.bool
};

export default RoomRoutes;
