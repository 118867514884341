import React from 'react';
import PropTypes from 'prop-types';
import Svg from './Svg';

class StairsDownSvg extends Svg {
    constructor(props) {
        super(props);
    }

    getPlainSVG(xrotation, color, opacity) {
        let rotation = "rotate(" + xrotation + " 256 256)";
        return (
            <svg id='stairsdown' version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="512px" height="512px" viewBox="0 0 512 512"
                 enableBackground="new 0 0 256 256">
                <g id="Layer_2">
                    <circle fill="#FFFFFF" opacity={opacity} cx="256" cy="256" r="250"/>
                </g>
                <g transform={rotation} id="Layer_1">
                    <circle fill={color} opacity={opacity} strokeMiterlimit="10" cx="256" cy="256"
                            r="230"/>
                    <g>
                        <path fill="#FFFFFF" opacity={opacity}
                              d="M205.29,366.575c-16.525,0-33.053-0.106-49.577,0.073c-4.254,0.045-6.166-1.197-5.635-5.652 					c0.291-2.445,0.308-4.982,0.01-7.426c-0.551-4.502,1.46-5.643,5.655-5.617c24.127,0.14,48.256,0.078,72.384,0.062 					c6.925-0.004,6.978-0.039,6.985-6.894c0.024-22.806,0.118-45.611-0.081-68.414c-0.038-4.431,1.418-5.675,5.7-5.649 					c25.615,0.155,51.229,0.076,76.845,0.08c6.913,0,6.923,0.006,6.924-6.64c0.003-23.302,0.116-46.604-0.105-69.904 					c-0.042-4.528,1.246-6.157,5.812-6.141c32.556,0.119,65.113,0.096,97.669,0.024c3.898-0.009,5.85,1.113,5.505,5.429 					c-0.328,4.104-0.308,8.275-0.003,12.384c0.327,4.419-1.456,5.685-5.726,5.658c-24.457-0.156-48.916-0.077-73.374-0.083 					c-6.607-0.003-6.615-0.014-6.615,6.543c-0.003,22.971-0.004,45.942,0.003,68.915c0.003,7.723,0.016,7.725-7.975,7.725 					c-24.293,0.002-48.588-0.004-72.881,0.004c-6.672,0.002-6.699,0.035-6.699,6.528c0.004,21.317-0.104,42.64,0.119,63.955 					c0.043,4.271-1.265,5.219-5.362,5.157c-16.522-0.246-33.051-0.103-49.577-0.103C205.29,366.586,205.29,366.58,205.29,366.575z"/>
                        <path fill="#FFFFFF" opacity={opacity}
                              d="M241.203,114.349c-6.577,6.475-12.157,12.048-17.825,17.531c-9.593,9.28-19.198,18.55-28.878,27.742 					c-13.122,12.463-26.329,24.836-39.451,37.301c-0.605,0.575-1.211,1.861-0.957,2.438c0.275,0.626,1.559,1.131,2.402,1.132 					c8.754,0.012,17.508-0.097,26.262-0.155c4.924-0.033,9.851-0.098,14.771,0.02c0.743,0.019,2.064,0.919,2.098,1.473 					c0.205,3.45,0.333,6.94-0.026,10.364c-0.084,0.8-2.143,1.985-3.299,1.991c-21.809,0.119-43.618,0.078-65.427,0.096 					c-2.938,0.002-4.058-1.369-4.049-4.284c0.065-20.652,0.075-41.305,0.006-61.957c-0.011-3.225,1.407-4.537,4.46-4.461 					c0.33,0.008,0.661-0.002,0.991-0.012c9.458-0.255,9.465-0.255,9.472,9.269c0.008,10.243-0.012,20.486,0.02,30.729 					c0.004,1.225,0.246,2.449,0.463,4.46c1.606-1.216,2.678-1.864,3.554-2.712c12.206-11.804,24.375-23.644,36.574-35.454 					c12.076-11.689,24.153-23.378,36.282-35.013c2.96-2.838,6.006-5.62,9.268-8.088c0.93-0.704,3.187-0.816,4.101-0.167 					C235.069,108.764,237.793,111.405,241.203,114.349z"/>
                    </g>
                </g>
            </svg>);
    }




}

StairsDownSvg.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    rotation: PropTypes.number.isRequired
};
export default StairsDownSvg;


