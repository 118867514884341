import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import VideoPlane from '../../three-components/VideoPlane.js';
import { getThreeTransformFromArray } from '../../utils/ThreeTransformConverter.js';
import Marker from './Marker.jsx';
import { InSceneVideoClicked } from '../../utils/Analytics.js';
import { getIconSVGAsync } from '../ui/ui-layer-panel/SVGIcons.jsx';
import { MediaContext } from '../ui/MediaContext.jsx';
import { RoomContext } from '../room/RoomContext';

const videoMarkerIconUrl = 'https://cdn.obsess-vr.com/play-icon.svg';

class SceneVideoMarker extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onVideoEnd = this.onVideoEnd.bind(this);
        this.iconTransformArray = null;
        if (props.iconTransform) {
            this.iconTransformArray = props.iconTransform;
        } else {
            const transformArray = props.transform;
            const center = new THREE.Vector3(0, 0, 0);
            const videoPlanePos = new THREE.Vector3(transformArray[12],
                transformArray[13],
                transformArray[14]);
            const dir = new THREE.Vector3();
            dir.subVectors(center, videoPlanePos).normalize();
            videoPlanePos.add(dir.multiplyScalar(1));
            this.iconTransformArray = transformArray.slice(0);
            this.iconTransformArray[12] = videoPlanePos.x;
            this.iconTransformArray[13] = videoPlanePos.y - 0.2;
            this.iconTransformArray[14] = videoPlanePos.z - 0.65;
        }
        const splitUrl = props.url.split('/');
        const name = splitUrl[splitUrl.length - 1];
        this.state = { isPlaying: false, videoName: name, svgSpriteString: '' };
        this.renderUUID = null;
    }

    componentDidMount() {
        getIconSVGAsync(null, videoMarkerIconUrl).then(svgString => {
            this.setState({ svgSpriteString: svgString });
            const { chromaColor, width, height, url, transform, scene, imageUrl, imageTransform, request3DSceneRender, roomId } = this.props;
            this.videoPlane = new VideoPlane(width,
                height,
                url,
                this.onVideoEnd,
                imageUrl,
                chromaColor,
                roomId);
            this.videoPlaneTransform = getThreeTransformFromArray(transform);
            this.imageTransformMatrix = null;
            if (imageTransform) {
                this.imageTransformMatrix = getThreeTransformFromArray(imageTransform);
            }
            this.videoPlane.setTransform(this.videoPlaneTransform, this.imageTransformMatrix);
            this.videoPlane.video.addEventListener('loadeddata', () => this.videoPlane.addToScene(scene));
            request3DSceneRender();
        }).catch(error => console.error(error));
    }

    componentWillUnmount() {
        const { scene, removeFromRenderLoop, request3DSceneRender } = this.props.threeJSWorldContext;
        this.videoPlane.removeFromScene(scene);
        request3DSceneRender();

        if (this.renderUUID !== null) {
            removeFromRenderLoop(this.renderUUID);
            this.renderUUID = null;
        }
    }

    onClick() {
        const { addToRenderLoop, removeFromRenderLoop } = this.props;
        if (this.videoPlane.isPlaying()) {
            this.videoPlane.pause();
            this.setState({ isPlaying: false });
            removeFromRenderLoop(this.renderUUID);
            this.renderUUID = null;
        } else {
            this.renderUUID = addToRenderLoop();
            this.videoPlane.play();
            InSceneVideoClicked(this.state.videoName);
            this.setState({
                isPlaying: true
            });
        }
    }

    onVideoEnd() {
        this.setState({ isPlaying: false });

        this.props.removeFromRenderLoop(this.renderUUID);
        this.renderUUID = null;
    }

    render() {
        const { primaryColor, secondaryColor } = this.props;
        const { isPlaying, svgSpriteString } = this.state;
        const svg = isPlaying ? '' : svgSpriteString;
        const showVisual = !isPlaying;
        return (
            <Marker transform={this.iconTransformArray}
                colliderTransform={this.props.colliderTransform} showVisual={showVisual}
                onClick={this.onClick} svgSpriteString={svg} primaryColor={primaryColor}
                secondaryColor={secondaryColor} />
        );
    }
}

SceneVideoMarker.propTypes = {
    roomId: PropTypes.string,
    scene: PropTypes.object,
    chromaColor: PropTypes.object,
    iconTransform: PropTypes.array,
    transform: PropTypes.array,
    colliderTransform: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    url: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    imageUrl: PropTypes.string,
    imageTransform: PropTypes.array,
    addToRenderLoop: null,
    removeFromRenderLoop: null,
    request3DSceneRender: null
};
export default props => (
    <ThreeJSWorldContext.Consumer>
        {threeJSWorldContextValue => (
            <MediaContext.Consumer>
                {mediaContextValue => (
                    <RoomContext.Consumer>
                        {roomContextValue => (
                            <SceneVideoMarker
                                {...props} roomId={roomContextValue.roomId}
                                scene={threeJSWorldContextValue.scene}
                                addToRenderLoop={threeJSWorldContextValue.addToRenderLoop}
                                removeFromRenderLoop={threeJSWorldContextValue.removeFromRenderLoop}
                                request3DSceneRender={threeJSWorldContextValue.request3DSceneRender}
                                threeJSWorldContext={threeJSWorldContextValue}
                                mediaContext={mediaContextValue}
                            />)}
                    </RoomContext.Consumer>
                )}
            </MediaContext.Consumer>
        )}
    </ThreeJSWorldContext.Consumer>
);

