export const ContentTypes = Object.freeze({
    SKU: 'SKU',
    BRAND: 'BRAND',
    TITLE: 'TITLE',
    DESCRIPTION: 'DESCRIPTION',
    DETAILS_BODY_1: 'DETAILS_BODY_1',
    DETAILS_BODY_2: 'DETAILS_BODY_2',
    COLOR: 'COLOR',
    PRICE: 'PRICE',
    PRICE_CURRENCY: 'PRICE_CURRENCY',
    PRICE_SUFFIX: 'PRICE_SUFFIX',
    PRICE_PREFIX: 'PRICE_PREFIX',
    SALE_PRICE: 'SALE_PRICE',
    SIZE: 'SIZE',
    URL: 'URL',
    URL_NAME: 'URL_NAME',
    ADD_TO_CART_URL: 'ADD_TO_CART_URL',
    THUMBNAIL: 'THUMBNAIL',
    DOWNSIZED_THUMBNAIL: 'DOWNSIZED_THUMBNAIL',
    SPIN_IMAGES: 'SPIN_IMAGES',
    DOWNSIZED_SPIN_IMAGES: 'DOWNSIZED_SPIN_IMAGES',
    CAROUSEL_IMAGES: 'CAROUSEL_IMAGES',
    DOWNSIZED_CAROUSEL_IMAGES: 'DOWNSIZED_CAROUSEL_IMAGES',
    GROUP: 'GROUP',
    COLOR_IMAGE_FILE: 'COLOR_IMAGE_FILE',
    SOLD_OUT: 'SOLD_OUT',
    SOLD_OUT_TEXT: 'SOLD_OUT_TEXT',
    CUSTOM_FIELD: 'CUSTOM_FIELD',
    VIDEO: 'VIDEO',
    GLTF_FILE: 'GLTF_FILE',
    USDZ_FILE: 'USDZ_FILE'
});

export const ImageTypes = Object.freeze({
    STATIC: 'STATIC',
    SPIN: 'SPIN',
    ZOOM: 'ZOOM',
    VIDEO: 'VIDEO',
    THREE: 'THREE'
});

export const  ImageViewAngles = Object.freeze({
    LOW: 'low',
    MID: 'mid',
    HIGH: 'high'
});

export const ComponentTypes = Object.freeze({
    BRAND: 'brand',
    TITLE: 'title',
    COLOR: 'color',
    DESCRIPTION: 'description',
    PRICE: 'price',
    SALE_PRICE: 'salePrice',
    COLOR_SELECTOR: 'colorSelector',
    PRODUCT_IMAGE: 'productImage',
    PRODUCT_BUTTONS: 'productButtons',
    PRODUCT_DETAILS: 'productDetails',
})