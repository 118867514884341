import React, { Component } from 'react';

class StoreSelectorTip extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.showTip) {
            return(<div/>);
        }
        return(
            <div id='selectorTip'>
                <label style={this.props.menuTextFontStyle} id='selectorTipLabel'>{this.props.hoverMessage}</label>
            </div>
        )
    }
}

export default StoreSelectorTip;