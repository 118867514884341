import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SocialButton from './SocialButton.jsx';
import FacebookButton from "./FacebookButton";

export default class SnapchatButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null
        };

        // getSnapchatAsync()
        // .then(url => this.setState({ imageUrl: url }))
        // .catch(error => console.error(error));
    }

    render() {
        const { color, profileUrl } = this.props;
        // const imageSrc = this.state.imageSrc;
        return (
            <SocialButton
                // imageSrc='https://cdn.obsess-vr.com/snapchat.svg'
                style={this.props.style}
                imageSrc='https://s3.amazonaws.com/obsessvr-webstore-assets-public/snapchat.svg'
                redirectUrl={profileUrl}
                color={color}
            // onClickAnalytics={FacebookButtonAnalytics}
            />
        );
    }
}

SnapchatButton.propTypes = {
    style: Proptypes.object
};
