import { ContentTypes } from './ProductModalEnums.js';
import { constructUrl } from '../../../../../../utils/UrlConstructor.js';
import { loadUIImageAsync } from 'web-store-modules/packages/asset-loader';

export const getEnumeratedProductInfo = (productInfo, s3Bucket) => {
    return {
        [ContentTypes.SKU]: productInfo.sku,
        [ContentTypes.BRAND]: productInfo.brand,
        [ContentTypes.TITLE]: productInfo.name,
        [ContentTypes.DESCRIPTION]: productInfo.description,
        [ContentTypes.DETAILS_BODY_1]: productInfo.details_body_1,
        [ContentTypes.DETAILS_BODY_2]: productInfo.details_body_2,
        [ContentTypes.COLOR]: productInfo.color,
        [ContentTypes.PRICE]: productInfo.price,
        [ContentTypes.PRICE_CURRENCY]: productInfo.price_currency,
        [ContentTypes.PRICE_SUFFIX]: productInfo.price_suffix,
        [ContentTypes.PRICE_PREFIX]: productInfo.price_prefix,
        [ContentTypes.SALE_PRICE]: productInfo.sale_price,
        [ContentTypes.SIZE]: productInfo.size,
        [ContentTypes.URL]: productInfo.url ? constructUrl(productInfo.url, s3Bucket) : productInfo.url,
        [ContentTypes.URL_NAME]: productInfo.url_name,
        [ContentTypes.ADD_TO_CART_URL]: productInfo.add_to_cart_url,
        [ContentTypes.THUMBNAIL]: productInfo.thumbnail,
        [ContentTypes.DOWNSIZED_THUMBNAIL]: productInfo.downsized_thumbnail,
        [ContentTypes.SPIN_IMAGES]: productInfo.spin_images,
        [ContentTypes.DOWNSIZED_SPIN_IMAGES]: productInfo.downsized_spin_images,
        [ContentTypes.CAROUSEL_IMAGES]: productInfo.carousel_images,
        [ContentTypes.DOWNSIZED_CAROUSEL_IMAGES]: productInfo.downsized_carousel_images,
        [ContentTypes.GROUP]: productInfo.group_id,
        [ContentTypes.COLOR_IMAGE_FILE]: productInfo.color_image_file ? constructUrl(productInfo.color_image_file, s3Bucket) : productInfo.color_image_file,
        [ContentTypes.SOLD_OUT]: productInfo.sold_out,
        [ContentTypes.SOLD_OUT_TEXT]: productInfo.sold_out_text,
        [ContentTypes.CUSTOM_FIELD]: productInfo.custom_field,
        [ContentTypes.VIDEO]: productInfo.video,
        [ContentTypes.GLTF_FILE]: productInfo.gltf_model,
        [ContentTypes.USDZ_FILE]: productInfo.usdz_model
    };
}

export const constructSpinImageUrls = (spinObjs, s3Bucket, loadImage = true, result = { low: [], mid: [], high: [] }) => {
    Object.entries(spinObjs).forEach(([pov, urlObjs]) => {
        if (urlObjs) {
            result[pov] = urlObjs.map((urlObj) => {
                const url = constructUrl(urlObj, s3Bucket);
                if (loadImage) {
                    loadUIImageAsync(url);
                }
                return url;
            });
        }
    });
    return result;
}

export const formatPriceString = (priceNum, currencySymbol = '$', suffix = '', prefix = '', locale = 'en-US') => {
    const currencyMap = {
        '$': 'USD', // US Dollar
        '€': 'EUR', // Euro
        '₡': 'CRC', // Costa Rican Colón
        '£': 'GBP', // British Pound Sterling
        '₪': 'ILS', // Israeli New Sheqel
        '₹': 'INR', // Indian Rupee
        '¥': 'JPY', // Japanese Yen
        '₩': 'KRW', // South Korean Won
        '₦': 'NGN', // Nigerian Naira
        '₱': 'PHP', // Philippine Peso
        'zł': 'PLN', // Polish Zloty
        '₲': 'PYG', // Paraguayan Guarani
        '฿': 'THB', // Thai Baht
        '₴': 'UAH', // Ukrainian Hryvnia
        '₫': 'VND', // Vietnamese Dong
        'QR': 'QAR', // Quatari Riyal
        '₽': 'RUB' // Russian Ruble
    }
    const checkForDecimal = (num) => {
        if (typeof num === 'string') {
            return num.includes('.');
        } else {
            return num % 1 !== 0;
        }
    }

    if (currencySymbol) {
        const currency = currencySymbol.length < 3 ? currencyMap[currencySymbol] : currencySymbol;
        const price = new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: checkForDecimal(priceNum) ? 2 : 0 }).format(priceNum)
        suffix = suffix !== undefined ? suffix : '';
        prefix = prefix !== undefined ? prefix : '';
        return (prefix + price + suffix);

    }
}

export const dropdownSpacingMultiplier = (numberOfItems) => {
    if (numberOfItems <= 1) {
        return 8.1;
    } else if (numberOfItems <= 2) {
        return 6.76;
    } else if (numberOfItems <= 3) {
        return 6.31;
    } else if (numberOfItems <= 4) {
        return 6.12;
    } else if (numberOfItems <= 5) {
        return 5.96;
    } else if (numberOfItems <= 6) {
        return 5.87;
    } else if (numberOfItems <= 7) {
        return 5.8;
    }
    return 5.78;
}