import React from 'react';
import PropTypes from 'prop-types';
import Hotspot from './Hotspot';


class HotspotCircle extends Hotspot {
    constructor(props) {
        super(props);
    }

    getPlainSVG(color) {
        return (
            <svg id='hotspotcircle' version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="512px" height="512px" viewBox="0 0 512 512"
                 enableBackground="new 0 0 256 256">
                <circle fill="#FFFFFF" className="cls-1" cx="249.5" cy="249.5" r="245"/>
                <ellipse fill={color} className="cls-2" cx="247.69" cy="251.26" rx="148.34"
                         ry="151.73"/>
            </svg>);
    }


}

HotspotCircle.propTypes = { primaryColor: PropTypes.string.isRequired, };
export default HotspotCircle;

