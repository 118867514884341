import React from 'react';

export const AddToCartContext = React.createContext({
    cartList: null,
    itemCount: null,
    addToCart: null,
    removeFromCart: null,
    cartButtonHidden: null,
    setCartButtonHidden: null,
    pricePrefix: null,
    colors: null,
    buttonFields: null
})