import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalOverlay from './../ModalOverlay.jsx';
import { MediaContext } from '../../MediaContext';
import './ComposableVideo.css';
import { getAltText } from '../../../../utils/StaticAssetManager.js';

const enCaption = 'https://cdn.obsess-vr.com/obsess-cms-beta/clients/Obsess/5f319733135089517fd8a79d/images/hotspots/test-cc-en.vtt'
const esCaption = 'https://cdn.obsess-vr.com/obsess-cms-beta/clients/Obsess/5f319733135089517fd8a79d/images/hotspots/test-cc-es.vtt'
const krCaption = 'https://cdn.obsess-vr.com/obsess-cms-beta/clients/Obsess/5f319733135089517fd8a79d/images/hotspots/test-cc-kr.vtt'
const jpCaption = 'https://cdn.obsess-vr.com/obsess-cms-beta/clients/Obsess/5f319733135089517fd8a79d/images/hotspots/test-cc-jp.vtt'
// NOTE: mp4 and vtt should be in the same location(folder), otherwise cc doesn't work.

const ComposableVideo = ({ mediaContext, src, style }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoFileName, setVideoFileName] = useState('')

    useEffect(() => {
        mediaContext.setIsVideoPlaying(true);
        const videoFileName = getAltText(src);
        setVideoFileName(videoFileName)

        return mediaContext.setIsVideoPlaying(false);
    }, []);

    const onPlay = () => {
        setIsPlaying(true);
    };

    const onPause = () => {
        setIsPlaying(false);
    };

    const onStartLoad = () => {
        setIsLoading(true)
    }

    const onEndLoad = () => {
        setIsLoading(false)
    }

    return (
        <div className={isPlaying ? 'composableVideoPlaying' : ''}>
            <ModalOverlay />
            <video
                controls
                autoPlay
                preload='auto'
                style={style}
                onPlay={onPlay}
                onPause={onPause}
                onLoadStart={onStartLoad}
                onLoadedData={onEndLoad}
                width={isLoading ? '0' : 'auto'}
                alt={videoFileName}
                crossorigin="anonymous"
            // From the client side, it's impossible to force an over-ride, though correctly setting the crossorigin attribute - in this case to anonymous does help the browser negotiate the request correctly 
            >
                <source src={src} />
                <track kind="captions" srclang="en" label="English" src={enCaption} default />
                {/* English cc is on by default */}
                <track kind="captions" srclang="es" label="Espanol" src={esCaption} />
                <track kind="captions" srclang="jp" label="Japanese" src={jpCaption} />
                <track kind="captions" srclang="kr" label="Korean" src={krCaption} />
            </video>
        </div>
    );
}

ComposableVideo.propTypes = {
    src: PropTypes.string.isRequired,
    style: PropTypes.object
};

export default props => (
    <MediaContext.Consumer>
        {mediaContextValue => (
            <ComposableVideo
                mediaContext={mediaContextValue}
                {...props}
            />
        )}
    </MediaContext.Consumer>
)

