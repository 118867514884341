import ShopifyBuy from 'shopify-buy';
import { getStoreId } from './StoreConfigManager.js';
import { constructUrl } from './UrlConstructor.js';
const variables = require('../../../variables/Variables.js');

const apiUrl = variables.apiUrl;

export const ENDPOINTTYPE = Object.freeze({
    SHOPIFY: 'shopify',
    OBSESSAPI: 'obsessapi'
});

const productDataMap = new Map();
let fetchCallCount = 0;
const fetchCallRate = 5;

const shopClient = ShopifyBuy.buildClient({
    domain: '',     // carmen-sol.myshopify.com
    storefrontAccessToken: '',      // 2859daf83d536ca26f6404d5d7196a5c
});

function delay(milliseconds) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
}

function getProductInfoWithIdAsyncShopify(id) {
    var ids = id.split('?variant=');
    var handle = ids[0];
    var variantId = ids[1];
    return new Promise((resolve, reject) => {
        let cachedProductData = productDataMap.get(id);
        if (cachedProductData) {
            resolve(cachedProductData);
        } else {
            shopClient.product.fetchByHandle(handle)
            .then(product => {
                if (product) {
                    product.variants.forEach(variant => {
                        if (variant.id == window.btoa('gid://shopify/ProductVariant/' + variantId)) {
                            if (!variant) {
                                console.log('no variant' + variantId);
                            }
                            let productInfo = {
                                title: product.title,
                                description: variant.selectedOptions[0].value,
                                price: variant.price,
                                imageSrc: variant.image.src,
                                url: product.onlineStoreUrl + '?variant=' + variantId
                            }
                            productDataMap.set(id, productInfo);
                            resolve(productInfo);
                        }
                    });
                } else {
                    throw new Error('Failed to fetch product info from shopify with id: ' + id);
                }
            })
            .catch(error => reject(error));
        }
    });
}

function getProductInfoWithIdAsyncShopifyRetry(id) {
    return new Promise((resolve, reject) => {
        getProductInfoWithIdAsyncShopify(id)
        .then(productInfo => {
            resolve(productInfo);
        })
        .catch(error => {
            console.error(error);
            ++fetchCallCount;
            let timeoutMultiplier = Math.floor(fetchCallCount / fetchCallRate);
            delay(timeoutMultiplier * 1000)
            .then(() => {
                return getProductInfoWithIdAsyncShopify(id);
            })
            .catch(error => reject(error));
        });
    });
}

function getProductInfoAsyncObsessAPI(sku) {
    return new Promise((resolve, reject) => {
        let cachedProductData = productDataMap.get(sku);
        if (cachedProductData) {
            resolve(cachedProductData);
        } else {
            const storeId = getStoreId();
            const fetchUrl = apiUrl + 'store/product?store_id=' + storeId + '&product_id=' + sku;
            fetch(fetchUrl)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Fetch product info failed with code ' + response.status);
                }
            })
            .then(responseJson => {
                if (productDataMap.get(sku)) {
                    console.log('Product data fetch finished before this fetch');
                } else {
                    productDataMap.set(sku, responseJson);
                }
                resolve(responseJson);
            })
            .catch(error => reject(error));
        }
    });
}

function fetchMultipleProductInfoObsessAPI(fetchUrl) {
    return new Promise((resolve, reject) => {
        fetch(fetchUrl)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Fetch product infos failed with code ' + response.status);
            }
        })
        .then(responseJson => resolve(responseJson))
        .catch(error => reject(error));
    });
}

export function getAllProductInfosObsessAPI() {
    const storeId = getStoreId();
    const fetchUrl = apiUrl + 'store/products?store_id=' + storeId;
    return fetchMultipleProductInfoObsessAPI(fetchUrl);
}

export function getSelectedProductInfosObsessAPI(skus) {
    const storeId = getStoreId();
    const skuParams = '&product_id=' + skus.join('&product_id=');
    const fetchUrl = apiUrl + 'store/products?store_id=' + storeId + skuParams;
    return fetchMultipleProductInfoObsessAPI(fetchUrl);
}

export function getGroupProductInfosObsessAPI(groupId) {
    const storeId = getStoreId();
    const skuParams = '&group_id=' + groupId;
    const fetchUrl = apiUrl + 'store/products?store_id=' + storeId + skuParams;
    return fetchMultipleProductInfoObsessAPI(fetchUrl);
}

export function getProductInfoWithIdAsync(id, endPointType) {
    switch (endPointType) {
        case ENDPOINTTYPE.SHOPIFY:
            return getProductInfoWithIdAsyncShopifyRetry(id);
        case ENDPOINTTYPE.OBSESSAPI:
        default:
            return getProductInfoAsyncObsessAPI(id)
    }
}

export function getProductInfoWithIdImmediate(id, endPointType) {
    switch (endPointType) {
        case ENDPOINTTYPE.SHOPIFY:
            return getProductInfoWithIdAsyncShopify(id);
        case ENDPOINTTYPE.OBSESSAPI:
            return getProductInfoAsyncObsessAPI(id)
        default:
            return getProductInfoAsyncObsessAPI(id)
    }
}

export function getSpinImageSrcsBySkuAsync(sku) {
    return new Promise((resolve, reject) => {
        getProductInfoWithIdAsync(sku, ENDPOINTTYPE.OBSESSAPI)
        .then(productData => {
            const spinImageObjects = productData['spin_images'];
            const spinImageSrcs = spinImageObjects.map(imageObject => constructUrl(imageObject));
            resolve(spinImageSrcs);
        })
        .catch(error => {
            console.error(error);
            reject(new Error('Spin image data with sku doesn`t exist! SKU:', sku));
        });
    });
}
