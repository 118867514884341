import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SVGImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parsedSvg: null
        };

        this.fetchSrc = this.fetchSrc.bind(this);

        this.fetchSrc();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.fetchSrc();
        }
    }

    fetchSrc() {
        fetch(this.props.src)
            .then(response => {
                if(response.ok) {
                    return response.text();
                }
                throw new Error('Network response was not ok.');
            })
            .then(svgString => {
                const parser = new DOMParser();
                const parsedSvg = parser.parseFromString(svgString, 'image/svg+xml');
                this.setState({ parsedSvg: parsedSvg });
            })
            .catch(error => console.error(error));
    }

    render() {
        let { parsedSvg } = this.state;
        if (!parsedSvg) {
            return (<React.Fragment />);
        }

        const { svgId, width, height, viewbox, color } = this.props;
        const svg = svgId ? parsedSvg.getElementById(svgId) : parsedSvg.getElementsByTagName('svg')[0];
        if (width) { svg.setAttribute('width', width); }
        if (height) { svg.setAttribute('height', height); }
        if (viewbox) { svg.setAttribute('viewbox', viewbox); }
        let iconSVGString = svg.outerHTML;

        if (color) {
            iconSVGString = iconSVGString.replace(new RegExp("fill=\"white\"", "g"), 'fill=\"' + color + '\"');
        }
        return (
            <div className={this.props.className} dangerouslySetInnerHTML={{ __html: iconSVGString }} />
        );
    }
}

SVGImage.propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    svgId: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    viewbox: PropTypes.string
};

export default SVGImage;
