/*
    Provides functionality to render background images, blurred images, and Room Objects to the screen. Critically,
    getRoomObjectRenderAsync, which is called by Room.jsx to render RoomObjects, returns a list of Components that
    correspond to specific types of NavMarkers or HotspotMarkers
 */

import React from "react";

import {
  getSceneRoomObjectsAsync,
  getStyleColorAsync,
  getSceneInfoAsync,
} from "./StoreConfigManager.js";
import NavMarker from "../components/hotspots/NavMarker.jsx";
import HotspotMarker from "../components/hotspots/HotspotMarker.jsx";
import { constructUrl } from "./UrlConstructor.js";
import { detectIEVersion } from "./BrowserDetector.js";
import ArrowLabelMarker from "../components/hotspots/ArrowLabelMarker.jsx";

const RoomObjectTypes = {
  NavMarker: NavMarker,
  ArrowLabel: ArrowLabelMarker,
  HotspotMarker: HotspotMarker,
};

export const HotspotMarkerType = Object.freeze({
  ProductMarker: "ProductMarker",
  ProductImageMarker: "ProductImageMarker",
  SceneVideoMarker: "SceneVideoMarker",
  LinkMarker: "LinkMarker",
  ModalMarker: "ModalMarker",
  TextBoxHoverMarker: "TextBoxHoverMarker",
  HeartMarker: "HeartMarker",
  AnimatedMarker: "AnimatedMarker",
  SoundMarker: "SoundMarker",
});

// TODO: change video in db to be called modal marker and use the correct schema
const HotspotMarkerDBTypeMap = {
  product: HotspotMarkerType.ProductMarker,
  product_image: HotspotMarkerType.ProductImageMarker,
  embedded_video: HotspotMarkerType.SceneVideoMarker,
  text_box_hover: HotspotMarkerType.TextBoxHoverMarker,
  link: HotspotMarkerType.LinkMarker,
  composable_modal: HotspotMarkerType.ModalMarker,
  image: HotspotMarkerType.ModalMarker,
  animated_image: HotspotMarkerType.AnimatedMarker,
  video: HotspotMarkerType.ModalMarker,
  textbox: HotspotMarkerType.TextBoxHoverMarker,
  heart: HotspotMarkerType.HeartMarker,
  text_with_button: HotspotMarkerType.ModalMarker,
  image_carousel: HotspotMarkerType.ModalMarker,
  sound: HotspotMarkerType.SoundMarker,
  iframe: HotspotMarkerType.ModalMarker,
};

const defaultPrimaryColor = "black";
const defaultSecondaryColor = "white";

// Determine what type of RoomObject to render (NavMarker vs HotspotMarker)
function parseSceneObjectJsonAsync(roomId, key, sceneObject, navLen) {
  let markerKey = key;
  console.log("markerKey? ", markerKey);
  return new Promise((resolve, reject) => {
    let primaryColor = defaultPrimaryColor;
    let secondaryColor = defaultSecondaryColor;

    // TODO: Do the primary and secondary color inside NavMarker and not here, because this isn't something that is stored on the room object in the db.
    getStyleColorAsync()
      .then((colors) => {
        primaryColor = colors[0];
        secondaryColor = colors[1];

        const objectType = sceneObject["type"];
        const objectTransform = sceneObject["transform"];
        const objectColliderTransform = sceneObject["collider_transform"];
        // Construct react render for object
        // ObjectReactType has to be captial initial, or the browser will log error: Always use lowercase HTML tags in React
        const ObjectReactType = RoomObjectTypes[objectType];
        const objectProps = parseObjectProps(
          roomId,
          objectType,
          sceneObject["props"]
        );
        const objectReactRender = (
          <ObjectReactType
            key={key}
            transform={objectTransform}
            colliderTransform={objectColliderTransform}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            markerKey={markerKey}
            markerLen={navLen}
            roomId={roomId}
            {...objectProps}
          />
        );
        resolve(objectReactRender);
      })
      .catch((error) => reject(error));
  });
}

// Filter out the correct props for this roomobject
function parseObjectProps(roomId, type, props) {
  let reactComponentProps = {};
  switch (type) {
    case "NavMarker":
      reactComponentProps.linkedRoomId = props["linked_room_id"]["$oid"];
      reactComponentProps.id = roomId + "-" + reactComponentProps.linkedRoomId;
      reactComponentProps.spriteType = props["sprite_type"];
      reactComponentProps.rotationAngle = props["sprite_rotation_degree"];
      reactComponentProps.canFade = props["fade"];
      reactComponentProps.showVisual = !props["hide"];
      break;
    case "ArrowLabel":
      reactComponentProps.linkedRoomId = props["linked_room_id"]["$oid"];
      reactComponentProps.id = roomId + "-" + reactComponentProps.linkedRoomId;
      reactComponentProps.spriteType = props["sprite_type"];
      reactComponentProps.rotationAngle = props["sprite_rotation_degree"];
      reactComponentProps.canFade = props["fade"];
      reactComponentProps.showVisual = !props["hide"];
      if (props["label"]) {
        reactComponentProps.labelColor = props["label"]["color"];
        reactComponentProps.labelFont = props["label"]["name"];
        reactComponentProps.labelText = props["label"]["text"];
        reactComponentProps.labelSize = props["label"]["size"];
      }
      break;
    case "HotspotMarker":
      const hotspotType = props["hotspot_type"];
      reactComponentProps.type = HotspotMarkerDBTypeMap[hotspotType];
      reactComponentProps.markerProps = props;
      break;
    default:
      console.error(type + " does not match any existing room object types!");
      break;
  }
  return reactComponentProps;
}

export function getBlurredImageUrl(imageUrl) {
  let imagePath = imageUrl.split("/");
  imagePath.splice(imagePath.length - 1, 0, "blur");
  return imagePath.join("/");
}

export function getRoomObjectRenderAsync(roomId) {
  return new Promise((resolve, reject) => {
    getSceneRoomObjectsAsync(roomId)
      .then((sceneObjects) => {
        let sceneObjectReactRenders = [];
        let navLen = [];
        sceneObjects.forEach((sceneObject, i) => {
          parseSceneObjectJsonAsync(roomId, i, sceneObject, navLen)
            .then((objectReactRender) => {
              if (
                sceneObject.props.hotspot_type === "embedded_video" &&
                detectIEVersion()
              ) {
                console.log("Skipping embedded video render for IE");
              } else {
                sceneObjectReactRenders.push(objectReactRender);
              }
              if (sceneObjectReactRenders.length === sceneObjects.length) {
                resolve(<div>{sceneObjectReactRenders}</div>);
              }
              if (sceneObject.type === "NavMarker") {
                navLen.push(i);
              }
            })
            .catch((error) => reject(error));
        });
      })
      .catch((error) => reject(error));
  });
}
