import * as THREE from 'three';

class Collider extends THREE.Mesh {
    constructor(threeGeometry, threeMaterial, onHover, onUnhover, onClick) {
        super(threeGeometry, threeMaterial);

        this.onHoverAction = onHover;
        this.onUnhoverAction = onUnhover;
        this.onClickAction = onClick;

        this.onHover = this.onHover.bind(this);
        this.onUnhover = this.onUnhover.bind(this);
        this.onClick = this.onClick.bind(this);

        this.setTransform = this.setTransform.bind(this);
    }

    onHover() {
        if (this.onHoverAction) {
            this.onHoverAction(this);
        }
    }

    onUnhover() {
        if (this.onUnhoverAction) {
            this.onUnhoverAction(this);
        }
    }

    onClick() {
        if (this.onClickAction) {
            this.onClickAction(this);
        }
    }

    setTransform(transformMatrix4x4) {
        this.matrix = transformMatrix4x4;
        this.matrix.decompose(this.position, this.quaternion, this.scale);
    }
}

export default Collider;
