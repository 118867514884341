import React, { useState, useEffect, createContext } from 'react';

const initialState = {
    isFlatImageScene: false,
    flatImageButtonOption: null,
    flatImageButtonText: '',
};

export const FlatImageSceneContext = createContext(initialState);

const FlatImageSceneContextComponent = ({ children }) => {
    const [isFlatImageScene, setIsFlatImageScene] = useState(false);
    const [flatImageButtonOption, setFlatImageButtonOption] = useState(null);
    const [flatImageButtonText, setFlatImageButtonText] = useState('');

    const setIsFlatImageSceneWithSceneIds = (currentSceneId, flatImageSceneId) => {
        setIsFlatImageScene(currentSceneId === flatImageSceneId);
    }

    return (
        <FlatImageSceneContext.Provider value={{
            isFlatImageScene,
            flatImageButtonOption,
            flatImageButtonText,
            setIsFlatImageSceneWithSceneIds,
            setFlatImageButtonOption,
            setFlatImageButtonText,
        }}>
            {children}
        </FlatImageSceneContext.Provider>
    )
}

export default FlatImageSceneContextComponent;