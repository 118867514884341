import { getFontNamedUrlsAsync } from './StoreConfigManager.js';

function setFont() {
    return new Promise((resolve, reject) => {
        getFontNamedUrlsAsync()
        .then(fontNamedUrls => {
            const fontStyleNode = document.createElement('style');
            let fontFaceString = '';
            fontNamedUrls.forEach(fontNamedUrl => {
                fontFaceString += '\
                    @font-face {\
                        font-family: ' + fontNamedUrl.name + ';\
                        font-display: swap;\
                        src: url("' + fontNamedUrl.url + '");\
                    }\
                ';                

                const fontPreloader = document.createElement('span');
                fontPreloader.style.fontFamily = fontNamedUrl.name;
                fontPreloader.style.width = 0;
                fontPreloader.style.height = 0;
                fontPreloader.style.zIndex = -999;
                document.body.appendChild(fontPreloader);
            });
            fontStyleNode.type = 'text/css';
            fontStyleNode.textContent = fontFaceString;
            document.head.appendChild(fontStyleNode);
            resolve();
        })
        .catch(error => reject(error));
    });
}

export function loadStyleData() {
    return new Promise((resolve, reject) => {
        setFont()
        .then(() => {
            resolve();
        })
        .catch(error => reject(error));
    });
}
