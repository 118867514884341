import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Modal.css';
import { ModalContext } from './ModalContext.jsx';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import { getModalCloseIconNormalAsync, getModalCloseIconHoverAsync } from '../../../utils/StaticAssetManager.js';
import { getPopupBackgroundColor, getStyleColorAsync, getPopupOpacity } from '../../../utils/StoreConfigManager';
import InteractableElement from '../InteractableElement';
import BaseImage from '../image-components/BaseImage';

const isMobile = isMobileDevice();
const closeIconStyle = {
    maxWidth: '100%',
    width: '2.8em',
    float: 'right'
};

class Modal extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onStartInteractionWithChildren = this.onStartInteractionWithChildren.bind(this);
        this.onEndInteractionWithChildren = this.onEndInteractionWithChildren.bind(this);

        this.state = {
            closeIconNormalSrc: '',
            closeIconHoverSrc: '',
            opacity: 1.0
        };
        this.isInteractingWithChildren = false;
        this.modalActive = true;

        getModalCloseIconNormalAsync()
            .then(url => this.setState({ closeIconNormalSrc: url }))
            .catch(error => console.error(error));
        getModalCloseIconHoverAsync()
            .then(url => this.setState({ closeIconHoverSrc: url }))
            .catch(error => console.error(error));
        getPopupBackgroundColor()
            .then(color => { this.setState({ backgroundColor: color }) })
            .catch(error => console.error(error));
        getStyleColorAsync()
            .then(colors => { this.setState({ primaryColor: colors[0] }) })
            .catch(error => console.error(error));
        if (props.isOpaque) {
            getPopupOpacity()
                .then(opacity => { this.setState({ opacity: opacity }) })
                .catch(error => console.error(error));
        }

    }

    componentDidMount() {
        if (isMobile) {
            window.addEventListener('touchend', this.onClick);
        } else {
            window.addEventListener('click', this.onClick);
        }
    }

    componentWillUnmount() {
        if (isMobile) {
            window.removeEventListener('touchend', this.onClick);
        } else {
            window.removeEventListener('click', this.onClick);
        }
    }

    onStartInteractionWithChildren() {
        this.isInteractingWithChildren = true;
    }

    onEndInteractionWithChildren() {
        // HACK: Figure out a proper way to interact with children without closing the modal.
        setTimeout(() => {
            this.isInteractingWithChildren = false;
        }, 10);
    }

    onClick(event) {
        if (this.isInteractingWithChildren) {
            return;
        }
        if (event.target.className.includes('modalContainer')) {
            this.closeModal();
        }
    }

    closeModal() {
        if (this.props.onClose) {
            this.modalActive = false;
            this.props.onClose();
        }
    }

    hexToRgb(hex) {
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        var rgb = result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;

        return rgb ? [rgb.r, rgb.g, rgb.b].join() : '255,255,255';
    }

    render() {
        const { closeIconNormalSrc, closeIconHoverSrc, backgroundColor, primaryColor, opacity } = this.state;
        const { isImageLoaded } = this.props
        const colorWithAlpha = backgroundColor ? this.hexToRgb(backgroundColor) : backgroundColor;
        const backgroundColorWithAlpha = 'rgba(' + colorWithAlpha + ',' + opacity + ')';
        const contextValue = {
            onStartInteractionWithChildren: this.onStartInteractionWithChildren,
            onEndInteractionWithChildren: this.onEndInteractionWithChildren,
            modalActive: this.modalActive
        };
        return (
            <ModalContext.Provider value={contextValue}>
                <div style={{ backgroundColor: backgroundColorWithAlpha }} className='modalContentWrapper'>
                    {isImageLoaded && <div className="modalContentWrapperCloseButton hoverable" style={{ background: primaryColor }}>
                        <InteractableElement onClick={this.closeModal}>
                            <BaseImage src={closeIconNormalSrc} style={closeIconStyle} />
                        </InteractableElement>
                    </div>}

                    {this.props.children}
                </div>
            </ModalContext.Provider>
        );
    }
}

Modal.propTypes = {
    onClose: PropTypes.func
};

export default Modal;
