import React, { Component } from 'react';

class MenuItem extends Component {
    constructor(props) {
        super(props);
        this.onHover = this.onHover.bind(this);
        this.onUnhover = this.onUnhover.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            isHover: false,
            keyboardNav: 0,
            isActive: false
        }

        this.clickRef = React.createRef();
    }
    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyboardNav);
        this.isActiveHandler();

    }

    handleKeyboardNav = (e) => {
        const { storeSelectorLen } = this.props;
        const { keyboardNav } = this.state;

        //If shift + ArrowLeft/ArrowRight are pressed, activate the keyboard
        if (e.shiftKey && e.key === "ArrowLeft" && keyboardNav > 0) {
            this.setState((prevState) => ({
                keyboardNav: prevState.keyboardNav - 1,
              }));
        } else if (
          e.shiftKey &&
          e.key === "ArrowRight" &&
          this.state.keyboardNav < storeSelectorLen - 1
        ) {
            this.setState((prevState) => ({
                keyboardNav: prevState.keyboardNav + 1,
              }));
        }
        this.isActiveHandler();
      };
    
    isActiveHandler = () => {
        const { index } = this.props;
        const markerKey = index;
        if (this.state.keyboardNav === markerKey) {
            this.setState({
                isActive: true,
              });
              window.addEventListener("keydown", this.handleSpace);
        } else {
            this.setState({
                isActive: false,
              });
        }
    };

    handleSpace = (e) => {
        if (this.state.isActive && e.key === " ") {
            this.clickRef.current.click()
        }
    };

    onHover() {
        this.props.handleMouseOver();
        this.setState({ isHover: true });
    }

    onUnhover() {
        this.props.handleMouseOut();
        this.setState({ isHover: false });
    }

    handleClick(id) {
        this.props.handleClick(id)
        
    }

    render() {
        const { menuStyle, scene } = this.props;
        const { isHover, isActive } = this.state
        let sceneStyle = Object.assign({}, menuStyle);
        sceneStyle.color = isHover || isActive ? sceneStyle.hoverColor : sceneStyle.color;
        return (
            <div
                style={{ marginBottom: '0.3em' }}
                onMouseOver={this.onHover}
                onMouseOut={this.onUnhover}
                onClick={() => this.handleClick(scene.id)}
                ref={this.clickRef}
            >
                <span className="linked-store-scene" style={sceneStyle}> {scene.name} </span>
            </div>
        )
    }
}

export default MenuItem;