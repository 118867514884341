import React from 'react';
import PropTypes from 'prop-types';
import Svg from './Svg';

const arrowType = 'arrow';
class ArrowSvg extends Svg {
	constructor(props) {
		super(props);
	}

	getPlainSVG(xrotation, color, opacity) {
		let rotation = "rotate(" + xrotation + " 256 256)";
		return(
			<svg id={arrowType} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512">
				<g>
					<circle fill='#FFFFFF' opacity={opacity} className="st0" cx="256" cy="256" r="246"/>
				</g>
				<g transform={rotation}>
					<circle fill={color} opacity={opacity} className="st1" cx="256" cy="256" r="230"/>
					<circle fill={color} opacity={opacity} className="st2" cx="256" cy="256" r="230"/>
					<path fill='#FFFFFF' opacity={opacity} className="st3" d="M410.4,332.3c-3.5,4.1-7.8,5.2-12.3,3.2c-1.8-0.8-18.7-17.4-72.2-71L256,194.6l-69.6,69.6
					c-38.3,38.3-70.3,70.1-71.2,70.7c-4,2.7-9,2-13-2l-2.6-2.6v-4.8v-4.8l75.1-75.1c45.5-45.5,75.9-75.5,77.1-76.1
					c6-3,7.2-2.2,28.3,18.9c10,10,18.4,18.2,18.8,18.2s0.6,0.3,0.6,0.6c0,0.8,8.7,9.5,9.5,9.5c0.3,0,0.6,0.2,0.6,0.5
					c0,0.7,12.9,13.8,13.7,13.8c0.4,0,0.7,0.2,0.7,0.5c0,0.7,23.1,23.9,23.8,23.9c0.3,0,0.6,0.2,0.6,0.6c0,0.3,14.4,15,32.1,32.6
					l32.1,32.1v4.6C412.4,330,412.4,330,410.4,332.3"/>
				</g>
			</svg>
		);
	}

}

ArrowSvg.propTypes = {
	primaryColor: PropTypes.string.isRequired,
	rotation: PropTypes.number.isRequired
};

export default ArrowSvg;
