import ReactDOMServer from 'react-dom/server';
import canvg from 'canvg';

let svgStringCanvas = null;

export function createImageFromSvg(svg, finished) {
    let data = ReactDOMServer.renderToStaticMarkup(svg);
    let canvas = document.getElementById('canvas')
    if (!canvas) {
        canvas = document.createElement("canvas");
        canvas.id = 'canvas';
        canvas.style.display = "none";
        document.body.appendChild(canvas);
    }
    canvg('canvas', data, {
        renderCallback: function () {
            if (finished)
                finished(canvas);
        }
    });
}

export function createImageFromSvgString(svg, finished) {
    let canvas = document.getElementById('canvas-svg-string')
    if (!canvas) {
        canvas = document.createElement("canvas");
        // canvas.id = 'canvas-svg-string';
        // canvas.style.display = "none";
        canvas.width = 512;
        canvas.height = 512;
        // document.body.appendChild(canvas);
    }

    const v = canvg.fromString(
        canvas.getContext('2d'),
        svg,
        {
            enableRedraw: false,
            ignoreMouse: true,
            ignoreAnimation: true,
            ignoreClear: true
        }
    );
    v.render().then(res => {
        finished(canvas);
    }).catch(err => console.log(err));

}


// export function createImageFromSvgString(svgString, finished) {
//     if (!svgStringCanvas)
//     {
//         svgStringCanvas = document.createElement("canvas");
//         svgStringCanvas.width = 512;
//         svgStringCanvas.height = 512;
//     }
//     const v = canvg.fromString(
//         svgStringCanvas.getContext('2d'),
//         svgString,
//         {
//             enableRedraw: false,
//             ignoreMouse: true,
//             ignoreAnimation: true,
//             ignoreClear: true
//         }
//     );
//     v.render();
//     finished(svgStringCanvas);
// }

export function getIconSVGAsync(color, url) {
    return new Promise((resolve, reject) => {
        fetch(url).then(response => {
            if (response.status === 200) {
                return response.text();
            } else {
                throw new Error('Icon do not exist!');
            }
        }).then(svgString => {
            if (color) {
                const newSVGString = svgString.replace(new RegExp("fill=\"white\"", "g"),
                    'fill=\"' + color + '\"');
                return resolve(newSVGString);
            } else {
                return resolve(svgString);
            }
        }).catch(error => reject(error));
    });
}
