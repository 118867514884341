import * as THREE from 'three';

import Collider from './Collider.js';

class BoxCollider extends Collider {
    constructor(width, height, depth, onHover, onUnhover, onClick) {
        let boxGeometry = new THREE.BoxGeometry(width, height, depth);
        let colliderMaterial = new THREE.MeshBasicMaterial({ color: '#19c100' });
        colliderMaterial.wireframe = true;
        super(boxGeometry, colliderMaterial, onHover, onUnhover, onClick);
    }
}

export default BoxCollider;
