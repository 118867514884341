import React from 'react';
import './Screenshot.css'

const Screenshot = ({ showScreenshot }) => {
    return (
        // <div id='screenshot-overlay' style={{ display: showScreenshot ? 'block' : 'none' }}>
        <div id='screenshot-overlay'>
            <img src='https://via.placeholder.com/1000' />
        </div>
    );
}

export default Screenshot;