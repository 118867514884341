import React, { useState, useEffect, useContext } from 'react';

import './CartButton.css';

const cartIcon = 'https://cdn.obsess-vr.com/add-to-bag-icon.png';

// TODO: PASS IN CART PAGE URL, CART TEXT STYLE, AND CART TEXT AS PROPS
const CartButton = ({ onClick }) => {
    return (
        <div className='cart-button-container flex-center hoverable' onClick={onClick}>
            <div className='cart-icon-container flex-center'>
                <img className='cart-icon' src={cartIcon} />
            </div>
            <span className='cart-button-text'>Wishlist</span>
        </div>
    )
}

export default CartButton;