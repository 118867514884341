const variables = require('../../../variables/Variables.js');
const apiUrl = variables.apiUrl;

function getStaticUrlAsync(id) {
    const fetchUrl = apiUrl + 'asset/url?id=' + id;
    return new Promise((resolve, reject) => {
        fetch(fetchUrl)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Fetch static asset url failed with code ' + response.status);
            }
        })
        .then(responseJson => resolve(responseJson))
        .catch(error => reject(error));
    });
}

export const NavMarkerSpriteName = Object.freeze({
    NORMALLEFT: 'NavMarkerNormalLeft',
    NORMALRIGHT: 'NavMarkerNormalRight',
    NORMALUP: 'NavMarkerNormalUp',
    NORMALDOWN: 'NavMarkerNormalDown',
    NORMALUPLEFT: 'NavMarkerNormalUpLeft',
    NORMALDOWNLEFT: 'NavMarkerNormalDownLeft',
    NORMALUPRIGHT: 'NavMarkerNormalUpRight',
    NORMALDOWNRIGHT: 'NavMarkerNormalDownRight',
    NORMALSTAIRSUP: 'NavMarkerNormalStairsUp',
    NORMALSTAIRSDOWN: 'NavMarkerNormalStairsDown',
    HOVERLEFT: 'NavMarkerHoverLeft',
    HOVERRIGHT: 'NavMarkerHoverRight',
    HOVERUP: 'NavMarkerHoverUp',
    HOVERDOWN: 'NavMarkerHoverDown',
    HOVERUPLEFT: 'NavMarkerHoverUpLeft',
    HOVERDOWNLEFT: 'NavMarkerHoverDownLeft',
    HOVERUPRIGHT: 'NavMarkerHoverUpRight',
    HOVERDOWNRIGHT: 'NavMarkerHoverDownRight',
    HOVERSTAIRSUP: 'NavMarkerHoverStairsUp',
    HOVERSTAIRSDOWN: 'NavMarkerHoverStairsDown',
    FADELEFT: 'NavMarkerFadeLeft',
    FADERIGHT: 'NavMarkerFadeRight',
    FADEUP: 'NavMarkerFadeUp',
    FADEDOWN: 'NavMarkerFadeDown',
    FADEUPLEFT: 'NavMarkerFadeUpLeft',
    FADEDOWNLEFT: 'NavMarkerFadeDownLeft',
    FADEUPRIGHT: 'NavMarkerFadeUpRight',
    FADEDOWNRIGHT: 'NavMarkerFadeDownRight',
    FADESTAIRSUP: 'NavMarkerFadeStairsUp',
    FADESTAIRSDOWN: 'NavMarkerFadeStairsDown'
});

export function getHeartFillUrlAsync() {
    return getStaticUrlAsync('HeartFill');
}

export function getHeartEmptyUrlAsync() {
    return getStaticUrlAsync('HeartEmpty');
}

export function getNavMarkerUrlAsync(spriteName) {
    return getStaticUrlAsync(spriteName);
}

export function getProductMarkerNormalAsync() {
    return getStaticUrlAsync('ProductMarkerNormal');
}

export function getProductMarkerHoverAsync() {
    return getStaticUrlAsync('ProductMarkerHover');
}

export function getVideoMarkerNormalAsync() {
    return getStaticUrlAsync('VideoMarkerNormal');
}

export function getModalCloseIconNormalAsync() {
    return getStaticUrlAsync('ModalCloseIconNormal');
}

export function getModalCloseIconHoverAsync() {
    return getStaticUrlAsync('ModalCloseIconHover');
}

export function getDragToSpinAsync() {
    return getStaticUrlAsync('DragToSpin');
}

export function getDragToSpinIconAsync() {
    return getStaticUrlAsync('DragToSpinIcon');
}

export function getFacebookAsync() {
    return getStaticUrlAsync('FacebookIcon');
}

export function getTwitterAsync() {
    return getStaticUrlAsync('TwitterIcon');
}

export function getVideoPlayAsync() {
    return getStaticUrlAsync('VideoPlay');
}

export function getObsessLogoAsync() {
    return getStaticUrlAsync('ObsessLogo');
}

export function getObsessUrlAsync() {
    return getStaticUrlAsync('ObsessUrl');
}

export function getWelcomeExploreUrlAsync() {
    return getStaticUrlAsync('WelcomeExplore');
}

export function getZoomIconUrlAsync() {
    return getStaticUrlAsync('ZoomIcon');
}

export function getAltText (src) {
    const splitSrc = src?.split('/').pop();
    const fileName = splitSrc?.split('.')[0].replace(/[^a-zA-Z]/g, ' ');
    return fileName;
}