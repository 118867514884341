import React, { useState, useEffect, forwardRef } from 'react';

import './ThumbnailCarouselIndicator.css';

const WrappedIndicator = forwardRef((props, ref) => {
    return <ThumbnailCarouselIndicator forwardedRef={ref} {...props}/>
});

const ThumbnailCarouselIndicator = ({ forwardedRef, icon, index, currentIndex, thumbnail, onClick, last, alt }) => {
    const [selected, setSelected] = useState(false);
    useEffect(() => {
        if (index === currentIndex) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [currentIndex]);

    const thumbnailUrl = thumbnail.thumbnailUrl;

    return (
        <div
            ref={forwardedRef}
            className={selected ? 'thumbnail-indicator-container-selected' : 'thumbnail-indicator-container'}
            alt={alt}
            onClick={onClick}
            style={{
                backgroundImage: `url(${thumbnailUrl})`,
                marginBottom: last ? '0' : '10%'
            }}
        >
            {icon && <img className="thumbnail-indicator-icon" src={icon} />}
        </div>
    )
}

export default WrappedIndicator;
