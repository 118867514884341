import React, { useState, useEffect, useContext } from 'react';

import CartButton from './CartButton';
import CartDropdown from './CartDropdown';
import { getFontStylesAsync, getWishlistStyleAsync } from '../../../utils/StoreConfigManager';
import { isMobileDevice } from '../../../utils/DeviceDetector';
import { AddToCartContext } from './AddToCartContext';
import { WishlistIconClicked } from '../../../utils/Analytics';
import { AppContext } from '../../../AppContext';

const isMobile = isMobileDevice();
const cardStyle = {
    marginBottom: 'auto'
}
const AddToCart = ({ sceneSelectorActive, cartActive, showCartActive, showSceneSelector }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [menuTitleFontStyle, setMenuTitleFontStyle] = useState();
    const [menuTextFontStyle, setMenuTextFontStyle] = useState();
    const [wishlistFontStyle, setWishlistFontStyle] = useState();

    const { cartList } = useContext(AddToCartContext);
    const { currentSceneName } = useContext(AppContext);

    useEffect(() => {
        (async () => {
            const fontStyles = await getFontStylesAsync();
            const wishlistTextStyles = await getWishlistStyleAsync();
            setMenuTitleFontStyle({
                fontFamily: fontStyles.popUpTitle.name,
                fontSize: fontStyles.popUpTitle.size
            });
            setMenuTextFontStyle({
                fontFamily: fontStyles.popUpText.name,
                fontSize: fontStyles.popUpText.size
            })

            setWishlistFontStyle({
                fontSize: wishlistTextStyles.size,
                fontFamily: wishlistTextStyles.name,
                color: wishlistTextStyles.color,
                text: wishlistTextStyles.text
            })
        })()
    }, [])

    const closeCart = (e) => {
        console.log(e.target);
        if (e.target.tagName === 'CANVAS') {
            setShowDropdown(false);
            showCartActive(false);

            if (isMobile) {
                document.removeEventListener('touchend', closeCart);
            } else {
                document.removeEventListener('click', closeCart)
            }
        }
    }

    const handleCartButtonClick = () => {
        const showingDropdown = !showDropdown
        setShowDropdown(showingDropdown);
        if (sceneSelectorActive) {
            showSceneSelector(false);
        }
        showCartActive(!cartActive);

        if (showingDropdown) {
            WishlistIconClicked(currentSceneName)
            if (isMobile) {
                document.addEventListener('touchend', closeCart);
            } else {
                document.addEventListener('click', closeCart)
            }
        } else {
            if (isMobile) {
                document.removeEventListener('touchend', closeCart);
            } else {
                document.removeEventListener('click', closeCart)
            }
        }
    }

    return (
        <div id='add-to-cart-container' className="align-right" style={cardStyle}>
            {
                cartList.length > 0 && (
                    <CartButton
                        onClick={handleCartButtonClick}
                    />
                )
            }
            <CartDropdown
                cartTitle='cartTitle'
                showDropdown={cartActive}
                menuTextFontStyle={menuTextFontStyle}
                menuTitleFontStyle={menuTitleFontStyle}
                wishlistFontStyle={wishlistFontStyle}
                closeMenu={handleCartButtonClick}
            />
        </div>
    )
}

export default AddToCart;
