import React, { Component } from 'react';

import './StoreSelector.css';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import { getStoreSelectorDataAsync, getAllSceneInfoDictAsync, getFontStylesAsync, getIconsColorAsync } from '../../../utils/StoreConfigManager.js';
import { getAltText } from '../../../utils/StaticAssetManager';
import OldStoreSelectorMenu from './OldStoreSelector/OldStoreSelectorMenu.jsx';
import StoreSelectorMenu from './NewStoreSelector/StoreSelectorMenu.jsx';
import StoreSelectorTip from './StoreSelectorTip.jsx';
import { StoreSelectorIcon } from '../../../utils/Analytics.js';
import { constructUrl } from '../../../utils/UrlConstructor';
import Icon from '../ui-layer-panel/Icon';

const isMobile = isMobileDevice();

const dummyStoresData = [
    {
        name: "Store 1",
        location: "New York",
        url: "/ny",
    },
    {
        name: "Store 2",
        location: "Los Angeles",
        url: "/la",
    }
];

const iconStyle = {
    maxWidth: '100%',
    width: '2.3125em',
    height: '2.3125em'
};

class StoreSelector extends Component {
    constructor(props) {
        super(props);
        this.globeButtonClicked = this.globeButtonClicked.bind(this);
        this.onHover = this.onHover.bind(this);
        this.onUnhover = this.onUnhover.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        this.state = {
            newStoreSelector: true,
            showMenu: false,
            showTip: false,
            menuTextFontStyle: {},
            menuTitleFontStyle: {},
            hoverMessage: '',
            selectorTitle: '',
            menuTitle: '',
            currentStoreIndex: -1,
            linkedStores: dummyStoresData,
            worldIconSrc: '',
            worldIconColor: 'black',
            changeColor: true
        };

        getStoreSelectorDataAsync()
            .then((storeSelector) => {
                if (storeSelector) {
                    let linkedStores = [];

                    storeSelector.linked_stores.forEach(store => {
                        let storeInfo = {
                            name: store.name,
                            location: store.location,
                            url: store.url,
                            linkedScenes: store.linked_scenes
                        };
                        linkedStores.push(storeInfo);
                    });
                    const icon = storeSelector.selector_icon ? constructUrl(storeSelector.selector_icon) : 'https://s3.amazonaws.com/obsessvr-webstore-assets-public/world-icon.svg';
                    const newStoreSelector = storeSelector.linked_stores.length === 1;

                    this.setState({
                        linkedStores,
                        hoverMessage: storeSelector.hover_message,
                        menuTitle: storeSelector.menu_title,
                        selectorTitle: storeSelector.selector_icon_copy,
                        currentStoreIndex: [0, 1, 2, 3, 4].includes(storeSelector.own_store_index) ? storeSelector.own_store_index : -1,
                        worldIconSrc: icon,
                        changeColor: false,
                        newStoreSelector
                    });
                }
            })
            .catch(error => console.error(error));

        getAllSceneInfoDictAsync()
            .then(sceneInfoDict => {
                const idNameDict = {};
                for (const [id, info] of Object.entries(sceneInfoDict)) {
                    idNameDict[id] = info['name'];
                }
                this.setState({ sceneIdNameDict: idNameDict });
            })
            .catch(error => console.error(error));

        getFontStylesAsync()
            .then(fontStyles => {
                this.setState({
                    menuTitleFontStyle: {
                        marginTop: '1em',
                        fontFamily: fontStyles.storeSelectorTitle.name,
                        fontSize: fontStyles.storeSelectorTitle.size,
                        color: fontStyles.storeSelectorTitle.color
                    },
                    menuTextFontStyle: {
                        fontFamily: fontStyles.storeSelectorcategory.name,
                        fontSize: fontStyles.storeSelectorcategory.size,
                        color: fontStyles.storeSelectorcategory.color,
                        fontWeight: fontStyles.storeSelectorcategory.weight,
                        hoverColor: fontStyles.storeSelectorcategory.hoverColor
                    },
                });
            })
            .catch(error => console.error(error));

        getIconsColorAsync()
            .then(color => {
                this.setState({ worldIconColor: color });
            })
            .catch(error => console.error(error));
    }

    closeMenu(event) {
        if (event.target.tagName === 'CANVAS') {
            this.setState({ showMenu: false });
            this.props.showSceneSelector(false);
            const rootNode = document.getElementById('obsessvr-webstore-react-embed-root');
            if (isMobile) {
                rootNode.removeEventListener('touchend', this.closeMenu);
            } else {
                rootNode.removeEventListener('click', this.closeMenu);
            }
        }
    }

    onHover() {
        if (isMobile) { return; }
        if (!this.state.showMenu) {
            this.setState({ showTip: true });
        }
    }

    onUnhover() {
        if (isMobile) { return; }
        this.setState({ showTip: false });
    }

    globeButtonClicked() {
        StoreSelectorIcon();

        if (this.props.cartActive) {
            this.props.showCartActive(false);
        }
        let showMenu = !this.state.showMenu;
        this.props.showSceneSelector(!this.props.sceneSelectorActive);
        this.setState({ showMenu: showMenu, showTip: false });

        const rootNode = document.getElementById('obsessvr-webstore-react-embed-root');
        if (showMenu) {
            if (isMobile) {
                rootNode.addEventListener('touchend', this.closeMenu);
            } else {
                rootNode.addEventListener('click', this.closeMenu);
            }
        } else {
            if (isMobile) {
                rootNode.removeEventListener('touchend', this.closeMenu);
            } else {
                rootNode.removeEventListener('click', this.closeMenu);
            }
        }
    }

    render() {
        const {
            linkedStores,
            showTip,
            menuTextFontStyle,
            hoverMessage,
            showMenu,
            currentStoreIndex,
            menuTitleFontStyle,
            menuTitle,
            worldIconSrc,
            worldIconColor,
            sceneIdNameDict,
            changeColor,
            newStoreSelector,
            selectorTitle
        } = this.state;
        
        const { sceneSelectorActive } = this.props;
        const StoreSelectorMenuComponent = newStoreSelector ? StoreSelectorMenu : OldStoreSelectorMenu;
        const imgFileName = getAltText(worldIconSrc);

        return (
            <div id='storeSelector' className="align-right">
                {linkedStores.length !== 0 &&
                    <React.Fragment>
                        <div
                            className="flex-left"
                            onMouseOver={this.onHover}
                            onMouseOut={this.onUnhover}
                            style={linkedStores.length === 0 ? { display: "none" } : {}}
                        >
                            <div
                                id='globalIconContainer'
                                className='hoverable'
                                onClick={this.globeButtonClicked}
                            >
                                {
                                    worldIconSrc !== '' &&
                                    <Icon
                                        src={worldIconSrc}
                                        color={worldIconColor}
                                        width={50}
                                        height={50}
                                        viewbox='0 0 500 500'
                                        style={iconStyle}
                                        imgFileName={imgFileName}
                                    />
                                }
                                {worldIconSrc !== '' && menuTitle !== '' &&
                                    <span className="section-title" style={{ color: worldIconColor }}>{menuTitle}</span>
                                }
                            </div>

                        </div>
                        {(this.state.hoverMessage) ?
                            <StoreSelectorTip
                                showTip={showTip}
                                menuTextFontStyle={menuTextFontStyle}
                                hoverMessage={hoverMessage}
                            /> : null
                        }

                        <StoreSelectorMenuComponent
                            showMenu={sceneSelectorActive}
                            linkedStores={linkedStores}
                            currentStoreIndex={currentStoreIndex}
                            menuTextFontStyle={menuTextFontStyle}
                            menuTitleFontStyle={menuTitleFontStyle}
                            selectorTitle={selectorTitle}
                            sceneIdNameDict={sceneIdNameDict}
                            closeMenu={this.globeButtonClicked}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default StoreSelector;
