import React, { Component } from 'react';
import OldStoreSelectorMenuItem from './OldStoreSelectorMenuItem.jsx';
import '../StoreSelector.css';
import './OldStoreSelector.css';
import Segment from 'semantic-ui-react/dist/es/elements/Segment';
import Header from 'semantic-ui-react/dist/es/elements/Header';

import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import CurrentStoreMenuItem from './OldCurrentStoreMenuItem';

class OldStoreSelectorMenu extends Component {
    constructor(props) {
        super(props);
        this.hide = this.hide.bind(this);
        this.renderMenuItems = this.renderMenuItems.bind(this);
    }

    hide() {
        this.setState({ visible: false });
    }

    renderMenuItems() {
        return (
            <Segment.Group>
                {this.props.linkedStores.map((store, index) => {
                    let MenuItem = this.props.currentStoreIndex === index ? CurrentStoreMenuItem : OldStoreSelectorMenuItem;
                    return <MenuItem
                        key={index}
                        store={store}
                        menuTextFontStyle={this.props.menuTextFontStyle}
                        sceneIdNameDict={this.props.sceneIdNameDict}
                        closeMenu={this.props.closeMenu}
                    />
                })}
            </Segment.Group>
        );
    }

    render() {
        if (!this.props.showMenu) {
            return (<div />);
        }
        return (
            <div id='storeSelectorMenu'>
                <Header textAlign="center" id="storeSelectorMenuTitle" style={this.props.menuTitleFontStyle}>
                    {this.props.menuTitle}
                </Header>
                {this.renderMenuItems()}
            </div>
        );
    }
}

export default OldStoreSelectorMenu;
