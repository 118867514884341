import React, { useState, useEffect, useRef, useContext } from 'react';

import FlyUpModal from '../FlyUpModal';
import { getFontStylesAsync } from '../../../../utils/StoreConfigManager';
import './IFrameModal.css';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import ModalOverlay from '../ModalOverlay';

const isMobile = isMobileDevice();

const IFrameModal = ({ modalContent, onModalHidden, onClose }) => {
    const { title, src, inner_html, subtitle } = modalContent;
    const [fontStyles, setFontStyles] = useState();
    const modalRef = useRef();

    useEffect(() => {
        fetchFontStyles();
        addListeners();

        return (() => {
            removeListeners();
        });

    }, []);

    const addListeners = () => {
        if (isMobile) {
            window.addEventListener('touchend', handleModalClose);
        } else {
            window.addEventListener('click', handleModalClose);
        }
    }

    const removeListeners = () => {
        if (isMobile) {
            window.removeEventListener('touchend', handleModalClose);
        } else {
            window.removeEventListener('click', handleModalClose);
        }
    }

    const fetchFontStyles = () => {
        getFontStylesAsync()
            .then((fontStyles) => setFontStyles(fontStyles))
            .catch((err) => console.error(err));
    }

    const handleModalClose = (e) => {
        if (modalRef && modalRef.current.contains(e.target)) {
            return;
        }
        closeModal();
    }

    const closeModal = () => {
        if (onClose) {
            onClose();
        }
    }

    const titleRender = () => {
        return (
            <div className='text-modal-title-container' style={{ margin: '15px' }}>
                <span
                    style={{
                        fontSize: isMobile ? '60px' : fontStyles.popUpTitle.size,
                        fontFamily: fontStyles.popUpTitle.name
                    }}>
                    {title}
                </span>
            </div>
        );
    }

    const iFrameRrender = () => {
        return (
            <div className="iframeContrainer">
                <iframe src={src} style={{ width: '100%', height: '550px' }}></iframe>
            </div>
        );
    }

    const renderModalContent = () => {
        return (
            <div className='' style={{ textAlign: 'center' }} ref={modalRef}>
                {titleRender()}
                {iFrameRrender()}
            </div>
        )
    }
    return (
        //Added the isImage Loaded bool here to display the close button on the Modal due to a condition in Modal.jsx
        <FlyUpModal extraClassName="iframe-modal-container" onModalHidden={onModalHidden} isOpaque
            textPopup hideCloseIcon isImageLoaded>
            <ModalOverlay />
            {!!fontStyles && renderModalContent()}
        </FlyUpModal>
    );
}

export default IFrameModal;


