import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {SpinActivated} from "../../../../../utils/Analytics";
import {getDragToSpinIconAsync} from "../../../../../utils/StaticAssetManager";
import ProductModalImage from 'web-store-modules/packages/modal-media/lib/ProductModalImage';


class TemplateProductBody extends Component {
    constructor(props){
        super(props);

        this.onSpinActivated = this.onSpinActivated.bind(this);

        this.state = {
            dragToSpinIconUrl: ''
        };

        getDragToSpinIconAsync().then(dragToSpinIconUrl => { this.setState({ dragToSpinIconUrl })});
    }

    onSpinActivated() {
        const { sku, title } = this.props;
        SpinActivated(sku, title);
    }

    render() {
        const { sku, type, spinUrls, hiResSpinUrls, thumbnailUrl, modalOpened, colorSelectorReady } = this.props;
        return (thumbnailUrl) ?
         <ProductModalImage
            type={type}
            thumbnailUrl={thumbnailUrl}
            spinUrls={spinUrls}
            hiResSpinUrls={hiResSpinUrls}
            sku={sku}
            modalOpened={modalOpened}
            zoomLevel={3}
            onSpinActivated={this.onSpinActivated}
            dragToSpinIconUrl={this.state.dragToSpinIconUrl}
            scalingDimReady={colorSelectorReady}
        /> :
         <span>Image not found</span>;

    }
}

TemplateProductBody.propTypes = {
    sku: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    spinUrls: PropTypes.object,
    hiResSpinUrls: PropTypes.object,
    thumbnailUrl: PropTypes.string,
    modalOpened: PropTypes.bool,
    colorSelectorReady: PropTypes.bool
};

export default TemplateProductBody;