import React, { Component } from 'react';
import PropTypes from 'prop-types';
import canvg from 'canvg';
import ReactDOMServer from 'react-dom/server';

let canvas = null;

class Svg extends Component {
    constructor(props) {
        super(props);
        this.getSVG = this.getSVG.bind(this);
        this.saveSVG = this.saveSVG.bind(this);
    }

    saveSVG(color, finished, rotation = 0, opacity) {
        const r = rotation ? rotation : 0;
        const svg = this.getPlainSVG(r, color, opacity);
        const svgString = ReactDOMServer.renderToStaticMarkup(svg);
        if (!canvas) {
            canvas = document.createElement("canvas");
        }
        const v = canvg.fromString(canvas.getContext('2d'),
            svgString,
            { enableRedraw: false, ignoreMouse: true, ignoreAnimation: true, ignoreClear: true });

        v.render().catch(err => console.log(err));
        finished(canvas);
    }

    getSVG() {
        let r = this.props.rotation ? this.props.rotation : 0;
        let color = this.props.primaryColor ? this.props.primaryColor : 'black';
        return (this.getPlainSVG(r, color, 1.0));
    }

    render() {
        return (this.getSVG());
    }
}

Svg.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    rotation: PropTypes.number.isRequired
};
export default Svg;


