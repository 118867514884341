import React from 'react';

import StaticImage from '../../image-components/StaticImage.jsx';
import ComposableVideo from './ComposableVideo.jsx';
import { getAltText } from '../../../../utils/StaticAssetManager.js';



export function getComposableModalRender(style, contentArray) {
    let contentRenderArray = [];
    let isImageModal = false;
    for (let i = 0; i < contentArray.length; ++i) {
        const element = contentArray[i];
        switch (element.type) {
            case 'title':
                if (element.props.title)
                    contentRenderArray.push(
                        <div style={style.titleStyle}>
                            <p
                                id="productModalTitle"
                                className="obsessvr-boldFont"
                                style={element.props.style}
                            >
                                {element.props.title}
                            </p>
                        </div>
                    );
                break;
            case 'text':
                if (element.props.text)
                    contentRenderArray.push(
                        <div style={style.textStyle}>
                            <p
                                id="productModalDescription"
                                className="obsessvr-regularFont"
                                style={element.props.style}
                            >
                                {element.props.text}
                            </p>
                        </div>
                    );
                break;
            case 'image':
                const imageSrc = element.props.src;
                isImageModal = true;
                const fileName = getAltText(imageSrc);
                contentRenderArray.push(<StaticImage src={imageSrc} style={style.imageStyle} setImageLoaded={element.props.setImageLoaded} alt={fileName} />);
                break;
            case 'video':
                const videoSrc = element.props.src;
                contentRenderArray.push(<ComposableVideo
                    src={videoSrc}
                    style={style.videoStyle}
                />);
                break;
            default:
                break;
        }
    }

    const contentRender = contentRenderArray.map((element, index) => {
        return <div key={index}>{element}</div>
    });

    return isImageModal ? <div id="imageContentWrapper" className="flex">{contentRender}</div> : contentRender;
}

