import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import BoxCollider from '../../web-store-core/collider/BoxCollider.js';

class InteractableObject extends Component {
    constructor(props) {
        super(props);
        this.updateTransform = this.updateTransform.bind(this);

        this.collider = new BoxCollider(1, 1, 1, props.onHover, props.onUnhover, props.onClick);

        props.colliderManager.addCollider(this.collider);
        this.collider.material.visible = this.props.isColliderVisible;
        props.scene.add(this.collider);
    }

    componentWillUnmount() {
        this.props.colliderManager.removeCollider(this.collider);
        this.props.scene.remove(this.collider);
    }

    updateTransform() {
        this.collider.setTransform(this.props.colliderTransform);
        if (this.props.isLookingCenter) {
            this.collider.lookAt(0,0,0);
        }
    }

    render() {
        this.updateTransform();
        return (
            <React.Fragment />
        );
    }
}

InteractableObject.propTypes = {
    scene: PropTypes.object,
    colliderManager: PropTypes.object,
    colliderTransform: PropTypes.object,
    isColliderVisible: PropTypes.bool,
    isLookingCenter: PropTypes.bool,
    onHover: PropTypes.func,
    onUnhover: PropTypes.func,
    onClick: PropTypes.func,
    onchange: PropTypes.func,
    onKeyDown: PropTypes.func
};

export default props => (
    <ThreeJSWorldContext.Consumer>
        {value => (
            <InteractableObject
                {...props}
                scene={value.scene}
                colliderManager={value.colliderManager}
            />
        )}
    </ThreeJSWorldContext.Consumer>
);
