const SocialEnum = Object.freeze({
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    INSTAGRAM: 'instagram',
    SNAPCHAT: 'snapchat',
    PINTEREST: 'pinterest',
    WHATSAPP:'whatsapp'
});

export default SocialEnum;
