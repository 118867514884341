import React, { Component } from 'react';
import Marker from './Marker.jsx';
import * as THREE from 'three';
import { loadImageAsync } from '../../utils/AssetLoader';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import { getThreeTransformFromArray } from '../../utils/ThreeTransformConverter.js';

class ProductImageMarker extends Component {
  constructor(props) {
    super(props);

    this.scene = this.props.scene;
    this.visualObject = new THREE.Mesh();
    this.transform = getThreeTransformFromArray(props.transform);
  }

  setImage() {
    const { image_url } = this.props.markerProps;

    loadImageAsync(image_url, (texture) => {
        const { image } = texture;
        const width = image.width / image.height;
        const height = 1;

        this.setNewGeometry(width, height, texture);
        this.updateTransform();
    });
  }

  setNewGeometry(width, height, texture) {
    const { scale, renderOrder } = this.props.markerProps;
    const geometry = new THREE.PlaneGeometry(width, height);
    const material = new THREE.MeshBasicMaterial({
        map: texture, transparent: true, depthTest: false,
    });

    this.visualObject.geometry = geometry;
    this.visualObject.material = material;
    this.visualObject.scale.set(scale, scale, scale);
    this.visualObject.renderOrder = renderOrder;
  }

  componentDidMount(){
    this.setImage()
    this.scene.add(this.visualObject);
  }

  componentWillUnmount() {
    this.scene.remove(this.visualObject);
    this.visualObject.material.map.dispose();
    this.visualObject.material.dispose();
    this.visualObject.geometry.dispose();
    this.visualObject = null;
  }

  updateTransform() {
    this.visualObject.matrix = this.transform;
    this.visualObject.matrix.decompose(this.visualObject.position, this.visualObject.quaternion, this.visualObject.scale);
    this.visualObject.lookAt(0, 0, 0);
  }

  render() {
    return ( 
      <div>
        <Marker 
          transform={this.props.transform}
          colliderTransform={this.props.colliderTransform}
        /> 
      </div>
    )
  }
}

export default props => (
  <ThreeJSWorldContext.Consumer>
    {threeJSWorldContextValue => (
      <ProductImageMarker
        {...props}
        scene={threeJSWorldContextValue.scene}
      />
    )}
  </ThreeJSWorldContext.Consumer>
);