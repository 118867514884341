import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { setCursorStyle } from '../../utils/MouseUtilities.js';
import Marker from './Marker.jsx';
import { getIconSVGAsync } from '../ui/ui-layer-panel/SVGIcons.jsx';
import { LinkClicked } from '../../utils/Analytics.js';

const hoverCursorStyle = 'pointer';
const unhoverCursorStyle = 'all-scroll';
const linkMarkerIconUrl = 'https://cdn.obsess-vr.com/link-marker-icon.svg';

class LinkMarker extends Component {
    constructor(props) {
        super(props);
        this.onHover = this.onHover.bind(this);
        this.onUnhover = this.onUnhover.bind(this);
        this.onClick = this.onClick.bind(this);

        this.state = {
            svgSpriteString: ''
        }
    }

    componentDidMount() {
        getIconSVGAsync(null, linkMarkerIconUrl)
            .then(svgString => this.setState({ svgSpriteString: svgString }))
            .catch(error => console.error(error));
    }

    onHover() {
        setCursorStyle(hoverCursorStyle);
    }

    onUnhover() {
        setCursorStyle(unhoverCursorStyle);
    }

    onClick() {
        LinkClicked(this.props.url);
        window.open(this.props.url, '_blank');
    }

    render() {
        const { transform, colliderTransform, showVisual, primaryColor, secondaryColor } = this.props;
        const { svgSpriteString } = this.state;
        return (
            <React.Fragment>
                <Marker
                    transform={transform}
                    colliderTransform={colliderTransform}
                    svgSpriteString={svgSpriteString}
                    showVisual={showVisual}
                    onHover={this.onHover}
                    onUnhover={this.onUnhover}
                    onClick={this.onClick}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                />
            </React.Fragment>
        );
    }
}

LinkMarker.propTypes = {
    transform: PropTypes.array,
    colliderTransform: PropTypes.array,
    url: PropTypes.string,
    showVisual: PropTypes.bool,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string
};

export default LinkMarker;
