import React from 'react';

import './BottomBarSelector.css';
import useBarSelector from './useBarSelector.jsx';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
//import { useTranslation } from 'react-i18next';

const BottomBarSelector = (props) => {
    //const { t, i18n } = useTranslation();
    const [bottomHeight, renderSelectorItems] = useBarSelector(props)

    return (
        <div id='bottom-bar-selector-container' style={{ bottom: isMobileDevice() ? 0 : '0.75em', width: isMobileDevice() ? '100%' : '25em', left: isMobileDevice() ? 0 : '0.75em' }}>
            {renderSelectorItems()}
        </div>
    )
}

export default BottomBarSelector;
