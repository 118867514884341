import React, { Component } from 'react';
import canvg from 'canvg';
import ReactDOMServer from 'react-dom/server';

const lineHeight = 25;
const maxLines = 6;
const maxCharLength = 250;
const minCharsPerLine = 50;

let canvas = null;

export default class TextSvgSprite extends Component {
	constructor(props) {
		super(props);
		this.getSVG = this.getSVG.bind(this);
		this.saveSVG = this.saveSVG.bind(this);
	}

	saveSVG(text, title, titleStyle, textStyle, finished) {
		const svg = this.getPlainSVG(text, title, titleStyle, textStyle);
		const svgString = ReactDOMServer.renderToStaticMarkup(svg);

		if (!canvas)
		{
			canvas = document.createElement("canvas");
		}
		const v = canvg.fromString(
			canvas.getContext('2d'),
			svgString,
			{
				enableRedraw: false,
				ignoreMouse: true,
				ignoreAnimation: true,
				ignoreClear: true
			}
		);
		v.render();
		finished(canvas);
	}

	testWhite(x) {
		var white = new RegExp(/^\s$/);
		return white.test(x.charAt(0));
	}

	wordWrap(str, maxWidth) {
		let done = false;
		var lines = [];
		do {
			let found = false;
			// Inserts new line at first whitespace of the line
			for (var i = maxWidth - 1; i >= 0; i--) {
				if (this.testWhite(str.charAt(i))) {
					let line = str.slice(0, i);
					lines.push(line);

					str = str.slice(i + 1);
					found = true;
					break;
				}
			}
			// Inserts new line at maxWidth position, the word is too long to wrap
			if (!found) {
				str = str.slice(maxWidth);
			}

			if (str.length < maxWidth) {
				done = true;
			}

		} while (!done);

		lines.push(str);

		return lines;
	}

	getPlainSVG(text, title, titleStyle, textStyle) {
		let lines;
		const charsPerLine = Math.max(text.length / (maxLines - 1), minCharsPerLine);

		// Need to update lines and text variables through conditional because ternary operator will break the application
		if (text.length > minCharsPerLine) {
			text = text.slice(0, maxCharLength);
			lines = this.wordWrap(text, charsPerLine);
		} else {
			text = text.split();
			lines = text;
		}

		const titleExists = !!title;
		const bodyText = lines.map((line, index) => {return(<tspan key={index} x={lineHeight} y={index * lineHeight}>{line}</tspan>)});

		/* ASSIGNMENTS
		* boxHeight = height of white background behind the text
		* boxPosition = vertical position of white background behind the text
		* titlePosition & bodyPosition = vertical position of text elements within SVG, calculated to be centerted to arrow pointing to info hotspot, used only in their respective transformation matrices
		* titleMatrix & bodyMatrix = transformation matrix that scales and sets position of text in SVG.
		*/
		const xOffset = 0;

		const boxHeight = lines.length * lineHeight * 2 + 100;
		const boxPosition = 367 - lines.length * lineHeight;
		const titlePosition = 425 - lines.length * lineHeight;
		const titleMatrix = `matrix(2 0 0 2 ${1180 + xOffset * 1.5} ${titlePosition})`;
		const bodyPosition = titleExists ? titlePosition + 50 : titlePosition + 25;
		const bodyMatrix = `matrix(2 0 0 2 ${1120 + xOffset * 1.5} ${bodyPosition})`;

		return (
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x={`${xOffset}px`} y="0px" width={`${1024 + 5*xOffset}px`} height="512px" viewBox={`0 0 1468 836`}>
				<defs>
				</defs>
				<polygon fill="#FFFFFF" stroke="#FFFFFF" points="783,443 762,418 761,418 783,392" />
				<rect x="783" y={boxPosition} fill="#FFFFFF" stroke="#FFFFFF" width="878" height={boxHeight} />
				{titleExists && <text transform={titleMatrix} textAnchor="middle" style={titleStyle} font={titleStyle.fontFamily} >{title}</text>}
				<text transform={bodyMatrix} textAnchor="middle" style={textStyle} >{bodyText}</text>
			</svg>
		);
	}

	getSVG() {
		return (this.getPlainSVG());
	}

	render() {
		return (
			this.getSVG()
		);
	}
}
