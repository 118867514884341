import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ProductModal } from 'web-store-modules/packages/product-modal';
import { Modal } from 'web-store-modules/packages/web-store-ui-library'
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector'

import { ProductModalContext } from '../ProductModalContext';
import TemplateProductHeader from './TemplateProductHeader';
import TemplateProductBody from './TemplateProductBody';
import TemplateProductBuy from './TemplateProductBuy';

import './TemplateProductModal.css';

const variables = require('../../../../../../../variables/Variables.js');

const { apiUrl, reactRoot, s3Bucket } = variables;
const isMobile = isMobileDevice();

const TemplateProductModal = ({ sku, onClose }) => {
    const { expandedProduct, setModalOpened, onColorSelectorLoaded, fetchProductInfo, modalOpened, productInfo, thumbnailUrls, isLoading, fontStyles, modalStylingFields, productPricePrefix, groupProducts, primaryColor, secondaryColor, backgroundColor, colorSelectorReady } = useContext(ProductModalContext);
    const [thumbnailUrl, setThumbnailUrl] = useState();
    const [spinUrls, setSpinUrls] = useState();
    const [hiResSpinUrls, setHiResSpinUrls] = useState();
    const [modalImageType, setModalImageType] = useState();

    const productSKU = productInfo[ProductModal.ContentTypes.SKU] || sku;

    useEffect(() => {
        if (!expandedProduct) {
            const thumbnail = thumbnailUrls && thumbnailUrls.length >= 1 && thumbnailUrls[0];
            if (thumbnail) {
                if (thumbnail['type'] === 'SPIN') {
                    setSpinUrls(thumbnail['spinUrls']);
                    setHiResSpinUrls(thumbnail['hiResSpinUrls']);
                }
                setThumbnailUrl(thumbnail['thumbnailUrl']);
                setModalImageType(thumbnail['type']);
            }
        }
    }, [expandedProduct, thumbnailUrls]);

    const onModalOpened = () => {
        setModalOpened(true);
    }
    
    const header = (
        <TemplateProductHeader
            productInfo={productInfo}
            fontStyles={fontStyles}
            showStylingFields={modalStylingFields}
            productPricePrefix={productPricePrefix}
            sku={productSKU}
            groupProducts={groupProducts}
            fetchProductInfo={fetchProductInfo}
            onColorSelectorLoaded={onColorSelectorLoaded}
        />
    )
    const body = (
        <TemplateProductBody
            sku={productSKU}
            title={productInfo[ProductModal.ContentTypes.TITLE]}
            type={modalImageType}
            thumbnailUrl={thumbnailUrl}
            spinUrls={spinUrls}
            hiResSpinUrls={hiResSpinUrls}
            modalOpened={modalOpened}
            colorSelectorReady={colorSelectorReady}
        />
    );

    const buy = (
        <TemplateProductBuy
            buyNowFontStyle={fontStyles && fontStyles.buttonTextFontStyle}
            addToCartFontStyle={fontStyles && fontStyles.addToCartTextFontStyle}
            productButtonCopy={modalStylingFields && modalStylingFields.productButtonCopy}
            sku={productSKU}
            title={productInfo[ProductModal.ContentTypes.TITLE]}
            url={productInfo[ProductModal.ContentTypes.URL]}
            showAddToCart={modalStylingFields && modalStylingFields.showAddToCart}
            addToCartUrl={productInfo[ProductModal.ContentTypes.ADD_TO_CART_URL]}
            addToCartCopy={modalStylingFields && modalStylingFields.addToCartCopy}
        />

    );

    const extraProps = {
        height: `${document.getElementById(reactRoot).offsetHeight * .80}px`,
        width: isMobile ? '80vw' : '35em',
        backgroundColor
    };

    return (
            <ProductModal
                animationType={Modal.modalPropTypes.animationType.FLYUP}
                closeIconStyle={{
                    width: '2.8em',
                    float: 'right',
                    backgroundColor: primaryColor,
                    borderRadius: '50%'
                }}
                onClose={onClose}
                header={header}
                body={body}
                buy={buy}
                isLoading={isLoading}
                bodyStyle={{ padding: '0 1em' }}
                onOpened={onModalOpened}
                {...extraProps}
            />
        )
}

TemplateProductModal.propTypes = {
    sku: PropTypes.string,
    onClose: PropTypes.func,
    specialUrl: PropTypes.string,
    onProductTitleLoaded: PropTypes.func
};

export default TemplateProductModal