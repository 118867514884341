import React from 'react';
import { constructUrl } from '../../../utils/UrlConstructor';

import './CartItem.css'

const CartItem = ({ name, price, thumbnail, menuTextFontStyle, prefix, removeFromCart }) => {
    return (
        <React.Fragment>
            <div className='cart-item-container'>
                {thumbnail && <img className='cart-item-thumbnail' src={constructUrl(thumbnail)} />}
                {!thumbnail && <div className='cart-item-thumbnail'>&nbsp;</div>}
                <div className='cart-item-info' style={menuTextFontStyle}>
                    <span>{name}</span>
                    <span>{`${prefix}${price}.00`}</span>
                </div>
            </div>
            <div className='cart-item-remove' onClick={() => removeFromCart(name)}>Remove</div>
            <hr className="cart-item-break" />
        </React.Fragment>
    )
}

export default CartItem;
