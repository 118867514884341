import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LoadingIcon2 } from 'web-store-modules/packages/web-store-ui-library';
import { loadUIImageAsync } from '../../../utils/AssetLoader.js';

class StaticImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        loadUIImageAsync(this.props.src)
            .then((image) => {
                this.setState({
                    src: image.src,
                    isLoading: false
                });
                this.props.setImageLoaded(true);
            })
            .catch((error) => {
                // TODO: retry logic
                console.error('Download image error', error);
            });
    }

    render() {
        if (this.state.isLoading) {
            return (<LoadingIcon2 />);
        }
        const { src, style, onImageLoaded, alt } = this.props;
        return (
            <img
                src={src}
                style={style}
                onLoad={onImageLoaded || null}
                alt={alt}
            />
        );
    }
}

StaticImage.propTypes = {
    src: PropTypes.string,
    style: PropTypes.object
};

export default StaticImage;
