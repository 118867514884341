import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGImage from '../image-components/SVGImage.jsx';
import InteractableElement from '../InteractableElement.jsx';

class SocialButton extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { onClickAnalytics, redirectUrl } = this.props;
        if (onClickAnalytics) {
            onClickAnalytics();
        }
        window.open(redirectUrl, '_blank');
    }

    render() {
        const { imageSrc, color } = this.props;
        return (
            <span className='hoverable' style={this.props.style} onClick={this.onClick}>
                <SVGImage
                    className="inline"
                    src={imageSrc}
                    color={color}
                    width={28}
                    height={28}
                    viewbox='0 0 500 500'
                />
            </span>
        );
    }
}

SocialButton.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    style: Proptypes.object,
    redirectUrl: PropTypes.string.isRequired,
    imageColor: PropTypes.string,
    onClickAnalytics: PropTypes.func
};

export default SocialButton;
