import React from 'react';
import './ScreenshotButton.css'

const INSTAGRAM_TRANSPARENT_ICON = 'https://cdn.obsess-vr.com/instagram-transparent-icon.png';
const SNAPCHAT_TRANSPARENT_ICON = 'https://cdn.obsess-vr.com/snapchat-transparent-icon.png';

const ScreenshotButton = ({ activateScreenshot }) => {
    return (
        <div id='screenshot-button-container' onClick={activateScreenshot}>
            <img className='screenshot-button-icon' src={INSTAGRAM_TRANSPARENT_ICON}/>
            <img className='screenshot-button-icon' src={SNAPCHAT_TRANSPARENT_ICON}/>
            <span id='screenshot-button-text'>Screenshot</span>
        </div>
    )
}

export default ScreenshotButton;