import React from 'react';

const CartDropdownFooterText = ({wishlistFontStyle}) => {
    const text = wishlistFontStyle.text;
    const cssStyles = {
        ...wishlistFontStyle, 
        padding: '0px', 
        margin: '10px',
        textAlign: 'center'
    };
    return (
        
            <div className='obsessvr-regularFont' style={cssStyles}>
                {text}
            </div>
    )
}

export default CartDropdownFooterText;
