import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Social from './Social';
import StoreSelector from './store-selector/StoreSelector';
import AddToCart from './add-to-cart/AddToCart';

import './TopBarRight.css';

class TopBarRight extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sceneSelectorActive: false,
            cartActive: false
        };
    }

    showSceneSelector = (type) => {
        this.setState({ sceneSelectorActive: type })
    }

    showCartActive = (type) => {
        this.setState({ cartActive: type })
    }


    render() {
        const { showStoreSelector, moveTopRightBarToBottomLeft } = this.props;
        const { sceneSelectorActive, cartActive } = this.state;
        let topBarRightRender = (<React.Fragment />);
        if (showStoreSelector) {
            topBarRightRender = (
                <StoreSelector sceneSelectorActive={sceneSelectorActive} cartActive={cartActive}
                    showCartActive={this.showCartActive}
                    showSceneSelector={this.showSceneSelector} />
            );
        } /* else {
            topBarRightRender = (
                <Social socialReversed={true} />
            );
        } */
        const id = moveTopRightBarToBottomLeft ? 'topBarRightAtBottomLeft' : 'topBarRight';
        return (
            <div id={id}>
                <div className="flex-left">
                    {topBarRightRender}
                    <AddToCart
                        sceneSelectorActive={sceneSelectorActive}
                        cartActive={cartActive}
                        showCartActive={this.showCartActive}
                        showSceneSelector={this.showSceneSelector}
                    />
                </div>
            </div>
        );
    }
}

TopBarRight.propTypes = {
    showStoreSelector: PropTypes.bool,
    moveTopRightBarToBottomLeft: PropTypes.bool
};

export default TopBarRight;
