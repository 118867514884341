/*
    Renders basic shared UI, such as the top right bar or bottom left bar, as well as initial load UI like welcome scene
    and pop up, and transition into first scene. Global UI that persists across rooms such probably be placed here
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Welcome from './welcome/Welcome.jsx';
import PromoPage from './welcome/PromoPage.jsx';
import Logo from './Logo.jsx';
import TopBarRight from './TopBarRight.jsx';
import BottomBarRight from './BottomBarRight.jsx';
import BottomBarLeft from './BottomBarLeft.jsx';
import AccessibilityModal from './modals/accessibility/AccessibilityModal.jsx';
import {
    getEntranceAutoTransitionAysnc,
    getSceneIds,
    getAllSceneInfoDictAsync,
    getStoreSelectorExistAsync,
    getStoreId,
    getCurrentLiveUserCountAsync
} from '../../utils/StoreConfigManager.js';
import { getAutoRotate, getTopRightBarToBottomLeftBar } from '../../utils/EmbedVariable';
import { isMobileDevice } from '../../utils/DeviceDetector';

import { AppContext } from '../../AppContext.jsx';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import { FlatImageSceneContext } from '../flat-image-scene/FlatImageSceneContextComponent'
import './TopUILayer.css';
import { EntranceAnimationContext } from '../entrance-animation/EntranceAnimationContextComponent';

const autoRotate = getAutoRotate();
const moveTopRightBarToBottomLeft = getTopRightBarToBottomLeftBar();
const isMobile = isMobileDevice();
const updateUserCountInterval = 10000;

/*
    Renders basic shared UI, such as the top right bar or bottom left bar, as well as initial load UI like welcome scene
    and pop up, and transition into first scene. Global UI that persists across rooms should probably be placed here
 */

class TopUILayer extends Component {
    constructor(props) {
        super(props);
        this.hideUserPanel = this.hideUserPanel.bind(this);
        this.onWelcomeHide = this.onWelcomeHide.bind(this);
        this.toggleTipPanel = this.toggleTipPanel.bind(this);
        this.initStoreScene = this.initStoreScene.bind(this);
        this.toggleUserPanel = this.toggleUserPanel.bind(this);
        this.setShowAccessibilityModal = this.setShowAccessibilityModal.bind(this);
        this.onAccessibilityModalHidden = this.onAccessibilityModalHidden.bind(this);

        this.state = {
            showingWelcome: this.initStoreScene(),
            hasStoreSelector: false,
            showingTipsMenu: false,
            sharePanelOpen: false,
            userPanelOpen: false,
            userCount: null,
            showAccessibilityModal: false
        };

        this.autoTranstionToSceneIndex = 0;
    }

    componentDidMount() {
        getEntranceAutoTransitionAysnc()
            .then(autoTransition => {
                this.autoTranstionEntrance = autoTransition
            })
            .catch(error => console.error(error));

        getStoreSelectorExistAsync()
            .then(hasStoreSelector => this.setState({ hasStoreSelector: hasStoreSelector }))
            .catch(error => console.error(error));

        // this.getUserCount()
        // setInterval(this.getUserCount, updateUserCountInterval)

        if (this.props.setRoomObjectVisibility) {
            this.props.setRoomObjectVisibility(true);
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (prevProps.showingUI !== this.props.showingUI) {
    //         this.props.setLoadingIconVisibility(false);
    //     }
    // }


    initStoreScene() {
        const dragTooltipEncountered = sessionStorage.getItem('dragTooltipEncountered')
        // If drag tooltip has been shown this session, show the tooltip
        if (!dragTooltipEncountered) {
            return true;
        }

        this.props.setRoomObjectVisibility(true);
        return false;
    }

    onWelcomeHide() {
        this.setState({ showingWelcome: false });

        if (this.autoTranstionEntrance > 0) {
            this.props.setLoadingIconVisibility(true);
            let linkedRoomId = '';
            getSceneIds()
                .then(sceneIds => {
                    linkedRoomId = sceneIds[1];
                    return getAllSceneInfoDictAsync();
                })
                .then(sceneInfoDict => {
                    const sceneInfo = sceneInfoDict[linkedRoomId];
                    const linkedRoomName = sceneInfo['name'];
                    const targetPath = '/' + linkedRoomName;
                    this.props.history.push(targetPath);
                })
                .catch(error => console.error(error));
        }
    }

    toggleTipPanel(isShowing) {
        this.setState({
            showingTipsMenu: isShowing
        });
    }

    toggleUserPanel() {
        this.setState({
            sharePanelOpen: false,
            userPanelOpen: !this.state.userPanelOpen
        });
    }

    hideUserPanel() {
        this.setState({
            userPanelOpen: false
        })
    }

    setShowAccessibilityModal() {
        this.setState({
            showAccessibilityModal: !this.state.showAccessibilityModal
        })
    }

    onAccessibilityModalHidden() {
        if (!this.mounted) {
            return ;
        }
        this.setState({ showAccessibilityModal: false })
    }

    // GETs current active user count in mall. 
    // Currently set to be fired off every 10 seconds.
    // While using the same endpoint as mall, payload is different, location is set to storeId instead.
    // getUserCount() {
    //     getCurrentLiveUserCountAsync(getStoreId())
    //         .then(userCount => {
    //             this.setState({ userCount })
    //         })
    //         .catch(err => console.error(err));
    // }

    getFlatImageSceneWelcomeComponent() {
        const { isFlatImageScene, flatImageButtonOption, flatImageButtonText } = this.props.flatImageSceneContextValue;
        return <Welcome
            showingUI={true}
            onWelcomeHide={this.onWelcomeHide}
            isFlatImageScene={isFlatImageScene}
            flatImageButtonOption={flatImageButtonOption}
            flatImageButtonText={flatImageButtonText}
        />
    }

    getWelcomeComponent() {
        const { showingUI } = this.props;
        const { showingWelcome } = this.state;
        const { isFlatImageScene } = this.props.flatImageSceneContextValue;

        if (isFlatImageScene) {
            return this.getFlatImageSceneWelcomeComponent();
        }

        if (showingWelcome) {
            if (autoRotate) {
                return <PromoPage
                    onWelcomeHide={this.onWelcomeHide}
                />
            }
            return <Welcome
                showingUI={showingUI}
                onWelcomeHide={this.onWelcomeHide}
            />
        }
    }

    getBottomBarLeft() {
        if (this.state.showingTipsMenu && isMobile) {
            return <React.Fragment />;
        }
        return <BottomBarLeft
            showSocial={this.state.hasStoreSelector}
            toggleUserPanel={this.toggleUserPanel}
            userPanel={this.state.userPanelOpen}
            userCount={this.state.userCount}
            isMobile={isMobile}
            hideUserPanel={this.hideUserPanel}
        />;
    }

    getLogo() {
        return this.props.hideLogo ? <React.Fragment /> : <Logo />
    }

    getTopBarRight() {
        return <TopBarRight showStoreSelector={this.state.hasStoreSelector}
            moveTopRightBarToBottomLeft={moveTopRightBarToBottomLeft} />;
    }

    getTopUILayer() {
        return (
            <div id="top-layer-ui-container" >
                {this.getWelcomeComponent()}
                {this.getLogo()}
                {this.getTopBarRight()}
                <BottomBarRight toggleTipPanel={this.toggleTipPanel} setShowAccessibilityModal={this.setShowAccessibilityModal} />
                {this.getBottomBarLeft()}
                {this.state.showAccessibilityModal && <AccessibilityModal onAccessibilityModalHidden={this.onAccessibilityModalHidden} />}
            </div>
        )
    }

    render() {
        if (!this.props.showingUI || (!this.props.isAnimationVideoEnd && this.props.isEntranceAnimationScene)) {
            return <React.Fragment />;
        }
        return this.getTopUILayer();
    }
}

TopUILayer.propTypes = {
    history: PropTypes.object,
    hideLogo: PropTypes.bool,
    showingUI: PropTypes.bool,
    setRoomObjectVisibility: PropTypes.func,
    setLoadingIconVisibility: PropTypes.func,
    threeJSWorldContext: PropTypes.object
};

export default props => (
    <AppContext.Consumer>
        {appContextValue => (
            <ThreeJSWorldContext.Consumer>
                {threeJSWorldContextValue => (
                    <FlatImageSceneContext.Consumer>
                        {flatImageSceneContextValue => (
                            <EntranceAnimationContext.Consumer>
                               {entranceAnimationContext => (
                                <TopUILayer
                                    {...props}
                                    history={threeJSWorldContextValue.history}
                                    setLoadingIconVisibility={appContextValue.setLoadingIconVisibility}
                                    threeJSWorldContext={threeJSWorldContextValue}
                                    primaryColor={appContextValue.primaryColor}
                                    flatImageSceneContextValue={flatImageSceneContextValue}
                                    isAnimationVideoEnd={entranceAnimationContext.isAnimationVideoEnd}
                                    isEntranceAnimationScene={entranceAnimationContext.isEntranceAnimationScene}
    
                                />
                                )}
                            </EntranceAnimationContext.Consumer>        
                        )}
                    </FlatImageSceneContext.Consumer>
                )}
            </ThreeJSWorldContext.Consumer>
        )}
    </AppContext.Consumer>
);