import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';

import { RoomContext } from '../room/RoomContext';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import { loadImageAsync } from '../../utils/AssetLoader';
import { createImageFromSvgString } from '../ui/ui-layer-panel/SVGIcons.jsx';

class HotspotVisual extends Component {
    constructor(props) {
        super(props);
        this.updateTransform = this.updateTransform.bind(this);
        this.loadSpriteImage = this.loadSpriteImage.bind(this);
        this.loadSvgImage = this.loadSvgImage.bind(this);

        // FIXES TextBox clipping issue
        // If it affects other hotspot types, it should be passed as prop
        let spriteMaterial = new THREE.SpriteMaterial({
            depthWrite: true,
            depthTest: false
        });
        this.sprite = new THREE.Sprite(spriteMaterial);
        // this.sprite.renderOrder = 100;
        this.props.scene.add(this.sprite);
        let spriteScale = this.props.spriteScale;
        this.sprite.scale.set(spriteScale.x, spriteScale.y, spriteScale.z);
        this.sprite.visible = false;
        this.sprite.hovered = false;

        // We use this to differentiate between hover/unhover and loading for the first time
        // Hover/unhover needs to be rendered right away, while loading for the first time can wait a bit to render.
        this.loadedOnce = false;
    }

    componentWillUnmount() {
        this.props.scene.remove(this.sprite)
    }

    updateTransform() {
        this.sprite.matrix = this.props.transform;
        this.sprite.matrix.decompose(this.sprite.position, this.sprite.quaternion, this.sprite.scale);
    }

    loadSvgImage(source) {
        if (source) {
            this.visualSource = new source();
            const color = this.props.isHovered || this.props.isActive ? this.props.secondaryColor : this.props.primaryColor;
            const rotation = this.props.rotationAngle;
            const opacity = this.props.fade ? 0.4 : 1.0;
            // set sprite
            this.visualSource.saveSVG(
                color,
                (canvas) => {
                    const img = canvas.toDataURL('image/png');
                    this.loadSpriteImage(img);
                },
                rotation,
                opacity
            );
        }
    }

    loadSpriteImage(url) {
        if (url) {
            loadImageAsync(url,
                (image) => {
                    this.sprite.material.map = image;
                    this.sprite.material.needsUpdate = true;
                    this.sprite.visible = true;

                    if (this.loadedOnce) {
                        this.props.requestImmediate3DSceneRender();
                    } else {
                        this.props.request3DSceneRender();
                        this.loadedOnce = true;
                    }
                },
                null,
                (error) => {
                    console.log("error: " + error + ", loading image with url: " + url);
                },
                this.props.roomId
            );
        }
    }

    loadArrowLabel(svgSprite) {
        if (svgSprite) {
            this.sprite.material.map = svgSprite;
            this.sprite.material.needsUpdate = true;
            this.sprite.visible = true;

            if (this.loadedOnce) {
                this.props.requestImmediate3DSceneRender();
            } else {
                this.props.request3DSceneRender();
                this.loadedOnce = true;
            }
        }
    }

    render() {
        const { fade, svgSprite, svgSpriteString, isHovered, isActive, hoveredSpriteUrl, normalSpriteUrl, primaryColor, secondaryColor, isLabel  } = this.props;

        this.updateTransform();

        const opacity = fade ? 0.4 : 1.0;
        if (isLabel) {
            this.loadArrowLabel(svgSprite)
        } else if (svgSprite) {
            this.loadSvgImage(svgSprite);
        } else if (svgSpriteString) {
            const color = isHovered || isActive ? secondaryColor : primaryColor;
            const coloredSvgSpriteString = svgSpriteString.replace(new RegExp("fill=\"white\"", "g"), 'fill=\"' + color + '\" opacity=\"' + opacity + '\"');
            createImageFromSvgString(coloredSvgSpriteString, canvas => {
                const img = canvas.toDataURL('image/png');
                const url = isHovered  || isActive ? img : img;
                this.loadSpriteImage(url);
            });
        } else {
            const url = isHovered  || isActive ? hoveredSpriteUrl : normalSpriteUrl;
            this.loadSpriteImage(url);
        }

        return null;
    }

}

HotspotVisual.propTypes = {
    roomId: PropTypes.string,
    scene: PropTypes.object,
    transform: PropTypes.object,
    isHovered: PropTypes.bool,
    spriteScale: PropTypes.object,
    normalSpriteUrl: PropTypes.string,
    hoveredSpriteUrl: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    rotationAngle: PropTypes.number,
    svgSprite: PropTypes.func,
    svgSpriteString: PropTypes.string,
    fade: PropTypes.bool,
    request3DSceneRender: PropTypes.func,
    requestImmediate3DSceneRender: PropTypes.func,
    isLabel: PropTypes.bool
};

export default props => (
    <ThreeJSWorldContext.Consumer>
        {threeJSWorldContextValue => (
            <RoomContext.Consumer>
                {roomContextValue => (
                    <HotspotVisual
                        {...props}
                        roomId={roomContextValue.roomId}
                        scene={threeJSWorldContextValue.scene}
                        request3DSceneRender={threeJSWorldContextValue.request3DSceneRender}
                        requestImmediate3DSceneRender={threeJSWorldContextValue.requestImmediate3DSceneRender}
                    />
                )}
            </RoomContext.Consumer>
        )}
    </ThreeJSWorldContext.Consumer>
);
