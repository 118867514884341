const styles = {
    'EDITORIAL': {
        containerStyle: {
            textAlign: 'center',
            display: 'block'
        },
        titleStyle: {
            padding: '0.5em'
        },
        textStyle: {
            padding: '0.5em'
        },
        imageStyle: {
            width: 'fit-content',
            maxWidth: '100%',
            maxHeight: '86vh',
            margin: '0 auto',
            display: 'block',
        }
    },
    'VIDEO': {
        containerStyle: {
            textAlign: 'center',
            display: 'block'
        },
        videoStyle: {
            // width: '100%',
            maxWidth: '100%',
            maxHeight: '80vh',
            display: 'block',
            border: 'none',
            margin: '0 auto'
        }
    }
};

export function getStyleWithType(type) {
    return styles[type]
}
