import React, { useState, useEffect } from 'react';
import { constructUrl } from '../../../utils/UrlConstructor';
import './SharePanel.css';
import ScreenshotButton from './ScreenshotButton';
import SharePanelSocialIcon from './SharePanelSocialIcon';
import { getShareOptions, getInstagramLink } from './SharePanelUtils.js';
import { getStoreNameAsync, getStyleColorAsync } from '../../../utils/StoreConfigManager';
import { getAllDescendantIDs } from '../share-panel/SharePanelUtils';

const SharePanel = ({ showScreenshot, setShowScreenshot, showSharePanel, setShowSharePanel, isMobile, hideUserPanel,sharePanelData }) => {
    const [storeName, setStoreName] = useState();
    const [primaryColor, setPrimaryColor] = useState("white");

    useEffect(() => {
        const fetchData = async () => {
            setStoreName(await getStoreNameAsync());
        }
        fetchData();

        getStyleColorAsync()
            .then(colors => setPrimaryColor(colors[0]))
            .catch(error => console.error(error))

    });

    // listner will be created on component mount and will be removed when component unmounts.
    useEffect(() => {
        let currentEvent = e => hideSharePanel(e)

        if (showSharePanel) {
            if (isMobile) {
                document.addEventListener('touchend', currentEvent);
            } else {
                document.addEventListener('click', currentEvent);
            }
            hideUserPanel();
        }

        return () => {
            if (isMobile) {
                document.removeEventListener('touchend', currentEvent);
            } else {
                document.removeEventListener('click', currentEvent);
            }
        }

    }, [showSharePanel])

    const hideSharePanel = (e) => {
        const sharePanelElement = document.getElementById('share-panel-container');
        if (!sharePanelElement) {
            return;
        }
        const sharePanelChildrenIDs = getAllDescendantIDs(sharePanelElement);
        const IDs = new Set(['share-panel-container', ...sharePanelChildrenIDs]);

        if (IDs.has(e.target.id) && e.target.id !== "") {
            return
        }

        setShowSharePanel(false);
    }

    const shareOptions = getShareOptions(window.location.origin, storeName, isMobile);

    const sharePanelSocialButtons = shareOptions.map((option, index) => {
        if( sharePanelData[option.key].enable === true){
        return (<SharePanelSocialIcon
            key={index}
            name={option.analyticsName}
            iconSrc={option.icon}
            redirectUrl={option.url + (option.body ? option.body : '')}
            textAbove={option.textAbove}
            targetSelf={option.targetSelf}
            primaryColor={primaryColor}
            showScreenshot={option.analyticsName === 'Instagram' ? showScreenshot && isMobile : null}
            setShowScreenshot={option.analyticsName === 'Instagram' ? setShowScreenshot : null}
        />)
        }
    });

    //IG Handle is currently set to storeName but storeName may not be the actual IG Handle
    const instagramLink = getInstagramLink(storeName);

    return (
        <div id='share-panel-container' className='flex-center'>
            <div id='share-panel-store-name'>{storeName}</div>
            <div id='title-separation-line' />
            <div id='share-panel-social-icon-container'>
                {sharePanelSocialButtons}
            </div>
            <a id='share-panel-tag' href={instagramLink}>@ {storeName}</a>
            <div id='share-panel-triangle-connector' />
        </div>
    )
}

export default SharePanel;
