import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FlyUpModal from '../FlyUpModal.jsx';
import { getStyleWithType } from './ComposableElementStyles.js';
import { getComposableModalRender } from './ComposableModalRenderCreator.js';

import './ComposableModal.css';
import { constructUrl } from '../../../../utils/UrlConstructor.js';
import { getFontStylesAsync } from '../../../../utils/StoreConfigManager.js';
import { ImageClicked, PopupVideoClicked } from '../../../../utils/Analytics.js';

const HotspotTypeComposeTypeMap = Object.freeze({
    'image': 'EDITORIAL',
    'video': 'VIDEO'
});

class ComposableModal extends Component {
    constructor(props) {
        super(props);
        this.getContentRender = this.getContentRender.bind(this);
        this.setImageLoaded = this.setImageLoaded.bind(this);
        this.state = {
            contentRender: null,
            isImageLoaded: false
        };
        this.composeStyle = getStyleWithType(HotspotTypeComposeTypeMap[props.type]);
    }

    componentDidMount() {
        getFontStylesAsync()
            .then(fontStyles => {
                const popUpTitleFontStyle = {
                    fontFamily: fontStyles.popUpTitle.name,
                    fontSize: fontStyles.popUpTitle.size
                };
                const popUpTextFontStyle = {
                    fontFamily: fontStyles.popUpText.name,
                    fontSize: fontStyles.popUpText.size
                };
                const contentRender = this.getContentRender(popUpTitleFontStyle, popUpTextFontStyle);
                this.setState({ contentRender });
            })
            .catch(error => console.error(error));
    }

    getContentRender(popUpTitleFontStyle, popUpTextFontStyle) {
        const modalContents = this.props.modalContent;  // Every DB field in the hotspot's "props" object
        let contents = [];
        switch (this.props.type) {
            case 'image':
                const title = modalContents['title'];
                const description = modalContents['description'];
                const titleContent = {
                    type: 'title',
                    props: {
                        title: title,
                        style: popUpTitleFontStyle
                    }
                };
                const descriptionContent = {
                    type: 'text',
                    props: {
                        text: description,
                        style: popUpTextFontStyle
                    }
                };
                const imageUrlObject = modalContents['url'];
                const imageUrl = constructUrl(imageUrlObject);
                const imageContent = {
                    type: 'image',
                    props: {
                        src: imageUrl,
                        setImageLoaded: this.setImageLoaded
                    }
                };
                const imageSplitUrl = imageUrl.split('/');
                const imageName = imageSplitUrl[imageSplitUrl.length - 1];
                ImageClicked(imageName);
                [titleContent, imageContent, descriptionContent].forEach(element => contents.push(element));
                break;
            case 'video':
                const videoUrlObject = modalContents['url'];
                const videoUrl = constructUrl(videoUrlObject);
                const videoSplitUrl = videoUrl.split('/');
                const videoName = videoSplitUrl[videoSplitUrl.length - 1];
                PopupVideoClicked(videoName);
                this.setImageLoaded(true);
                const videoContent = {
                    type: 'video',
                    props: {
                        src: videoUrl
                    }
                };
                contents = [videoContent];
                break;
            default:
                break;
        }
        const contentRender = getComposableModalRender(this.composeStyle, contents);
        return contentRender;
    }

    setImageLoaded(type) {
        this.setState({ isImageLoaded: type })
    }

    render() {
        //opacity is currently on image hotspot types only
        return (
            <FlyUpModal extraClassName='composableModalWrapper' onModalHidden={this.props.onModalHidden} isOpaque={this.props.type === 'image'} setImageLoaded={this.setImageLoaded} isImageLoaded={this.state.isImageLoaded}>
                <div style={this.composeStyle.containerStyle}>
                    {this.state.contentRender}
                </div>
            </FlyUpModal>
        );
    }
}

ComposableModal.propTypes = {
    type: PropTypes.string.isRequired,
    modalContent: PropTypes.object.isRequired,
    onModalHidden: PropTypes.func,
};

export default ComposableModal;
