import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { constructUrl } from '../../utils/UrlConstructor.js';
import { HotspotMarkerType } from '../../utils/RoomConstructor.js';
import ProductMarker from './ProductMarker.jsx';
import SceneVideoMarker from './SceneVideoMarker.jsx';
import TextBoxHoverMarker from './TextBoxHoverMarker.jsx';
import LinkMarker from './LinkMarker.jsx';
import ModalMarker from './ModalMarker.jsx';
import HeartMarker from './HeartMarker.jsx';
import AnimatedMarker from './AnimatedMarker.jsx';
import ProductImageMarker from './ProductImageMarker.jsx';
import SoundMarker from './SoundMarker.jsx';
import { getAltText } from '../../../store-components/utils/StaticAssetManager'

class HotspotMarker extends PureComponent {

    getProductMarker() {
        const { transform, colliderTransform, markerProps } = this.props;
        return (
            <ProductMarker
                transform={transform}
                colliderTransform={colliderTransform}
                productSKU={markerProps['product_sku']}
                showVisual={markerProps['show_icon']}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        );
    }

    getProductImageMarker() {
        const { transform, colliderTransform, markerProps } = this.props;

        return (
            <ProductImageMarker
                transform={transform}
                colliderTransform={colliderTransform}
                markerProps={markerProps}
            />
        );
    }

    getTextBoxHoverMarker() {
        const { transform, colliderTransform, markerProps } = this.props;
        return (
            <TextBoxHoverMarker
                transform={transform}
                colliderTransform={colliderTransform}
                text={markerProps['text']}
                title={markerProps['title']}
                showVisual={markerProps['show_icon']}
                width={512}
                height={512}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        );
    }

    getSceneVideoMarker() {
        const { transform, colliderTransform, markerProps } = this.props;
        // TODO: Figure out a better way to do url constructions.
        // TODO: Given how our data is structured in db currently,
        // TODO: performance will take a very big hit if we construct
        // TODO: url on the backend (with large amount of product data or room object data)
        // TODO: maybe consider changing the our data structure.

        const sceneVideoUrlObject = markerProps['url'];
        const sceneVideoUrl = constructUrl(sceneVideoUrlObject);
        return (
            <SceneVideoMarker
                transform={transform}
                colliderTransform={colliderTransform}
                url={sceneVideoUrl}
                width={markerProps['width']}
                height={markerProps['height']}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        );
    }

    getLinkMarker() {
        const { transform, colliderTransform, markerProps } = this.props;
        const linkMarkerUrlObject = markerProps['url'];
        const linkMarkerUrl = constructUrl(linkMarkerUrlObject);
        return (
            <LinkMarker
                transform={transform}
                colliderTransform={colliderTransform}
                url={linkMarkerUrl}
                showVisual={markerProps['show_icon']}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        );
    }

    getModalMarker() {
        const { transform, colliderTransform, markerProps } = this.props;
        const hotSpotType = markerProps['hotspot_type'];
        const fileName = markerProps?.url?.path ? getAltText(markerProps.url.path) : '';

        return (
            <ModalMarker
                transform={transform}
                colliderTransform={colliderTransform}
                type={hotSpotType}
                modalContent={markerProps}
                showVisual={markerProps['show_icon']}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
                fileName={fileName}
            />
        );
    }

    getHeartMarker() {
        const { transform, colliderTransform, markerProps } = this.props;
        const heartSKU = markerProps['product_sku'];
        if (heartSKU) {
            return (
                <HeartMarker
                    transform={transform}
                    colliderTransform={colliderTransform}
                    productSKU={heartSKU}
                />
            )
        }
        return (
            <React.Fragment/>
        );
    }

    getAnimatedMarker(){
      const { transform, colliderTransform, markerProps } = this.props;
      const hotSpotType = markerProps['hotspot_type'];
      return(
        <AnimatedMarker
            transform={transform}
            colliderTransform={colliderTransform}
            type={hotSpotType}
            modalContent={markerProps}
        />
      )
    }

    getSoundMarker = () => {
        const { transform, colliderTransform, markerProps } = this.props;
        return (
            <SoundMarker
                transform={transform}
                colliderTransform={colliderTransform}
                modalContent={markerProps}
                showVisual={markerProps['show_icon']}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
            />
        )
    }

    renderHotspot() {
        switch (this.props.type) {
            case HotspotMarkerType.ProductMarker:
                return this.getProductMarker()
            case HotspotMarkerType.ProductImageMarker:
                return this.getProductImageMarker();
            case HotspotMarkerType.TextBoxHoverMarker:
                return this.getTextBoxHoverMarker()
            case HotspotMarkerType.SceneVideoMarker:
                return this.getSceneVideoMarker()
            case HotspotMarkerType.LinkMarker:
                return this.getLinkMarker();
            case HotspotMarkerType.ModalMarker:
                return this.getModalMarker()
            case HotspotMarkerType.HeartMarker:
                return this.getHeartMarker()
            case HotspotMarkerType.AnimatedMarker:
                  return this.getAnimatedMarker()
            case HotspotMarkerType.SoundMarker:
                return this.getSoundMarker();
            default:
                console.error('Hotspot type of ' + this.props.markerProps['hotspot_type'] + ' do not exist!');
                return null;
        }
    }

    render() {
        return (
            <div>
                {this.renderHotspot()}
            </div>
        );
    }
}

HotspotMarker.propTypes = {
    type: PropTypes.string.isRequired,
    transform: PropTypes.array.isRequired,
    colliderTransform: PropTypes.array.isRequired,
    markerProps: PropTypes.object,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired
};

export default HotspotMarker;
