import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';

import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import Marker from './Marker.jsx';
import { AppContext } from '../../AppContext.jsx';

class ArrowLabelMarker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            svgSprite: null,
        };
    }

    componentDidMount() {
        this.addLabelMarker()
    }

    makeLabelCanvas(labelFont, size, color, name) {
        const baseWidth = 200;
        const borderSize = 2;
        const ctx = document.createElement('canvas').getContext('2d');
        const font = `${size * 2}px ` + + labelFont;;
        ctx.font = font;
        // measure how long the name will be
        const textWidth = ctx.measureText(name).width;

        const doubleBorderSize = borderSize * 3;
        const width = baseWidth + doubleBorderSize;
        const height = (size * 2) + doubleBorderSize;
        ctx.canvas.width = width;
        ctx.canvas.height = height;

        // need to set font again after resizing canvas
        ctx.font = font;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';

        ctx.fillRect(0, 0, 0, 0);

        // scale to fit but don't stretch
        const scaleFactor = Math.min(1, baseWidth / textWidth);
        ctx.translate(width / 2, height / 2);
        ctx.scale(scaleFactor, 1);
        ctx.fillStyle = color;
        ctx.fillText(name, 0, 0);

        return ctx.canvas;
    }

    addLabelMarker = () => {
        const { labelSize, labelColor, labelText, labelFont } = this.props;
        const canvas = this.makeLabelCanvas(labelFont, labelSize, labelColor, labelText);
        const texture = new THREE.CanvasTexture(canvas);
        texture.minFilter = THREE.LinearFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;
        this.setState({ svgSprite: texture })
    }

    render() {
        const { svgSprite } = this.state;
        return (
            <div>
                <Marker
                    transform={this.props.transform}
                    colliderTransform={this.props.colliderTransform}
                    svgSprite={svgSprite}
                    primaryColor={this.props.primaryColor}
                    secondaryColor={this.props.secondaryColor}
                    rotationAngle={this.props.rotationAngle}
                    showVisual={this.props.showVisual}
                    isLabel={true}
                />
            </div>
        );
    }
}

ArrowLabelMarker.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.array.isRequired,
    colliderTransform: PropTypes.array.isRequired,
    linkedRoomId: PropTypes.string.isRequired,
    spriteType: PropTypes.string,
    canFade: PropTypes.bool,
    showVisual: PropTypes.bool,
    setLoadingIconVisibility: PropTypes.func,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    rotationAngle: PropTypes.number,
    labelColor: PropTypes.string.isRequired,
    labelFont: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    labelSize: PropTypes.number.isRequired
};

export default props => (
    <AppContext.Consumer>
        {appContextValue => (
            <ThreeJSWorldContext.Consumer>
                {threeJSWorldContext => (
                    <ArrowLabelMarker
                        {...props}
                        threeJSWorldContext={threeJSWorldContext}
                        setLoadingIconVisibility={appContextValue.setLoadingIconVisibility}
                    />
                )}
            </ThreeJSWorldContext.Consumer>
        )}
    </AppContext.Consumer>
);
