import React, { useEffect, useState, useContext } from 'react';
import { ProductModalContext } from '../../ProductModalContext';

import { Button } from 'web-store-modules/packages/web-store-ui-library'

import { BuyNowOnce, BuyNowClicked, AddToWishlistClicked } from '../../../../../../utils/Analytics';
// import ProductModalDropdown from './dropdown-button/ProductModalDropdown.jsx';
// import ProductModalCartOverlay from './ProductModalCartOverlay';
import { AddToCartContext } from '../../../../add-to-cart/AddToCartContext';
import SizeSelector from './size-selector/SizeSelector';

const ProductModalButtons = ({ sku, title, price, downsizedThumbnail, buttonFontStyle,
    addToCartFontStyle, buyNowFontStyle, productButtonCopy,
    addToCartCopy, url, cartUrl, setShowingAddToCartPrompt,
    addToCartEnabled, sizeDropdownEnabled, groupProducts }) => {

    const { colors, buttonBorderRadius, buttonBorderWidth, buyNowBorderActiveOn, addToCartBorderActiveOn, productInfo, variantSize, setVariantSize, variantColor, productVariantMap } = useContext(ProductModalContext)
    const { addToCart, removeFromCart, cartList } = useContext(AddToCartContext);
    const [isInWishlist, setIsInWishlist] = useState(false);
    const [firstSeenInWishlistButton, setFirstSeenInWishlistButton] = useState(false);

    useEffect(() => {
        if (cartList.find(item => item.TITLE === title)) {
            setIsInWishlist(true);
        } else {
            setIsInWishlist(false);
        }
    }, [cartList])


    const buyButtonClicked = () => {
        BuyNowOnce();
        BuyNowClicked(sku, title);
        window.open(url, '_blank');
    }

    const addToCartClicked = (option) => {
        // addToCart is only active when the item isn't in the cart/wishlist
        if (isInWishlist && firstSeenInWishlistButton) {
            return;
        }

        // addToCart is only active when variantSize and variantColor are set
        if (sizeDropdownEnabled && !variantSize && !variantColor) {
            return;
        }

        const variantSKU = productVariantMap.features_map[variantColor][variantSize];
        const productSKU = variantSKU ? variantSKU : sku;

        const variantName = productVariantMap.siblings[variantSKU];
        const productName = variantName ? variantName : title;

        if (isInWishlist) {
            removeFromCart(productName)
        } else {
            addToCart({
                TITLE: productName,
                PRICE: price,
                DOWNSIZED_THUMBNAIL: downsizedThumbnail,
            });

            AddToWishlistClicked(productSKU, productName);
            setFirstSeenInWishlistButton(true);
        }
    }

    const renderCartText = () => {
        if (isInWishlist && firstSeenInWishlistButton) {
            return 'This item has been added to Cart';
        }
        if (isInWishlist) {
            return 'Remove from Cart';
        }
        return addToCartCopy;
    }

    const addToCartColors = () => {
        if (isInWishlist) {
            return colors && colors.addToCartSecondaryColor;
        } else if (variantSize) {
            return colors && colors.addToCartPrimaryColor;
        }
        return 'white';
    }

    return (
        <div className='product-button-container' style={{ width: addToCartEnabled && url ? '90%' : '50%' }}>
            {sizeDropdownEnabled &&
                <SizeSelector
                    sku={sku}
                    groupProducts={groupProducts}
                    variantSize={variantSize}
                    setVariantSize={setVariantSize}
                    colors={colors}
                    variantColor={variantColor}
                    productVariantMap={productVariantMap}
                />
            }
            {addToCartEnabled && <Button
                hoverStyleChange={true}
                onClick={addToCartClicked}
                primaryColor={addToCartColors()}
                secondaryColor={colors && colors.addToCartSecondaryColor}
                className="product-button flex-center obsessvr-regularFont full-width pointer"
                style={{ padding: '0', margin: '0 1%', borderRadius: '4px' }}
                primaryBorderWidth={addToCartBorderActiveOn && addToCartBorderActiveOn.primary ? buttonBorderWidth : undefined}
                secondaryBorderWidth={addToCartBorderActiveOn && addToCartBorderActiveOn.secondary ? buttonBorderWidth : undefined}
                primaryBorderColor={colors && colors.addToCartBorderColor}
                secondaryBorderColor={colors && colors.addToCartBorderColor}
                insetBorder={true}
                padded={false}
            >
                <p className='obsessvr-regularFont' style={addToCartFontStyle}>
                    {renderCartText()}
                </p>
            </Button>}
            {!sizeDropdownEnabled && url && <Button
                hoverStyleChange={true}
                onClick={buyButtonClicked}
                primaryColor={colors && colors.buyNowPrimaryColor}
                secondaryColor={colors && colors.buyNowSecondaryColor}
                className="product-button flex-center obsessvr-regularFont full-width pointer"
                style={{ padding: '0', margin: '0 1%', borderRadius: '4px' }}
                primaryBorderWidth={buyNowBorderActiveOn && buyNowBorderActiveOn.primary ? buttonBorderWidth : undefined}
                secondaryBorderWidth={buyNowBorderActiveOn && buyNowBorderActiveOn.secondary ? buttonBorderWidth : undefined}
                primaryBorderColor={colors && colors.buyNowBorderColor}
                secondaryBorderColor={colors && colors.buyNowBorderColor}
                insetBorder={true}
                padded={false}
            >
                <p className='obsessvr-regularFont' style={buttonFontStyle}>
                    {productButtonCopy}
                </p>
            </Button>}
        </div>
    )
}

export default ProductModalButtons;