import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './BottomBarRight.css';
import BaseImage from './image-components/BaseImage.jsx';
import TipPanel from './ui-layer-panel/TipPanel.jsx';
import DisclaimerPanel from './ui-layer-panel/DisclaimerPanel.jsx';
import { getObsessLogoAsync, getObsessUrlAsync } from '../../utils/StaticAssetManager.js';
import { isMobileDevice } from '../../utils/DeviceDetector';
import { getShowPoweredByObsessAsync, getHasDisclaimerAsync, getTipsIconAsync, getTipsSettingsAsync } from '../../utils/StoreConfigManager.js';
import PoweredByObsess from './ui-layer-panel/PoweredByObsess';
import SVGImage from './image-components/SVGImage';
import Disclaimer from './ui-layer-panel/Disclaimer';
import AccessibilityButton from './AccessibilityButton';

const obsessLogoStyle = {
    width: '3em'
};

const defualtObsessUrl = 'http://www.obsessAR.com/';
const isMobile = isMobileDevice();
const tipInfoIconSrc = 'https://s3.amazonaws.com/obsessvr-webstore-assets-public/tips-info-icon.svg';

class BottomBarRight extends Component {
    constructor(props) {
        super(props);
        this.goToObsessUrl = this.goToObsessUrl.bind(this);
        this.toggleTipPanel = this.toggleTipPanel.bind(this);
        this.onTipClosed = this.onTipClosed.bind(this);
        this.closeTip = this.closeTip.bind(this);
        this.state = {
            showPoweredByObsess: false,
            hasDisclaimer: true,
            obsessLogoSrc: '',
            obsessUrl: defualtObsessUrl,
            isTipShowing: false,
            isDisclaimerShowing: false,
            showTipsIcon: true,
            insidePanel: true,
        };
        this.isPanelPanding = false;

        this.logoOnClick = null;
        this.logoOnTouchEnd = null;
        this.tipOnClick = null;
        this.tipOnTouchEnd = null;
        if (isMobile) {
            this.logoOnTouchEnd = this.goToObsessUrl;
            this.tipOnTouchEnd = this.toggleTipPanel;
        } else {
            this.logoOnClick = this.goToObsessUrl;
            this.tipOnClick = this.toggleTipPanel;
        }

        this.toggleDisclaimerPanel = this.toggleDisclaimerPanel.bind(this);
        this.onDisclaimerClosed = this.onDisclaimerClosed.bind(this);
        this.disclaimerOnClick = null;
        this.disclaimerOnTouchEnd = null;
        if (isMobile) {
            this.disclaimerOnTouchEnd = this.toggleDisclaimerPanel;
        } else {
            this.disclaimerOnClick = this.toggleDisclaimerPanel;
        }
        this.showingDisclaimer = this.showingDisclaimer.bind(this);
    }

    showingDisclaimer(isShowingDisclaimer) {
        this.setState({ isShowingDisclaimer });
    }

    toggleDisclaimerPanel() {
        if (this.state.isTipShowing) {
            this.isPanelPanding = true;
            this.setState({
                isTipShowing: false
            });
            return;
        }
        this.setState((previousState) => ({
            isDisclaimerShowing: !previousState.isDisclaimerShowing
        }));

        this.showingDisclaimer(!this.state.isDisclaimerShowing);
    }

    onDisclaimerClosed() {
        this.setState({ isDisclaimerShowing: false });
        if (this.isPanelPanding) {
            this.toggleTipPanel();
            this.isPanelPanding = false;
        }
    }

    componentDidMount() {
        getTipsIconAsync()
            .then(icon => {
                const showTipsIcon = icon === 'info';
                this.setState({ showTipsIcon: showTipsIcon })
            })
        getTipsSettingsAsync()
            .then(isInsideMenu => {
                this.setState({ insidePanel: isInsideMenu })
            })
            .catch(error => console.error(error));
        getShowPoweredByObsessAsync()
            .then(showPoweredByObsess => {
                if (showPoweredByObsess) {
                    getObsessLogoAsync()
                        .then(url => this.setState({ obsessLogoSrc: url }))
                        .catch(error => console.error(error));

                    getObsessUrlAsync()
                        .then(url => this.setState({ obsessUrl: url }))
                        .catch(error => console.error(error));
                }
                this.setState({ showPoweredByObsess: showPoweredByObsess });
            });

        getHasDisclaimerAsync()
            .then(hasDisclaimer => {
                this.setState({ hasDisclaimer: hasDisclaimer })
            })
            .catch(error => console.error(error));

    }

    goToObsessUrl() {
        this.setState({
            isTipShowing: false,
            isDisclaimerShowing: false
        });
        window.open(this.state.obsessUrl, '_blank');
    }

    toggleTipPanel() {
        if (this.state.hasDisclaimer && this.state.isDisclaimerShowing) {
            this.isPanelPanding = true;
            this.setState({
                isDisclaimerShowing: false
            });
            return;
        }
        this.registerToClickOutside(!this.state.isTipShowing);
        this.setState((previousState) => ({
            isTipShowing: !previousState.isTipShowing
        }));

        this.props.toggleTipPanel(!this.state.isTipShowing);
    }

    registerToClickOutside(showMenu) {
        const rootNode = document.getElementById('obsessvr-webstore-react-embed-root');
        if (showMenu) {
            if (isMobile) {
                rootNode.addEventListener('touchend', this.closeTip);
            } else {
                rootNode.addEventListener('click', this.closeTip);
            }
        } else {
            if (isMobile) {
                rootNode.removeEventListener('touchend', this.closeTip);
            } else {
                rootNode.removeEventListener('click', this.closeTip);
            }
        }
    }

    closeTip(event) {
        if (event.target.tagName === 'CANVAS') {
            this.onTipClosed();
            const rootNode = document.getElementById('obsessvr-webstore-react-embed-root');
            if (isMobile) {
                rootNode.removeEventListener('touchend', this.closeMenu);
            } else {
                rootNode.removeEventListener('click', this.closeMenu);
            }
        }
    }

    onTipClosed() {
        this.setState({ isTipShowing: false });
        this.props.toggleTipPanel(false);
        if (this.state.hasDisclaimer && this.isPanelPanding) {
            this.toggleDisclaimerPanel();
            this.isPanelPanding = false;
        }
    }

    render() {
        const { insidePanel, hasDisclaimer, showPoweredByObsess, isTipShowing, isDisclaimerShowing, showTipsIcon } = this.state;
        const { setShowAccessibilityModal } = this.props;

        let poweredByObsess = <div />;
        if (showPoweredByObsess && !insidePanel) {
            poweredByObsess = <PoweredByObsess showSeparator={insidePanel || hasDisclaimer} />;
        }


        let disclaimerRender = <div />;
        if (hasDisclaimer && !insidePanel) {
            disclaimerRender =
                <Disclaimer
                    insidePanel={insidePanel}
                    isDisclaimerShowing={isDisclaimerShowing}
                    disclaimerOnClick={this.disclaimerOnClick}
                    disclaimerOnTouchEnd={this.disclaimerOnTouchEnd}
                    showSeparator={true}
                />;
        }

        const disclaimerPanel = (
            <DisclaimerPanel
                visible={isDisclaimerShowing}
                onCloseButtonClicked={this.toggleDisclaimerPanel}
                onPanelClosed={this.onDisclaimerClosed}
            />
        );

        const tipHighlight = isTipShowing ? ' uiHighlight' : '';
        const tipPanel = (
            <TipPanel
                visible={isTipShowing}
                onCloseButtonClicked={this.toggleTipPanel}
                onPanelClosed={this.onTipClosed}
                showPoweredByObsess={showPoweredByObsess && insidePanel}
                hasDisclaimer={hasDisclaimer && insidePanel}
                isDisclaimerShowing={isDisclaimerShowing}
                disclaimerOnClick={this.disclaimerOnClick}
                disclaimerOnTouchEnd={this.disclaimerOnTouchEnd}
            />
        );

        const tips = showTipsIcon ?
            <SVGImage
                src={tipInfoIconSrc}
                color="white"
                width={25}
                height={25}
                viewbox='0 0 52 52'
            /> :
            <p>Tips</p>;

        return (
            <div id='bottomBarRight' style={{ bottom: isMobile ? '4.7em' : '0.25em'}}>
                {tipPanel}
                {disclaimerPanel}
                <div id='bottomBarRightMenuContainer'>
                    <AccessibilityButton setShowAccessibilityModal={setShowAccessibilityModal} />
                    {poweredByObsess}
                    {disclaimerRender}
                    <div
                        className={'hoverable' + tipHighlight}
                        onClick={this.tipOnClick}
                        onTouchEnd={this.tipOnTouchEnd}
                    >
                        {/* {tips} */}
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomBarRight;
