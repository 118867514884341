import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../BottomBarRight.css';
import './TipPanel.css';

class Disclaimer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { isDisclaimerShowing, disclaimerOnClick, disclaimerOnTouchEnd, showSeparator } = this.props;
        const disclaimerHighlight = isDisclaimerShowing ? ' uiHighlight' : '';

        const disclaimerRender = (
            <div>
                <div
                    className={'hoverable' + disclaimerHighlight}
                    onClick={disclaimerOnClick}
                    onTouchEnd={disclaimerOnTouchEnd}
                >
                    <p>Disclaimer</p>
                </div>
                {showSeparator &&
                    <div id='separator'> | </div>}
            </div>
        );
        return (
            <div>
                {disclaimerRender}
            </div>
        );
    }

}

export default Disclaimer;
