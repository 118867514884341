import React, { Component } from 'react';
import Marker from './Marker.jsx';
import { constructUrl } from '../../utils/UrlConstructor.js';
import * as THREE from 'three';
import { RoomContext } from '../room/RoomContext';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import { loadImageAsync } from '../../utils/AssetLoader';
import { getThreeTransformFromArray } from '../../utils/ThreeTransformConverter.js';

class SpriteSheetAnimator {
  /**
   *
   * @param {THREE.Texture} texture - THREE.js texture
   * @param {int} tileCountX - Horizontal tile count
   * @param {int} frameSpeed - Frames per second
   */
  constructor(texture, tileCountX, frameSpeed = 0.06) {
      this.update = this.update.bind(this);
      this.texture = texture;
      this.tileCountX = tileCountX;

      this.perFrameTimeMilliseconds = frameSpeed;
      this.timeSinceLastFrameMilliseconds = 0;
      this.texture.repeat.x = 1 / this.tileCountX;
      this.texture.offset.x = 0;
      this.currentTile = 0;
  }

  update(deltaTimeMilliseconds) {
      this.timeSinceLastFrameMilliseconds += deltaTimeMilliseconds;
      if (this.timeSinceLastFrameMilliseconds >= this.perFrameTimeMilliseconds) {
          // go to next frame
          this.currentTile++;
          if (this.currentTile === this.tileCountX) {
              this.currentTile = 0;
          }
          this.texture.offset.x = this.currentTile / this.tileCountX;
          this.timeSinceLastFrameMilliseconds = 0;
          return true;
      }
      return false;
  }
}

class AnimatedMarker extends Component {
  constructor(props) {
    super(props);
    this.loadTexture = this.loadTexture.bind(this);
    this.updateTransform = this.updateTransform.bind(this);

    this.transform = getThreeTransformFromArray(props.transform);
    this.sprite = new THREE.Mesh();
    this.scene = this.props.scene;
    this.animatedMarkers = this.props.animatedMarkers;
  }

  loadTexture() {
    const { joined_image } = this.props.modalContent; 
    const url = joined_image && joined_image.length ? constructUrl(joined_image[0].url) : false;

    if (url) {
      loadImageAsync(url,
        (image) => {
          let width = image.image.width;
          let height = image.image.height;

          width /= this.props.modalContent.frames
          let max = Math.max(width, height);
          let min = max === width ? height : width;

          let shorter = min / max

          if (width === max) {
            width = 1
            height = shorter
          } else {
            height = 1
            width = shorter
          }

          this.sprite.geometry = new THREE.PlaneBufferGeometry(width, height);
          this.sprite.material = new THREE.MeshBasicMaterial({
                          transparent: true,
                          map: image
                        });
          const animation = new SpriteSheetAnimator(image, this.props.modalContent.frames, this.props.modalContent.frames_duration)
          this.animatedMarkers.push(animation)
          this.updateTransform()
        },
        null,
        (error) => {
          console.log("error: " + error + ", loading image with url: " + url);
        },
      );
    } else {
      console.error('Error to construct image URL')
    }
  }

  disposeMaterials() {
    if (this.sprite.material.map) {
      this.sprite.material.map.dispose();
    }
    
    this.sprite.material.dispose();
  }

  componentDidMount(){
    this.loadTexture();
    this.scene.add(this.sprite);
  }

  componentWillUnmount() {
    this.animatedMarkers.forEach( animation => {
      for(let [key] of Object.entries(animation)){
        delete animation[key]
      }
    })

    this.scene.remove(this.sprite);
    this.disposeMaterials();
    this.sprite.geometry.dispose();
    this.sprite = null;
    this.animatedMarkers = [];
  }

  updateTransform() {
    this.sprite.matrix = this.transform;
    this.sprite.matrix.decompose(this.sprite.position, this.sprite.quaternion, this.sprite.scale);
    this.sprite.lookAt(0, 0, 0);
  }
  
  render() {
    return ( 
      <div>
        <Marker 
          transform = {this.props.transform}
           colliderTransform = {this.props.colliderTransform}
      /> 
      </div>
    )
  }
}

export default props => (
  <ThreeJSWorldContext.Consumer>
      {threeJSWorldContextValue => (
          <RoomContext.Consumer>
              {roomContextValue => (
                                    <AnimatedMarker
                                    {...props}
                                    roomId={roomContextValue.roomId}
                                    scene={threeJSWorldContextValue.scene}
                                    animatedMarkers = {threeJSWorldContextValue.animatedMarkers}
                                    request3DSceneRender={threeJSWorldContextValue.request3DSceneRender}
                                    requestImmediate3DSceneRender={threeJSWorldContextValue.requestImmediate3DSceneRender}
                                />
              )}
          </RoomContext.Consumer>
      )}
  </ThreeJSWorldContext.Consumer>
);