import { useState, useEffect, useRef } from 'react';

const useLongPress = (callback = () => {}, ms = 300) => {
    const timerId = useRef();

    useEffect(() => {
        return (() => {
            console.log('removing interval')
            clearInterval(timerId.current);
        }) 
    }, []);

    const onPress = () => {
        timerId.current = setInterval(() => {
            callback();
        }, ms)
    };

    const onRelease = () => {
        clearInterval(timerId.current);
    };

    return [onPress, onRelease]
}

export default useLongPress;