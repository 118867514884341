import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ColorSelectorActivated, ColorSelectorOptionClicked } from '../../../../../utils/Analytics.js';

import { ProductModal } from "web-store-modules/packages/product-modal";


class TemplateProductHeader extends Component {
    constructor(props){
        super(props);

        this.renderProductText = this.renderProductText.bind(this);
        this.colorSelectorAnalytics = this.colorSelectorAnalytics.bind(this);

        this.colorSelectorActivated = false;
    }

    renderProductText() {
        const { productInfo, showStylingFields, fontStyles, productPricePrefix } = this.props;
        
        if (fontStyles && Object.keys(productInfo).length && Object.keys(showStylingFields).length && Object.keys(fontStyles).length) {
            const { popUpTitleFontStyle, colorFontStyle, shortDescriptionFontStyle, priceFontStyle } = fontStyles;
            const { showBrandName, showColor, showDescription, showPrice } = showStylingFields;

            const brand = showBrandName ? productInfo[ProductModal.ContentTypes.BRAND] : '';
            const title = showBrandName ? productInfo[ProductModal.ContentTypes.TITLE] : '';

            // color doesn't show if ColorSelector also shows)
            const color = (showColor && !productInfo[ProductModal.ContentTypes.GROUP]) ? productInfo[ProductModal.ContentTypes.COLOR] : '';
            const description = showDescription ? productInfo[ProductModal.ContentTypes.DESCRIPTION] : '';

            const soldOut = productInfo[ProductModal.ContentTypes.SOLD_OUT];
            const showSalePrice = productInfo[ProductModal.ContentTypes.SALE_PRICE];

            const [priceNum, salePriceNum] = [productInfo[ProductModal.ContentTypes.PRICE], productInfo[ProductModal.ContentTypes.SALE_PRICE]].map(price => {
                if (price || price === 0) {
                    if (price % 1 !== 0) {
                        return price.toFixed(2);
                    }
                }
                return price;
            });

            const price = (showPrice && !soldOut) ? productPricePrefix + priceNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            const salePrice = (showSalePrice && !soldOut) ? productPricePrefix + salePriceNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';

            const productModalFieldProps = {
                'brand': {value: brand, id: 'productModalBrand', className: 'obsessvr-boldFont', style: popUpTitleFontStyle},
                'title': {value: title, id: 'productModalTitle', style: popUpTitleFontStyle},
                'color': {value: color, id: 'productModalColor', className: 'obsessvr-regularFont underline', style: colorFontStyle},
                'description': {value: description, id: 'productModalDescription', className: 'obsessvr-regularFont', style: shortDescriptionFontStyle},
                'price': {value: price, id: 'productModalPrice', className: 'obsessvr-regularFont', style: showSalePrice ? {...priceFontStyle, display: 'block', textDecoration: 'line-through'} : priceFontStyle},
                'salePrice': {value: salePrice, id: 'productModalSalePrice', className: 'obsessvr-regularFont', style: {...priceFontStyle, display: 'block', color: 'red'}}
            };

            return ['brand', 'title', 'color', 'description', 'price', 'salePrice'].map((field, index) => {
                const props = productModalFieldProps[field];
                if (props.value) {
                    return <p key={index} id={props.id} className={props.className} style={props.style}>{props.value}</p>
                }
            });
        }
    }

    colorSelectorAnalytics(productName, color, sku) {
        if (!this.colorSelectorActivated) {
            this.colorSelectorActivated = true;
            ColorSelectorActivated(sku, productName);
        }
        ColorSelectorOptionClicked(color, productName);
    }

    render() {
        const { sku, fetchProductInfo, groupProducts, productInfo, fontStyles, onColorSelectorLoaded } = this.props;

        const productGroup = productInfo[ProductModal.ContentTypes.GROUP];
        return (
            <React.Fragment>
                {this.renderProductText()}
                {productGroup && productGroup !== ',' ? (  // Render as long as group exists (and value is valid) instead of checking group length to avoid awkward sudden appearance
                    <div id='colorSelector'>
                        <ProductModal.ColorSelector
                            productSKU={sku}
                            color={productInfo[ProductModal.ContentTypes.COLOR]}
                            colorFontStyle={fontStyles.colorFontStyle}
                            activeColorUrl={productInfo[ProductModal.ContentTypes.COLOR_IMAGE_FILE]}
                            fetchProductInfo={fetchProductInfo}
                            groupProducts={groupProducts}
                            onColorSelectorLoaded={onColorSelectorLoaded}
                            analytics={this.colorSelectorAnalytics}
                        />
                    </div>
                ) : <div style={{padding: '0.5em 0'}} />}
            </React.Fragment>
        );
    }
}

TemplateProductHeader.propTypes = {
    productInfo: PropTypes.object.isRequired,
    fontStyles: PropTypes.object.isRequired,
    showStylingFields: PropTypes.object.isRequired,
    productPricePrefix: PropTypes.string,
    sku: PropTypes.string.isRequired,
    groupProducts: PropTypes.array.isRequired,
    fetchProductInfo: PropTypes.func,
    onColorSelectorLoaded: PropTypes.func
};

export default TemplateProductHeader;