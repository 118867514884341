import React, { useState, useEffect } from 'react';

export const MediaContext = React.createContext({
    isMusicPlaying: false,
    isVideoPlaying: false,
})

export const MediaContextProvider = ({ children }) => {
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        if (isVideoPlaying) {
            setIsMusicPlaying(false);
        } else {
            setIsMusicPlaying(true);
        }
    }, [isVideoPlaying])

    const value = {
        isMusicPlaying,
        isVideoPlaying,
        setIsMusicPlaying,
        setIsVideoPlaying
    }
    return (
        <MediaContext.Provider value={value}>
            {children}
        </MediaContext.Provider>
    )
}
