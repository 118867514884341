const clickedRoomMarkerIds = new Set();
const heartedSKUs = new Set();

export function isRoomMarkerClicked(id) {
    return clickedRoomMarkerIds.has(id);
}

export function addClickedRoomMarker(id) {
    clickedRoomMarkerIds.add(id);
}

export function isSKUHearted(sku) {
    return heartedSKUs.has(sku);
}

export function addHeartedSKU(sku) {
    heartedSKUs.add(sku);
}

export function removeHeartedSKU(sku) {
    heartedSKUs.delete(sku);
}
