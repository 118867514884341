import React, { Component } from 'react';
import { AppContext } from "./AppContext.jsx";
import { LoadingIcon2 } from 'web-store-modules/packages/web-store-ui-library';

export default class AppLoadingIcon extends Component {
    render() {
        return this.context.showingLoadingIcon ? <LoadingIcon2 /> : <React.Fragment />;
    }
}

AppLoadingIcon.contextType = AppContext;