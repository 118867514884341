import React, { Component } from 'react';

import './MusicController.css';
import '../ui/buttons/SocialButton.css';
import { getBackgroundMusicUrlAndStartStatus, getMusicIconGradient } from '../../utils/StoreConfigManager.js';
import { MusicButton } from '../../utils/Analytics.js';
import { isMobileDevice } from '../../utils/DeviceDetector';
import SocialButton from './buttons/SocialButton';
import { MediaContext } from './MediaContext';

const isMobile = isMobileDevice();

let hidden;
let visibilityChangeEventName;

if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChangeEventName = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChangeEventName = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChangeEventName = "webkitvisibilitychange";
}

const defaultTopColor = 'white';
const defaultBottomColor = 'black';

class MusicController extends Component {
    constructor(props) {
        super(props);

        this.toggleMusic = this.toggleMusic.bind(this);
        this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
        this.soundLoaded = this.soundLoaded.bind(this);
        this.onClick = this.onClick.bind(this);
        this.playAudio = this.playAudio.bind(this);

        this.state = {
            startPlaying: false,
            isAudioOn: false,
            playOnStart: false,
            backgroundMusicUrl: '',
            audio: null,
            previouslyOn: false,
            audioBarStyle: {
                backgroundImage: 'linear-gradient(-135deg, ' + defaultTopColor + ' 0%, ' + defaultBottomColor + ' 100%)'
            }
        };

        getBackgroundMusicUrlAndStartStatus()
            .then(urlAndStatus => {
                const url = urlAndStatus.url;
                const playOnStart = urlAndStatus.playOnStart;
                if (url) {
                    document.addEventListener(visibilityChangeEventName, this.onVisibilityChanged, false);
                    const audio = new Audio(url);
                    audio.loop = true;
                    // if (playOnStart) audio.addEventListener('canplaythrough', this.soundLoaded, false);

                    this.setState({
                        backgroundMusicUrl: url,
                        audio,
                        playOnStart
                    });
                }
            })
            .catch(error => {
                console.error(error);
            });

        getMusicIconGradient()
            .then(colors => {
                if (colors) {
                    this.setState({
                        audioBarStyle: {
                            backgroundImage: 'linear-gradient(-135deg, ' + colors[0] + ' 0%, ' + colors[1] + ' 100%)'
                        }
                    });
                } else {
                    console.log('Music controller linear gradient using default color');
                }
            })
            .catch(error => console.error(error));
    }

    componentDidMount() {
        if (isMobile) {
            window.addEventListener('touchend', this.onClick);
        } else {
            window.addEventListener('click', this.onClick);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.mediaContext.isVideoPlaying !== prevProps.mediaContext.isVideoPlaying) {
            if (this.props.mediaContext.isVideoPlaying) {
                if (!this.state.isAudioOn) {
                    this.setState({ previouslyOn: false });
                    return;
                }
                this.setState({ previouslyOn: true });
                this.toggleMusic();
            } else {
                if (this.state.previouslyOn) {
                    this.playAudio()
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener(visibilityChangeEventName, this.onVisibilityChanged);
    }

    onClick() {
        const { playOnStart, audio } = this.state;

        if (audio) {
            if (playOnStart) {
                this.playAudio();
            }
        }
    }

    playAudio() {
        var playPromise = this.state.audio.play();

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                this.props.mediaContext.setIsMusicPlaying(true);
                this.setState({
                    isAudioOn: true
                });

                if (isMobile) {
                    window.removeEventListener('touchend', this.onClick);
                } else {
                    window.removeEventListener('click', this.onClick);
                }
            })
                .catch(error => {
                    console.log(error);
                    this.props.mediaContext.setIsMusicPlaying(false);
                    this.setState({
                        isAudioOn: false
                    });
                });
        }
    }

    pauseAudio = () => {
        this.state.audio.pause();
    }

    soundLoaded() {
        this.playAudio();
    }

    onVisibilityChanged() {
        if (!this.state.backgroundMusicUrl) {
            return;
        }

        if (document[hidden] && this.state.audio) {
            this.state.audio.pause();
        } else {
            if (this.state.isAudioOn && this.state.audio) {
                this.playAudio();
            }
        }
    }

    toggleMusic() {
        if (!this.state.backgroundMusicUrl) {
            return;
        }

        const isAudioOn = !this.state.isAudioOn;

        if (isAudioOn) {
            this.props.mediaContext.setIsMusicPlaying(isAudioOn);
            this.playAudio();
        } else {
            this.props.mediaContext.setIsMusicPlaying(isAudioOn);
            this.pauseAudio();
        }

        this.setState({
            isAudioOn
        });

        MusicButton(this.state.isAudioOn);
    }

    render() {
        const { isAudioOn, audio, audioBarStyle } = this.state;
        const { newRender } = this.props;

        let className = isAudioOn ? 'audio-bar audio-on' : 'audio-bar audio-off';
        if (newRender) {
            return (
                <SocialButton onClick={this.toggleMusic}>
                    <div id='audio-bar-container'>
                        <div id="audio-bar-1" className={className} style={audioBarStyle} />
                        <div id="audio-bar-2" className={className} style={audioBarStyle} />
                        <div id="audio-bar-3" className={className} style={audioBarStyle} />
                    </div>
                </SocialButton>
            );
        } else {
            return (
                <div id='audio-bar-container-old' onClick={this.toggleMusic}>
                    <div id="audio-bar-1" className={className} style={audioBarStyle} />
                    <div id="audio-bar-2" className={className} style={audioBarStyle} />
                    <div id="audio-bar-3" className={className} style={audioBarStyle} />
                </div>
            )
        }
    }
}

export default props => (
    <MediaContext.Consumer>
        {mediaContextValue => (
            <MusicController mediaContext={mediaContextValue} {...props} />
        )}
    </MediaContext.Consumer>
)
