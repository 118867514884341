import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../StoreSelector.css';
import './NewStoreSelector.css';
import Segment from 'semantic-ui-react/dist/es/elements/Segment';
import Header from 'semantic-ui-react/dist/es/elements/Header';

import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import StoreSelectorMenuItem from './StoreSelectorMenuItem';
import CurrentStoreMenuItem from './CurrentStoreMenuItem';

/**
    Default store selector used by all new stores, as long as they follow the pattern of only linking scenes insteda of
    linking stores
 */
class StoreSelectorMenu extends Component {
    constructor(props) {
        super(props);
        this.hide = this.hide.bind(this);
        this.renderMenuItems = this.renderMenuItems.bind(this);
    }

    hide() {
        this.setState({ visible: false });
    }

    renderMenuItems() {
        const { linkedStores, currentStoreIndex, sceneIdNameDict, menuTextFontStyle, closeMenu } = this.props;
        return (
            <Segment.Group>
                {linkedStores.map((store, index) => {
                    // let MenuItem = currentStoreIndex === index ? CurrentStoreMenuItem : StoreSelectorMenuItem;
                    const MenuItem = CurrentStoreMenuItem;
                    return <MenuItem
                        key={index}
                        store={store}
                        menuTextFontStyle={menuTextFontStyle}
                        sceneIdNameDict={sceneIdNameDict}
                        closeMenu={closeMenu}
                        storeSelectorLen={linkedStores[0]?.linkedScenes?.length}
                    />
                })}
            </Segment.Group>
        );
    }

    render() {
        const { showMenu, menuTitleFontStyle, selectorTitle } = this.props;
        if (!showMenu) {
            return (<div />);
        }
        return (
            <div id='storeSelectorMenu'>
                {selectorTitle &&
                    <Header textAlign="center" id="storeSelectorMenuTitle" style={menuTitleFontStyle}>
                        {selectorTitle}
                    </Header>
                }
                {this.renderMenuItems()}
            </div>
        );
    }
}

StoreSelectorMenu.propTypes = {
    showMenu: PropTypes.bool,
    linkedStores: PropTypes.array,
    currentStoreIndex: PropTypes.number,
    menuTextFontStyle: PropTypes.object,
    menuTitleFontStyle: PropTypes.object,
    menuTitle: PropTypes.string,
    sceneIdNameDict: PropTypes.object,
    closeMenu: PropTypes.func
};

export default StoreSelectorMenu;
