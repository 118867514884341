import React, { Component } from 'react';
import '../StoreSelector.css';
import './OldStoreSelector.css';
import { setCursorStyle } from '../../../../utils/MouseUtilities.js';
import Segment from 'semantic-ui-react/dist/es/elements/Segment';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/image.min.css';

const hoverCursorStyle = 'pointer';
const unhoverCursorStyle = 'all-scroll';

import { StoreSelectorOption } from '../../../../utils/Analytics.js';
import SVGImage from '../../image-components/SVGImage';
import { getAllSceneInfoDictAsync } from '../../../../utils/StoreConfigManager';
import { AppContext } from '../../../../AppContext.jsx';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';

/**
    Legacy Store Selector styling for Levi's. Only used by stores that have multiple linked stores - all other stores
    will use NewStoreSelector
 */
class OldCurrentStoreMenuItem extends Component {
    constructor(props) {
        super(props);

        this.onHover = this.onHover.bind(this);
        this.onUnhover = this.onUnhover.bind(this);
        this.onClick = this.onClick.bind(this);

        this.state = {
            jumpToFont: 'https://s3.amazonaws.com/obsessvr-webstore-assets-public/Caslon-BookItalic.otf',
            placeholderIconSrc: 'https://s3.amazonaws.com/obsessvr-webstore-assets-public/placeholder.svg',
            currentStoreMenuItemColor: '#7d7e7e',
        }

    }

    onHover() {
        setCursorStyle(hoverCursorStyle);
    }

    onUnhover() {
        setCursorStyle(unhoverCursorStyle);
    }

    onClick(sceneId) {
        const { threeJSWorldContext, setLoadingIconVisibility, closeMenu } = this.props;
        setLoadingIconVisibility(true);
        closeMenu();
        getAllSceneInfoDictAsync()
            .then(sceneInfoDict => {
                if (!sceneInfoDict[sceneId]) {
                    throw new Error('Scene with id ' + sceneId + ' do not exist!');
                }

                const linkedSceneName = sceneInfoDict[sceneId]['name'];
                let targetPath = '/' + linkedSceneName;
                StoreSelectorOption(linkedSceneName);

                if (threeJSWorldContext.history.location.pathname !== targetPath) {
                    threeJSWorldContext.pushHistoryWithReset(targetPath)
                } else {
                    this.props.setLoadingIconVisibility(false);
                }
            })
    }

    render() {
        const { currentStoreMenuItemColor, placeholderIconSrc, jumpToFont } = this.state;
        const { menuTextFontStyle, store } = this.props;

        let style = Object.assign({}, menuTextFontStyle);
        let sceneStyle = Object.assign({}, menuTextFontStyle);
        if (!sceneStyle.hasOwnProperty('color')) {
            sceneStyle["color"] = "#000000"
        }
        let jumpStyle = { fontFamily: 'Book-Italic', fontStyle: 'italic', color: '#000' };

        const routeRenders = [];
        if (store.linkedScenes.length > 0) {
            routeRenders.push(<React.Fragment><div className="float-left" style={jumpStyle}>jump to:</div><br/></React.Fragment>);
        }

        style.color = currentStoreMenuItemColor;

        store.linkedScenes.map(scene => {
            // if (scene.scene_id)
            let render =
                <div
                    onMouseOver={this.onHover}
                    onMouseOut={this.onUnhover}
                    onClick={() => this.onClick(scene.scene_id)}
                >
                    <span className="old-linked-store-scene" style={sceneStyle}> {scene.name} </span>
                </div>
            routeRenders.push(render);
        });

        return (
            <Segment className="overflow-auto">
                <div className="text-align-left float-left">
                    <span style={style}> {store.name} </span>
                    <br />
                    <span style={style}> {store.location} </span>
                    <br />
                    {routeRenders}
                </div>
                <div
                    style={{ float: 'right', marginTop: '-0.5em' }}
                >
                    <SVGImage
                        src={placeholderIconSrc}
                        color={currentStoreMenuItemColor}
                        width={35}
                        height={35}
                        viewbox='0 0 500 500'
                    />
                </div>
            </Segment>
        );
    }
}

OldCurrentStoreMenuItem.propTypes = {
    closeMenu: PropTypes.func,
    menuTextFontStyle: PropTypes.object,
    store: PropTypes.object,
    setLoadingIconVisibility: PropTypes.func
};

export default props => (
    <AppContext.Consumer>
        {appContextValue => (
            <ThreeJSWorldContext.Consumer>
                {threeJSWorldContext => (
                    <OldCurrentStoreMenuItem
                        {...props}
                        threeJSWorldContext={threeJSWorldContext}
                        setLoadingIconVisibility={appContextValue.setLoadingIconVisibility}
                    />
                )}
            </ThreeJSWorldContext.Consumer>
        )}
    </AppContext.Consumer>
);
