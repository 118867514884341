import * as THREE from 'three';

export function getThreeTransformFromArray(transformArray) {
    let transformMatrix4x4 = new THREE.Matrix4();
    // column-major
    // [ 11, 21, 31, 41,
    //   12, 22, 32, 42,
    //   13, 23, 33, 43,
    //   14, 24, 34, 44 ];
    transformMatrix4x4.fromArray(transformArray);
    return transformMatrix4x4;
}
