import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Marker from './Marker.jsx';
import { ProductHotspotOnce, InteractedOnce, ProductHotspotClicked } from '../../utils/Analytics.js';
import HotspotCircle from './ReactSVGHotspots/HotspotCircle.jsx';
import HotspotPlus from './ReactSVGHotspots/HotspotPlus.jsx';
import { getHotspotIconStyle } from '../../utils/StoreConfigManager';
import TemplateProductModal from '../ui/modals/product/TemplateProductModal/TemplateProductModal';
import ProductModal from '../ui/modals/product/extended-product-modal/ui/ProductModal';

import ProductModalContextComponent from '../ui/modals/product/ProductModalContextComponent.jsx';
import { ProductModalContext } from '../ui/modals/product/ProductModalContext.jsx';

class ProductMarker extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onModalHidden = this.onModalHidden.bind(this);
        this.onProductTitleLoaded = this.onProductTitleLoaded.bind(this);

        this.state = {
            isShowingModal: false,
            svgSprite: HotspotCircle,
        };
        this.mounted = false;
        this.productTitle = null;
    }

    componentDidMount() {
        getHotspotIconStyle()
            .then(icon => {
                const Sprite = icon == 'blank' ? HotspotCircle : HotspotPlus;
                this.setState({ svgSprite: Sprite });
            })
            .catch(error => console.error(error));
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onProductTitleLoaded(productTitle) {
        this.productTitle = productTitle;
    }

    onClick() {
        if (!this.mounted) {
            return;
        }

        ProductHotspotOnce();
        InteractedOnce();
        if (this.productTitle) {
            ProductHotspotClicked(this.props.productSKU, this.productTitle);
        }
        this.setState({ isShowingModal: true });
    }

    onModalHidden() {
        if (!this.mounted) {
            return;
        }
        this.setState({ isShowingModal: false });
    }

    render() {
        const { productSKU, specialUrl } = this.props;
        const { svgSprite, isShowingModal } = this.state;
        
        return (
            <ProductModalContextComponent ProductSKU={productSKU} onProductTitleLoaded={this.onProductTitleLoaded}>
                <div>
                    <ProductModalContext.Consumer>
                        {value => (
                            isShowingModal && (
                                <ProductModal
                                    sku={productSKU}
                                    onClose={this.onModalHidden}
                                    specialUrl={specialUrl}
                                />
                            )
                        //         : (
                        //             <TemplateProductModal
                        //                 sku={productSKU}
                        //                 onClose={this.onModalHidden}
                        //                 specialUrl={specialUrl}
                        //                 onProductTitleLoaded={this.onProductTitleLoaded}
                        //             />
                        //         ))
                        )}
                    </ProductModalContext.Consumer>
                    <Marker
                        transform={this.props.transform}
                        colliderTransform={this.props.colliderTransform}
                        svgSprite={svgSprite}
                        primaryColor={this.props.primaryColor}
                        secondaryColor={this.props.secondaryColor}
                        showVisual={this.props.showVisual}
                        onClick={this.onClick}
                    />
                </div>
            </ProductModalContextComponent>
        );
    }
}

ProductMarker.propTypes = {
    color: PropTypes.string,
    productSKU: PropTypes.string.isRequired,
    transform: PropTypes.array.isRequired,
    colliderTransform: PropTypes.array.isRequired,
    specialUrl: PropTypes.string,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired
};

export default ProductMarker;
