import React, { useContext } from 'react';
import { ProductModalContext } from '../../ProductModalContext.jsx';

import PropTypes from 'prop-types';

import './ProductModalFooter.css';
import { DetailsClicked } from '../../../../../../utils/Analytics.js';
import { ContentTypes } from '../util/ProductModalEnums.js';

const arrowUrl = `https://cdn.obsessvr.com/details-arrow.png`;

const ProductModalFooter = (props) => {
    const { detailsActive, setDetailsActive, fontStyles, productInfo } = useContext(ProductModalContext);

    const handleClick = (e) => {
        DetailsClicked(productInfo[ContentTypes.SKU], productInfo[ContentTypes.TITLE]);
        setDetailsActive(!detailsActive)
    }

    return (
        <div className='product-modal-footer-content'>
            <div className='product-details-button-container' onClick={handleClick} style={fontStyles.detailsFooterFontStyle}>
                {detailsActive ? 'Details Above' : 'Details Below'}
                <img
                    src={arrowUrl}
                    className='product-details-arrow' 
                    style={{transform: detailsActive ? 'rotate(-180deg)' : null}}
                />
            </div>
        </div>
    );
}

ProductModalFooter.propTypes = {
    buyNowFontStyle: PropTypes.object,
    addToCartfontStyle: PropTypes.object,
    productButtonCopy: PropTypes.string,
    url: PropTypes.string,
    showAddToCart: PropTypes.bool,
    addToCartUrl: PropTypes.string,
    addToCartCopy: PropTypes.string,
    sku: PropTypes.string,
    title: PropTypes.title
};

export default ProductModalFooter;