import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three'
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import { AppContext } from '../../AppContext.jsx';
import VideoPlane from '../../three-components/VideoPlane';
import { EntranceAnimationContext } from './EntranceAnimationContextComponent.jsx';
import { isMobileDevice } from '../../utils/DeviceDetector';
import { PlaneBufferGeometry } from 'three'
import { getAllSceneInfoDictAsync } from '../../utils/StoreConfigManager.js';
import { constructUrl } from '../../utils/UrlConstructor.js';

const cdnURL = 'https://cdn.obsess-vr.com/dyson/entrance/';
const hiresUrl = `${cdnURL}Dyson_VirtualStore_Entrance_HiRes.mp4`;
const desktopUrl = `${cdnURL}VirtualStore_intro_Desktop_1920x1080_low.mp4`;
const mobileUrl = `${cdnURL}VirtualStore_intro_Mobile_1080x1920_low.mp4`;

class EntranceAnimation extends Component {
    constructor(props) {
        super(props);
        this.onVideoEnd = this.onVideoEnd.bind(this);
        this.videoPlane = null;
    }

    async componentDidMount() {
        const { threeJSWorldContext, scene, transform, id, roomId } = this.props;        
        let storeData  = await getAllSceneInfoDictAsync();
        const currentSceneData = storeData[roomId] 
        
        const videoAniVideoUrl = constructUrl(currentSceneData.first_scene_video_url);
        console.log("videoAniVideoUrl-------->", videoAniVideoUrl);
        if (scene) {
            const chromaColor = new THREE.Color('#17fc17');
            this.videoPlane = new VideoPlane(
                isMobileDevice() ? 480 : 1880,
                isMobileDevice() ? 1000 : 1000,
                isMobileDevice() ? videoAniVideoUrl : (window.innerWidth > 1920) ? videoAniVideoUrl : videoAniVideoUrl,
                this.onVideoEnd,
                null,
                chromaColor,
                id,
                null,
                this.videoReadyState);

            this.videoPlane.video.autoplay = true
            this.videoPlane.video.oncanplay = function () {
                this.play()
            }
            this.videoPlane.plane.geometry.dispose()
            this.videoPlane.plane.geometry = new PlaneBufferGeometry(2, 2)
            this.videoPlane.plane.material.onBeforeCompile = function (shader) {
                shader.vertexShader = `
                varying vec2 vUV;

                void main() {
                    vUV = uv;
                    gl_Position = vec4(position.xy, 0.0, 1.0);
                }`
            }

            this.videoPlane.addToScene(scene);
            this.renderUUID = threeJSWorldContext.addToRenderLoop();
            threeJSWorldContext.request3DSceneRender();
            this.videoPlane.plane.onBeforeRender = () => {
                scene.traverse(e => {
                    if (e.userData.isVideoPlane && e !== this.videoPlane.plane) {
                        e.visible = false
                    }
                })
            }
            setTimeout(() => {
                if (this.videoPlane.video.paused)
                    this.videoPlane.video.play()
            }, 2000)
            setTimeout(this.onVideoEnd, 10000) /// fail safe to end the video if there was a problem loading
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { animationVideoPlay } = this.props;
        if (prevProps && animationVideoPlay && (prevProps.animationVideoPlay !== animationVideoPlay) && this.videoPlane) {
            this.videoPlane.play()
        }
    }

    componentWillUnmount() {
        const { scene, removeFromRenderLoop, request3DSceneRender } = this.props.threeJSWorldContext;
        if (this.videoPlane) {
            this.videoPlane.removeFromScene(scene);
            request3DSceneRender();

            if (this.renderUUID !== null) {
                removeFromRenderLoop(this.renderUUID);
                this.renderUUID = null;
            }
        }
    }

    onVideoEnd() {
        this.props.setIsAnimationVideoEnd(true)
        const { removeFromRenderLoop } = this.props.threeJSWorldContext;
        if (this.renderUUID !== null) {
            removeFromRenderLoop(this.renderUUID);
            this.renderUUID = null;
        }
        delete this.videoPlane.onBeforeRender
        this.props.scene.traverse(e => {
            if (e.userData.isVideoPlane && e !== this.videoPlane.plane) {
                e.visible = e.userData.shouldBeVisible
                delete e.userData.shouldBeVisible
            }
        })
        this.props.scene.remove(this.videoPlane.plane)
    }

    videoReadyState = (type = true) => {
        this.props.setIsAnimationVideoReady(type)
        return
    }

    render() {
        return null;
    }
}

EntranceAnimation.propTypes = {
    animationVideoPlay: PropTypes.bool,
    roomId: PropTypes.string
};

export default props => (
    <AppContext.Consumer>
        {appContextValue => (        
            <ThreeJSWorldContext.Consumer>
                {threeJSWorldContext => (
                    <EntranceAnimationContext.Consumer>
                        {entranceAnimationContext => (
                            <EntranceAnimation
                                {...props}
                                threeJSWorldContext={threeJSWorldContext}
                                scene={threeJSWorldContext.scene}
                                setLoadingIconVisibility={appContextValue.setLoadingIconVisibility}
                                setIsAnimationVideoReady={entranceAnimationContext.setIsAnimationVideoReady}
                                setIsAnimationVideoEnd={entranceAnimationContext.setIsAnimationVideoEnd}
                                animationVideoPlay={entranceAnimationContext.animationVideoPlay}
                            />
                        )}
                    </EntranceAnimationContext.Consumer>
                )}
            </ThreeJSWorldContext.Consumer>
        )}
    </AppContext.Consumer>
);