import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { AddToCartContext } from './AddToCartContext';
import CartItem from './CartItem';

import './CartDropdown.css'
import CartDropdownFooter from './CartDropdownFooter';
import CartDropdownFooterText from './CartDropdownFooterText';

const CartDropdown = ({ showDropdown, menuTextFontStyle, wishlistFontStyle }) => {
    const { cartList, pricePrefix, removeFromCart } = useContext(AddToCartContext);

    const renderCartItems = () => {
        return (
            <div className='cart-dropdown-item-container'>
                {cartList.map((item, index) => {
                    return (
                        <CartItem
                            key={index}
                            name={item.TITLE}
                            price={item.PRICE}
                            prefix={pricePrefix}
                            thumbnail={item.DOWNSIZED_THUMBNAIL}
                            menuTextFontStyle={menuTextFontStyle}
                            removeFromCart={removeFromCart}
                        />
                    )
                })}
            </div>
        );
    }

    if (!showDropdown) {
        return (<div />);
    }
    return (
        <React.Fragment>
            <div id='add-to-cart-arrow' />
            <div id='add-to-cart-menu'>
                {renderCartItems()}
                {cartList.length !== 0 && <CartDropdownFooter />}
                {cartList.length !== 0 && wishlistFontStyle.text && <CartDropdownFooterText wishlistFontStyle={wishlistFontStyle}/>}
            </div>
        </React.Fragment>
    );
}

CartDropdown.propTypes = {
    showMenu: PropTypes.bool,
    linkedStores: PropTypes.array,
    currentStoreIndex: PropTypes.number,
    menuTextFontStyle: PropTypes.object,
    menuTitle: PropTypes.string,
    sceneIdNameDict: PropTypes.object,
};

export default CartDropdown;
