import React, { Component } from 'react';

import './Logo.css';
import { getLogoDataAsync } from '../../utils/StoreConfigManager.js';
import { LogoClicked } from '../../utils/Analytics.js';
import { constructUrl } from '../../utils/UrlConstructor.js';
import InteractableElement from './InteractableElement';
import BaseImage from './image-components/BaseImage';
import { getAltText } from '../../utils/StaticAssetManager';

const style = {
    width: '60%'
};

class Logo extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            imageSrc: '',
            redirectUrl: ''
        };
        getLogoDataAsync()
            .then((logoData) => {
                const imageSrcObject = logoData['imageSrc'];
                const redirectUrlObject = logoData['redirectUrl'];
                const imageSrc = imageSrcObject ? constructUrl(imageSrcObject) : '';
                const redirectUrl = redirectUrlObject ? constructUrl(redirectUrlObject) : '';

                this.setState({
                    imageSrc: imageSrc,
                    redirectUrl: redirectUrl
                });
            })
            .catch(error => console.error(error));
    }

    onClick() {
        LogoClicked();
        window.open(this.state.redirectUrl, '_blank');
    }

    render() {
        const imageSrc = this.state.imageSrc;
        const imgFileName = getAltText(imageSrc);
        if (!imageSrc) {
            return <React.Fragment />
        }
        return (
            <div id='topLeftLogo' className='hoverable'>
                <InteractableElement
                    onClick={this.onClick}
                >
                    <BaseImage
                        src={imageSrc}
                        style={style}
                        imgFileName={imgFileName}
                    />
                </InteractableElement>
            </div>
        );
    }
}

export default Logo;
