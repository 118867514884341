import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
// * IMPORTANT: Need reset.min.css and site.min.css for some of the semantic components to look correctly, mainly the bottom left bar and pop up button
import 'semantic-ui-css/components/reset.min.css'
// * IMPORTANT: site.min.css overrides body font-family in embeds, so it's downloaded and font-family is removed
// import 'semantic-ui-css/components/site.min.css'
import './store-components/css/site.min.css'

import App from './store-components/App.jsx';
import './store-components/css/main.css';
import { loadStyleData } from './store-components/utils/StyleDataManager.js';
import { loadGAAsync } from './store-components/utils/GoogleAnalyticsHelper.js';
import { getHideLogo, getClient, getStore, getContainerId } from './store-components/utils/EmbedVariable.js';
import { getStoreDataAsync, setFetchStoreDataRouteToClientAndStoreNameRoute } from './store-components/utils/StoreConfigManager.js';

const variables = require('../variables/Variables.js');


window.onload = function () {
    const viewportNodes = document.getElementsByName('viewport');
    if (!viewportNodes || viewportNodes.length === 0) {
        const viewportMeta = document.createElement('meta');
        viewportMeta.setAttribute('name', 'viewport')
        viewportMeta.setAttribute('content', 'width=device-width');
        viewportMeta.setAttribute('minimum-scale', '1.0');
        viewportMeta.setAttribute('maximum-scale', '1.0');
        document.head.appendChild(viewportMeta);
    }

    const containerId = getContainerId();
    const containerNode = containerId ? document.getElementById(containerId) : document.body;
    const virtualStoreEmbed = document.createElement('div');
    virtualStoreEmbed.id = 'obsessvr-virtual-store-embed-inner-app-container';
    containerNode.appendChild(virtualStoreEmbed);

    const rootNode = document.createElement('div');
    rootNode.id = variables.reactRoot;
    virtualStoreEmbed.appendChild(rootNode);

    const clientName = getClient();
    const storeName = getStore();
    if (clientName && storeName) {
        setFetchStoreDataRouteToClientAndStoreNameRoute(clientName, storeName);
    }

    const hideLogo = getHideLogo();

    getStoreDataAsync()
    .then(() => {
        loadGAAsync();
        loadStyleData().catch(err => console.log(err));
        ReactDOM.render(
            <App hideLogo={hideLogo} />,
            rootNode
        );
    })
    .catch(error => {
        console.error(error);
        // TODO: Redirect to 404 page
    });
};

// module.hot.accept();
// Stats panel
// javascript: (function () { var script = document.createElement('script'); script.onload = function () { var stats = new Stats(); document.body.appendChild(stats.dom); requestAnimationFrame(function loop() { stats.update(); requestAnimationFrame(loop) }); }; script.src = '//rawgit.com/mrdoob/stats.js/master/build/stats.min.js'; document.head.appendChild(script); })()
