import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'web-store-modules/packages/web-store-ui-library';
import { AddToCartContext } from './AddToCartContext';

const CartDropdownFooter = ({ onClick }) => {
    const {colors, buttonFields} = useContext(AddToCartContext);

    return (
        <Button
            hoverStyleChange={true}
            onClick={onClick}
            primaryColor={colors && colors.buyNowPrimaryColor}
            secondaryColor={colors && colors.buyNowSecondaryColor}
            className="product-button flex-center obsessvr-regularFont full-width pointer"
            style={{padding: '0', margin: '1em auto', borderRadius: '4px', width: '60%', height: '2em'}}
            // primaryBorderWidth={buttonFields.buyNowBorderActiveOn && buttonFields.buyNowBorderActiveOn.primary ? buttonFields.buttonBorderWidth : undefined}
            // secondaryBorderWidth={buttonFields.buyNowBorderActiveOn && buttonFields.buyNowBorderActiveOn.secondary ? buttonFields.buttonBorderWidth : undefined}
            primaryBorderColor={colors && colors.buyNowBorderColor}
            secondaryBorderColor={colors && colors.buyNowBorderColor}
            insetBorder={true}
            padded={false}
        >
            <p className='obsessvr-regularFont'>
                Place Order
            </p>
        </Button>
    )
}

export default CartDropdownFooter;
