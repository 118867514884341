import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';

import TextBox from './TextBox.jsx';
import Marker from './Marker.jsx';
import { getThreeTransformFromArray } from '../../utils/ThreeTransformConverter';
import { getIconSVGAsync, createImageFromSvgString } from './../ui/ui-layer-panel/SVGIcons.jsx';
import { isMobileDevice } from '../../utils/DeviceDetector';
import TextSprite from './TextSprite.jsx';
import { getFontStylesAsync } from '../../utils/StoreConfigManager';

const isMobile = isMobileDevice();
const textBoxIconUrl = 'https://cdn.obsess-vr.com/text-hotspot-icon.svg';
const DRAG_THRESHOLD = 30; // How far a drag needs to go to register as a 'drag' action

const TextBoxHoverMarker = ({ transform, colliderTransform, primaryColor, secondaryColor, text, title, showVisual }) => {
    const transformArr = getThreeTransformFromArray(transform);
    const markerTransform = transformArr.elements;

    let startPos;
    let isDragging = false;
    const [showTextBox, setShowTextBox] = useState(false);
    const [iconNormalSpriteUrl, setIconNormalSpriteUrl] = useState('');
    const [iconHoverSpriteUrl, setIconHoverSpriteUrl] = useState('');
    const [textBoxSpriteUrl, setTextBoxSpriteUrl] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const svg = await getIconSVGAsync(primaryColor, textBoxIconUrl)
            createImageFromSvgString(svg, (canvas) => {
                const img = canvas.toDataURL('image/png');
                setIconNormalSpriteUrl(img);
            })
            const hoverSvg = await getIconSVGAsync(secondaryColor, textBoxIconUrl)
            createImageFromSvgString(hoverSvg, (canvas) => {
                const img = canvas.toDataURL('image/png');
                setIconHoverSpriteUrl(img);
            })
        } catch (error) {
            console.error(error);
        }
    }

    const generateTextBoxFromSVG = async () => {
        if (textBoxSpriteUrl) {
            setShowTextBox(true);
            return;
        }
        try {
            const fontStyles = await getFontStylesAsync();
            const popUpTitleFontStyle = {
                fontFamily: fontStyles.popUpTitle.name,
                fontSize: fontStyles.popUpTitle.size
            };
            const popUpTextFontStyle = {
                fontFamily: fontStyles.popUpText.name,
                fontSize: fontStyles.popUpText.size
            };
            let textSprite = new TextSprite();
            textSprite.saveSVG(text, title, popUpTitleFontStyle, popUpTextFontStyle, (canvas) => {
                const img = canvas.toDataURL('image/png');
                setTextBoxSpriteUrl(img);
                setShowTextBox(true);
            });
        } catch (error) {
            console.error(error);
        }
    }

    const onHover = () => {
        generateTextBoxFromSVG();
    }

    const onUnhover = () => {
        setShowTextBox(false);
    }

    const touchStartHandler = (e) => {
        const x = e.touches[0].clientX;
        const y = e.touches[0].clientY;
        startPos = new THREE.Vector2(x, y);
    }

    const touchMoveHandler = (e) => {
        const x = e.touches[0].clientX;
        const y = e.touches[0].clientY;
        const endPos = new THREE.Vector2(x, y);
        const distance = endPos.distanceTo(startPos);
        if (distance > DRAG_THRESHOLD) {
            isDragging = true;
        }
    }

    const touchEndHandler = () => {
        startPos = null;
        if (isDragging) {
            isDragging = false;
            return;
        }
        const canvasNode = document.getElementsByTagName('canvas')[0];
        setShowTextBox(false);
        canvasNode.removeEventListener('touchstart', touchStartHandler);
        canvasNode.removeEventListener('touchmove', touchMoveHandler);
        canvasNode.removeEventListener('touchend', touchEndHandler);
    }

    const onClick = () => {
        if (isMobile && !showTextBox) {
            generateTextBoxFromSVG();
            const canvasNode = document.getElementsByTagName('canvas')[0];
            canvasNode.addEventListener('touchstart', touchStartHandler);
            canvasNode.addEventListener('touchmove', touchMoveHandler);
            canvasNode.addEventListener('touchend', touchEndHandler);

        }
    }
    
    return (
        <React.Fragment>
            <TextBox
                transform={transform}
                colliderTransform={colliderTransform}
                hoveredSpriteUrl={textBoxSpriteUrl}
                showTextBox={showTextBox}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                text={text}
                title={title}
            />
            <Marker
                transform={markerTransform}
                colliderTransform={colliderTransform}
                normalSpriteUrl={iconNormalSpriteUrl}
                hoveredSpriteUrl={iconHoverSpriteUrl}
                showVisual={showVisual}
                onClick={onClick}
                onHover={onHover}
                onUnhover={onUnhover}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
            />
        </React.Fragment>
    );
}

TextBoxHoverMarker.propTypes = {
    transform: PropTypes.array,
    colliderTransform: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    text: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string
};

export default TextBoxHoverMarker;