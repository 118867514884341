import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Marker from './Marker.jsx';
import { getHeartEmptyUrlAsync, getHeartFillUrlAsync } from '../../utils/StaticAssetManager.js';
import { isSKUHearted, addHeartedSKU, removeHeartedSKU } from '../../utils/DynamicStoreDataManager.js';
import { AddedHeartItem, RemovedHeartItem } from '../../utils/Analytics.js';
import { getProductInfoWithIdAsync, ENDPOINTTYPE } from '../../utils/ProductInfoLoader.js';

class HeartMarker extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);

        this.productTitle = null;
        this.pendingProductHotspotClicked = null;
        this.state = {
            currentSpriteUrl: ''
        };
        this.emptyUrl = '';
        this.fillUrl = '';

        const isHearted = isSKUHearted(props.productSKU);
        getHeartEmptyUrlAsync()
        .then(url => {
            this.emptyUrl = url;
            if (!isHearted) {
                this.setState({ currentSpriteUrl: url });
            }
        })
        .catch(error => console.error(error));
        getHeartFillUrlAsync()
        .then(url => {
            this.fillUrl = url
            if (isHearted) {
                this.setState({ currentSpriteUrl: url });
            }
        })
        .catch(error => console.error(error));

        this.productName = '';
        this.mounted = false;
        getProductInfoWithIdAsync(props.productSKU, ENDPOINTTYPE.OBSESSAPI)
            .then(fetchedProductInfo => {
                if (this.mounted) {
                    this.productName = fetchedProductInfo.name;
                }
            })
            .catch(error => console.error(error));
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onClick() {
        if (this.state.currentSpriteUrl === this.emptyUrl) {
            AddedHeartItem(this.props.productSKU, this.productName);
            addHeartedSKU(this.props.productSKU);
            this.setState({ currentSpriteUrl: this.fillUrl });
        } else {
            RemovedHeartItem(this.props.productSKU, this.productName);
            removeHeartedSKU(this.props.productSKU);
            this.setState({ currentSpriteUrl: this.emptyUrl });
        }
    }

    render() {
        const { currentSpriteUrl } = this.state;
        return (
            <div>
                <Marker
                    transform={this.props.transform}
                    colliderTransform={this.props.colliderTransform}
                    normalSpriteUrl={currentSpriteUrl}
                    hoverSpriteUrl={currentSpriteUrl}
                    showVisual={true}
                    onClick={this.onClick}
                />
            </div>
        );
    }
}

HeartMarker.propTypes = {
    productSKU: PropTypes.string.isRequired,
    transform: PropTypes.array.isRequired,
    colliderTransform: PropTypes.array.isRequired
};

export default HeartMarker;
