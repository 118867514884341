import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from 'semantic-ui-react/dist/es/modules/Transition';
import 'semantic-ui-css/components/transition.min.css';

import Modal from './Modal.jsx';

class FlyUpModal extends Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);

        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        this.setState({
            visible: true
        });
    }

    onClose() {
        this.setState({
            visible: false
        });
    }

    render() {
        const visible = this.props.open === null ? this.state.visible : (this.state.visible && this.props.open);
        const extraClassName = this.props.extraClassName;
        const modalWrapperClassName = extraClassName ? 'modalWrapper ' + extraClassName : 'modalWrapper';

        return (
            <React.Fragment>
                <Transition
                    animation={'fly up'}
                    duration={this.props.duration}
                    visible={visible}
                    unmountOnHide={true}
                    onComplete={this.props.onModalOpened}
                    onHide={this.props.onModalHidden}
                >
                    <div className='modalContainer'>
                        <div className={modalWrapperClassName}>
                            <Modal onClose={this.onClose} isOpaque={this.props.isOpaque}  isImageLoaded={this.props.isImageLoaded}>
                                {this.props.children}
                            </Modal>
                        </div>
                    </div>
                </Transition>
            </React.Fragment>
        );
    }
}

FlyUpModal.propTypes = {
    open: PropTypes.bool,
    extraClassName: PropTypes.string,
    onModalHidden: PropTypes.func,
    onModalOpened: PropTypes.func,
    isOpaque: PropTypes.bool
};

FlyUpModal.defaultProps = {
    duration: 700
}

export default FlyUpModal;
