import React, { useState, useEffect, useRef, useContext } from 'react';

import { Button } from 'web-store-modules/packages/web-store-ui-library';
import FlyUpModal from '../FlyUpModal';
import { getFontStylesAsync } from '../../../../utils/StoreConfigManager';
import './TextModal.css';
import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
import { constructUrl } from '../../../../utils/UrlConstructor';
import { AppContext } from "../../../../AppContext.jsx";
import { TextPopUpButtonClicked } from "../../../../utils/Analytics";
import ModalOverlay from '../ModalOverlay';
import ReactHtmlParser from "react-html-parser";
import { getTrackingParameter } from '../../../../utils/StoreConfigManager';
import { getAltText } from '../../../../utils/StaticAssetManager';

const isMobile = isMobileDevice();

const TextModal = ({ modalContent, onModalHidden, onClose, primaryColor, secondaryColor }) => {
    const { title, sub_title, body, button_copy, button_url,url } = modalContent;
    const [fontStyles, setFontStyles] = useState();
    const [modalActive, setModalActive] = useState();
    const [imageName, setImageName] = useState('');

    const modalRef = useRef();
    const { currentSceneName } = useContext(AppContext);

    const imageSrc = url ? constructUrl(url) : '';

    useEffect(() => {
        fetchFontStyles();
        addListeners(); 
        const fileName = getAltText(imageSrc);
        setImageName(fileName);

        return (() => {
            removeListeners();
        });
    }, []);

    const addListeners = () => {
        if (isMobile) {
            window.addEventListener('touchend', handleModalClose);
        } else {
            window.addEventListener('click', handleModalClose);
        }
    }

    const removeListeners = () => {
        if (isMobile) {
            window.removeEventListener('touchend', handleModalClose);
        } else {
            window.removeEventListener('click', handleModalClose);
        }
    }

    const fetchFontStyles = () => {
        getFontStylesAsync()
            .then((fontStyles) => setFontStyles(fontStyles))
            .catch((err) => console.error(err));

    }

    const handleModalClose = (e) => {
        if (modalRef && modalRef.current.contains(e.target)) {
            return;
        }
        closeModal();
    }

    const closeModal = () => {
        if (onClose) {
            setModalActive(false);
            onClose();
        }
    }

    const handleClick = () => {
        TextPopUpButtonClicked(currentSceneName);
        const attRedirectPath = constructUrl(button_url);
        const trackingParam = getTrackingParameter();
        const trackingParamUrl = `${attRedirectPath}?${trackingParam}`

        window.open(trackingParamUrl, '_blank');
    }

    const productButton = () => {
        return (
            <div className="product-button-container"><Button
                hoverStyleChange={true}
                onClick={handleClick}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                className="text-marker-button flex-center obsessvr-regularFont full-width pointer product-button"
                style={{ padding: '0', margin: '0 1%', borderRadius: '4px' }}
                primaryBorderColor='white'
                secondaryBorderColor='white'
                primaryBorderWidth='0px'
                secondaryBorderWidth='0px'
                insetBorder={true}
                padded={false}
            >
                <p className='obsessvr-regularFont'>
                    {button_copy}
                </p>
            </Button></div>
        )
    }

    const textContainer = () => {
        return (
            <div className='text-modal-text-container'>
                <pre
                    style={{
                        fontSize: isMobile ? '18px' : '21px',
                        fontFamily: fontStyles.popUpText.name,
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {ReactHtmlParser(body)}
                </pre>
            </div>
        )
    }

    const titleRender = () => {
        return (
            <div className='text-modal-title-container'>
                <span
                    style={{
                        fontSize: isMobile ? '60px' : fontStyles.popUpTitle.size,
                        fontFamily: fontStyles.popUpTitle.name
                    }}>
                    {title}
                </span>
            </div>
        );
    }

    const subtitleRender = () => {
        return (
            <div className='text-modal-subtitle-container'>
                <span
                    style={{
                        fontSize: fontStyles.popUpSubtitle.size,
                        fontFamily: fontStyles.popUpSubtitle.name
                    }}>
                    {sub_title}
                </span>
            </div>
        );
    }

  const imageRender = () => { 
     const imageUrl = constructUrl(url);
     return (
    <div className="zoomedImageWrapper">
      <div className="zoomedImageContainer flex-center">   
    <img src={imageUrl} style={{maxWidth: '70%'}} alt={imageName} />
      </div>
  </div>

        );
    }

    const renderModalContent = () => {
        return (
            <div className='text-modal-content' style={{ textAlign: 'center' }} ref={modalRef}>
                {titleRender()}
                {subtitleRender()}  
                {url && imageRender()}            
                {textContainer()}
                {button_copy && productButton()}
            </div>
        )
    }
    return (
        //Added the isImage Loaded bool here to display the close button on the Modal due to a condition in Modal.jsx
        <FlyUpModal extraClassName="text-modal-container" onModalHidden={onModalHidden} isOpaque
            textPopup hideCloseIcon isImageLoaded>
            <ModalOverlay />
            {!!fontStyles && renderModalContent()}
        </FlyUpModal>
    );
}

export default TextModal;


