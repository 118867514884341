import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'web-store-modules/packages/web-store-ui-library'
import { BuyNowClicked, BuyNowOnce, AddToWishlistClicked } from "../../../../../utils/Analytics";
import {getProductButtonColorsAsync, getProductButtonFields} from "../../../../../utils/StoreConfigManager";


class TemplateProductBuy extends Component {
    constructor(props){
        super(props);

        this.buyButtonClicked = this.buyButtonClicked.bind(this);
        this.addToCartClicked = this.addToCartClicked.bind(this);
        this.getButtonStyling = this.getButtonStyling.bind(this);

        this.state = {
            colors: {},
            buttonBorderRadius: '0',
            buttonBorderWidth: '0',
            buyNowBorderActiveOn: {
                primary: false,
                secondary: false
            },
            addToCartBorderActiveOn: {
                primary: false,
                secondary: false
            }
        };

        this.getButtonStyling();
    }

    buyButtonClicked() {
        const { sku, title, url } = this.props;
        BuyNowOnce();
        BuyNowClicked(sku, title);
        window.open(url, '_blank');
    }

    addToCartClicked() {
        const { sku, title, addToCartUrl } = this.props;
        AddToWishlistClicked(sku, title);
        window.open(addToCartUrl, '_blank');
    }

    getButtonStyling() {
        getProductButtonColorsAsync()
            .then(colors => {
                this.setState({ colors })
            })
            .catch(error => console.error(error));

        getProductButtonFields()
            .then(buttonFields => {
                const { buttonBorderRadius, buttonBorderWidth, buyNowBorderActiveOn, addToCartBorderActiveOn } = buttonFields;

                this.setState({ buttonBorderRadius, buttonBorderWidth, buyNowBorderActiveOn, addToCartBorderActiveOn });
            })
            .catch(error => console.error(error));
    }

    render() {
        const { addToCartFontStyle, buyNowFontStyle, productButtonCopy, addToCartCopy, addToCartUrl, showAddToCart } = this.props;
        const { colors, buttonBorderRadius, buttonBorderWidth, buyNowBorderActiveOn, addToCartBorderActiveOn } = this.state;

        const renderAddToCart = showAddToCart && addToCartUrl;

        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '6em', width: renderAddToCart ? '18em' : '12em'}}>
                {renderAddToCart && (
                    <Button
                        hoverStyleChange={true}
                        onClick={this.addToCartClicked}
                        primaryColor={colors.addToCartPrimaryColor}
                        secondaryColor={colors.addToCartSecondaryColor}
                        className="product-button flex-center obsessvr-regularFont full-width pointer"
                        style={{padding: '0 0.5em', marginRight: '0.1em', borderRadius: buttonBorderRadius}}
                        primaryBorderWidth={addToCartBorderActiveOn.primary ? buttonBorderWidth : undefined}
                        secondaryBorderWidth={addToCartBorderActiveOn.secondary ? buttonBorderWidth : undefined}
                        primaryBorderColor={colors.addToCartBorderColor}
                        secondaryBorderColor={colors.addToCartBorderColor}
                        insetBorder={true}
                        padded={false}
                    >
                        <p className='obsessvr-regularFont' style={addToCartFontStyle}>
                            {addToCartCopy}
                        </p>
                    </Button>
                )}
                <Button
                    hoverStyleChange={true}
                    onClick={this.buyButtonClicked}
                    primaryColor={colors.buyNowPrimaryColor}
                    secondaryColor={colors.buyNowSecondaryColor}
                    className="product-button flex-center obsessvr-regularFont full-width pointer"
                    style={{padding: '0 0.5em', marginLeft: '0.1em', borderRadius: buttonBorderRadius}}
                    primaryBorderWidth={buyNowBorderActiveOn.primary ? buttonBorderWidth : undefined}
                    secondaryBorderWidth={buyNowBorderActiveOn.secondary ? buttonBorderWidth : undefined}
                    primaryBorderColor={colors.buyNowBorderColor}
                    secondaryBorderColor={colors.buyNowBorderColor}
                    insetBorder={true}
                    padded={false}
                >
                    <p className='obsessvr-regularFont' style={buyNowFontStyle}>
                        {productButtonCopy}
                    </p>
                </Button>
            </div>
        );
    }
}

TemplateProductBuy.propTypes = {
    buyNowFontStyle: PropTypes.object,
    addToCartfontStyle: PropTypes.object,
    productButtonCopy: PropTypes.string,
    url: PropTypes.string,
    showAddToCart: PropTypes.bool,
    addToCartUrl: PropTypes.string,
    addToCartCopy: PropTypes.string,
    sku: PropTypes.string,
    title: PropTypes.title
};

export default TemplateProductBuy;