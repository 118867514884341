import React, { useState, useContext, useEffect } from 'react';

import { getCurrentScene } from '../../../utils/History.js';
import { StoreSelectorOption, NavigationMenuClicked } from '../../../utils/Analytics.js';
import { ThreeJSWorldContext } from 'web-store-modules/packages/web-store-three-world';
import BottomBarSelectorItem from './BottomBarSelectorItem.jsx';

const useBarSelector = ({ linkedStores, menuTextFontStyle, sceneIdNameDict, isGDPRShowing, isMobile }, lang) => {
    const { history, pushHistoryWithReset } = useContext(ThreeJSWorldContext);
    const [selected, setSelected] = useState();
    const [bottomHeight, setBottomHeight] = useState(0);

    useEffect(() => {
        if (!selected || history.location.pathname === '/') {
            const initialScene = getCurrentScene();
            setScene(initialScene);
        } else {
            setScene(history.location.pathname.substr(1))
        }
    }, [history.location.pathname])

    useEffect(() => {
        getGDPRHeight();
    }, [isGDPRShowing])

    const setScene = (scene) => {
        if (sceneIdNameDict) {
            Object.keys(sceneIdNameDict).forEach((key) => {
                if (scene === sceneIdNameDict[key]) {
                    setSelected(key);
                }
            })
        }
    }

    const onSelectorOptionClicked = (e, id, isLocked) => {
        if (!isLocked) {
            setSelected(id);
            goToScene(id);
        }
    }

    const goToScene = (sceneId) => {
        const linkedSceneName = sceneIdNameDict[sceneId];
        let targetPath = `/${linkedSceneName}`;
        NavigationMenuClicked(linkedSceneName);
        if (history.location.pathname !== targetPath) {
            pushHistoryWithReset(targetPath);
        }
    }

    const getGDPRHeight = () => {
        if (isGDPRShowing) {
            setBottomHeight(`${document.getElementById('gdpr').offsetHeight}px`);
        } else if (!isGDPRShowing && !isMobile) {
            setBottomHeight(`0.75em`)
        } else {
            setBottomHeight(`0px`);
        }
    }
    const renderSelectorItems = () => {
        const len = linkedStores.length;
        const widthPercent = `${1 / len * 100}%`;
        const currentPath = history.location.pathname;
        return linkedStores.map((scene, index) => {
            const currentlySelected = (currentPath.indexOf(sceneIdNameDict[scene.scene_id]) != -1) ? true : false;
            const isLocked = false;
            const selectedBorderWidth = (() => {
                if (index === 0) {
                    return '5px';
                } else {
                    return '5px 5px 5px 3px';
                }
            })()
            const className = `${isLocked ? 'bottom-bar-selector-item-locked' : (index === 0 ? 'bottom-bar-selector-item-start' : 'bottom-bar-selector-item')} flex-center`
            return (
                <BottomBarSelectorItem
                    key={index}
                    index={index}
                    className={className}
                    scene={scene}
                    isLocked={isLocked}
                    selectedBorderWidth={selectedBorderWidth}
                    widthPercent={widthPercent}
                    currentlySelected={currentlySelected}
                    onSelectorOptionClicked={onSelectorOptionClicked}
                    BottomBarSelectorLen={linkedStores.length}
                />
                
            )
        })
    }

    return [bottomHeight, renderSelectorItems];
}

export default useBarSelector;
