import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dropdownSpacingMultiplier } from '../../util/ProductModalHelpers';
import SizeSelectorItem from './SizeSelectorItem.jsx';
import './SizeSelector.css';

const SizeSelector = ({ groupProducts, sku, fontStyle, variantSize, setVariantSize, colors, variantColor, productVariantMap }) => {
    const [selectorOpen, setSelectorOpen] = useState(false);
    const [sizes, setSizes] = useState(null);

    useEffect(() => {
        const sizeArray = variantColor && Object.keys(productVariantMap.features_map[variantColor])
        setSizes(sizeArray);
        // reset size to null when the user chooses another color
        setVariantSize(null);
    }, [variantColor])

    const toggleSelector = () => setSelectorOpen(!selectorOpen);

    const handleOnClick = (size) => {
        setVariantSize(size)
        toggleSelector();
    }

    const selectorListOffset = () => {
        const numberOfItems = sizes && sizes.length;
        const multiplier = dropdownSpacingMultiplier(numberOfItems);
        const height = numberOfItems * multiplier;
        return `${height}em`;
    }

    return (
        <div id="size-selector-column" className="flex-center" style={{ ...fontStyle, height: selectorListOffset(), marginTop: '1em' }}>
            <SizeSelectorItem text={variantSize ? variantSize : 'Select A Size'} sku={sku} onClick={toggleSelector} showArrow={true} arrowDown={selectorOpen} style={variantSize && colors ? { backgroundColor: colors.addToCartPrimaryColor } : null} />

            <div id="size-selector-items" className="flex-center">
                {selectorOpen && sizes && sizes.map((size, index) => (
                    <SizeSelectorItem
                        key={index}
                        relative={true}
                        onClick={() => handleOnClick(size)}
                        text={size}
                    />
                ))}
            </div>
        </div>
    );
}

SizeSelector.propTypes = {
    groupProducts: PropTypes.array.isRequired,
    fontStyle: PropTypes.object,
    size: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    fetchProductInfo: PropTypes.func.isRequired
};

export default SizeSelector;
