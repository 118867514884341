let firstSceneName;

export function setFirstSceneName(sceneName) {
    if (!firstSceneName) {
        firstSceneName = sceneName;
    }
}

// Parse the url to return the current scene. If no path segment after hash, you're at the entrance
export function getCurrentScene() {
    return window.location.hash.split('/')[1] || firstSceneName;
}