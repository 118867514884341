import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGImage from '../image-components/SVGImage.jsx';
import BaseImage from '../image-components/BaseImage.jsx';

class Icon extends Component {
    render() {
        const { src, color, width, height, viewbox, style, imgFileName } = this.props;
        
        let image = <React.Fragment />
        if (src.split('.').pop() === 'svg') {
            image = <SVGImage
                src={src}
                color={color}
                width={width}
                height={height}
                viewbox={viewbox}
            />
        } else {
            image = <BaseImage src={src} style={style} imgFileName={imgFileName} />
        }

        return (
            <div>
            { image }
            </div>
        )
    }
}

SVGImage.propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    svgId: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    viewbox: PropTypes.string
};

export default Icon;
